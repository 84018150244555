import { _deepClone } from "@/helpers/misc"
import { defaultHistory } from "@/data/tab-template.js"
import { useTabsStore } from "./tabs.store";

export const undoRedoGetters = {
    history(state) {
        return state.selectedTab?.history || _deepClone(defaultHistory)
    },
    currentCommand() {
        return this.history.commands[this.history.currentCommandIdx]
    },
    nextCommand() {
        return this.history.commands[this.history.currentCommandIdx + 1]
    },
    canUndo() {
        return this.history.currentCommandIdx >= 0
    },
    canRedo() {
        return this.history.commands.length > 0 && this.history.currentCommandIdx < this.history.commands.length - 1
    },
}

export const undoRedoActions = {
    undo() {
        if (this.canUndo) this.currentCommand.undo()
    },
    redo() {
        if (this.canRedo) {
            if (!this.nextCommand) console.log('Error: cant redo')
            this.nextCommand.redo()
        }
    },
    reset() {
        const tab = useTabsStore()
        tab.selectedTab.history = _deepClone(defaultHistory)
    }
}

