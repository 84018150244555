<script lang="ts" setup>
import { RouterLink, useRouter } from 'vue-router'

const router = useRouter()
const props = defineProps<{
    path: string,
}>()
</script>

<template>
    <RouterLink class="sidebar-link px-4 py-3 d-flex align-items-center gap-6 rounded has-hover" :to="path">
        <slot></slot>
    </RouterLink>
</template>

<style lang="scss">
.sidebar-link {
    min-width: 15em;
    color: var(--muted);

    &.router-link-active {
        text-decoration: none;
        border-bottom: none;
        background-color: rgba(0, 0, 0, 0.05);
        color: var(--primary);
    }

    .material-design-icon {
        font-size: 1.5em;
    }
}

</style>
