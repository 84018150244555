import { SchemaToDbml } from "@/components/code-editor/schemaToDbml.js"

export class Command {
    _description = 'Parent Command'

    constructor(tab, payload) {
        this.tab = tab
        this.payload = payload
    }

    label() {
        return this._description
    }

    run() {
        this.tab.history.currentCommandIdx = this.tab.history.commands.indexOf(this)
    }

    saveToHistory() {
        this.tab.history = this.tab.history || {}
        this.tab.history.commands = this.tab.history.commands || []
        this.tab.history.commands.push(this)
    }

    async execute() {
        this.saveToHistory()
        this.run()
    }

    redo() {
        this.run()
    }

    undo() {
        this.tab.history.currentCommandIdx--
    }

    updateDbml() {
        new SchemaToDbml(this.tab).convert()
    }
}
