import { colorHelper } from "@/helpers/color-helper.js"
import { cssVar } from "@/helpers/misc"
import { canvasHelper } from "@/modules/canvas/canvas-helper.js"
import { borderColor, canvasFont, colorDark, colorLight, fontSize } from "@/modules/canvas/styles"
import { TableChild } from "@/modules/canvas/table/TableChild.js"

export class TableRow extends TableChild {
    paddingY = fontSize * 0.25
    highlighted = false
    maxChars = 10

    constructor(table, data, idx) {
        super(table, data)

        this.data = data

        this.index = idx
        this.color = data.color
        this.name = data.name
        this.type = data.type
        this.fk_table = data.fk_table
        this.fk_field = data.fk_field

        this.update()
    }

    get typeWidth() {
        return this.ctx.measureText(this.type?.slice(0, this.maxChars) + '...').width
    }

    get nameMaxWidth() {
        return this.table.width - this.paddingX * 2 - this.typeWidth - fontSize
    }

    get lines() {
        return canvasHelper.getLines(this.ctx, this.name, this.nameMaxWidth, "").length || 1
    }

    height() {
        return fontSize + this.paddingY * 2
    }

    update() {
        this.left = this.table.left
        this.top = this.table.top + this.table.header.height() + this.table.rows.slice(0, this.table.rows.indexOf(this)).reduce((t, r) => t + r.height(), 0)
    }

    get hasConnection() {
        return this.data?.fk && this.fk_field && this.fk_table
    }


    drawComment() {
        this.ctx.beginPath();
        this.ctx.moveTo(this.right, this.top);
        this.ctx.lineTo(this.right - 10, this.top);
        this.ctx.lineTo(this.right, this.top + 10);
        this.ctx.fillStyle = cssVar('--golden')
        this.ctx.fill();
    }

    draw() {
        this.update()

        // rect
        if (this.highlighted || (this.table.isHoveredObject && this.hasConnection)) {
            this.ctx.fillStyle = cssVar('--highlighted-bg')
        } else if (this.data.color && this.data.color !== '#ffffff') {
            this.ctx.fillStyle = this.data.color
        } else {
            this.ctx.fillStyle = cssVar('--body-bg')
        }
        this.ctx.fillRect(this.left, this.top, this.table.width, this.height())


        this.ctx.font = this.data.pk ? `bold ${canvasFont}` : canvasFont
        // type
        let left = this.left + this.table.width - this.typeWidth - this.paddingX
        if (this.data.color)
            this.ctx.fillStyle = colorHelper.isLight(this.data.color) ? colorDark : colorLight
        else
            this.ctx.fillStyle = cssVar('--main-text-color')
        canvasHelper.truncatedText(this.ctx, this.type, left + this.paddingX, this.top + this.paddingY, fontSize, 9)

        // name
        if (this.data.color) {
            this.ctx.fillStyle = colorHelper.isLight(this.data.color) ? colorDark : colorLight
        } else {
            this.ctx.fillStyle = cssVar('--main-text-color')
        }
        this.ctx.fillText(this.name, this.left + this.paddingX, this.top + this.paddingY)

        // border bottom
        canvasHelper.line(
            this.ctx,
            this.left,
            this.top + this.height(),
            this.left + this.table.width,
            this.top + this.height(),
            borderColor,
            1
        )

        // console.log('lines', lines)
        // this.ctx.fillText(this.name, this.left + this.paddingX, this.top + this.paddingY)

        if (this.data.comment) {
            this.drawComment()
        }

        this.drawHighlightedState()
    }

    drawHighlightedState() {
        const isHighlighted = this.table.canvas.highlightedItem === this.data
        if (isHighlighted) {
            const ctx = this.ctx
            ctx.beginPath()
            ctx.strokeStyle = colorHelper.isLight(this.data.color) ? cssVar('--warning') : cssVar('--primary')
            ctx.lineWidth = 3
            ctx.strokeRect(this.left, this.top, this.table.width, this.height())
            ctx.stroke()
            ctx.closePath()
        }
    }
}
