<script lang="ts" setup>
import {requiredRule} from '@/Vee/rules/required.rule'
import {useValidator} from '@/Vee/useValidator'
import {Validator} from '@/Vee/validator'
import UButton from 'nvd-u/components/UButton.vue'
import UTextarea from 'nvd-u/components/UTextarea.vue'
import WaitingAnimation from 'src/components/animated/WaitingAnimation.vue'
import {useToggle} from 'src/composables/useToggle'
import {useRouter} from 'vue-router'
import {useSchemaStore} from "../../stores/schema.store";
import CopyBtn from "../common/CopyBtn.vue";

const schema = useSchemaStore()
const router = useRouter()
const aiExamples = [
    {
        label: 'Book Store',
        desc: `An online store selling books`
    },
    {
        label: 'Trucking Business',
        desc: `Database for a trucking business
that provides logistics, transportation
and storage services to various
industries in Texas and Louisiana`
    },
    {
        label: 'Library Management System',
        desc: `A digital library management system that keeps record of
the inventory, customers and deadlines etc.`
    },
]
const {toggle, selected} = useToggle()

const v = useValidator(schema.aiForm, (v: Validator) => {
    v.addRule(requiredRule('prompt'))
})

async function save() {
    v.validate()
    if (v.hasErrors) return
    schema.createSchemaUsingAi()
}

interface GenerateWithAIEvent {
    failed: boolean
    message: string
    name: string
}

function getTitle() {
    let str = schema.aiForm.prompt.slice(0, 25)
    let lastSpaceIdx = str.indexOf(' ', -1)
    return str.slice(0, lastSpaceIdx)
}

function trimJson(json) {
    return json.slice(json.indexOf('{'), json.lastIndexOf('}') + 1)
}
</script>

<template>
    <form class="gen-with-ai" @submit.prevent="save">
        <div v-if="!schema.aiForm.loading">
            <UTextarea
                class="ai-prompt"
                v-model="schema.aiForm.prompt"
                label="What's your schema about?"
                :errors="v.errors.prompt"
                :disabled="schema.aiForm.loading"
            />
            <div class="text-danger text-small" v-if="schema.aiForm.error">{{ schema.aiForm.error }}</div>
            <h4>Examples</h4>
            <div class="mb-3 mx-0 ai-example card"
                 v-for="example in aiExamples">
                <div class="header p-3 align-items-center"
                     :class="{'border-b1': selected === example.label,'bg-dark': selected === example.label, 'has-hover' : selected !== example.label}"
                     @click="toggle(example.label)">
                    {{ example.label }}
                </div>
                <div class="body" :class="{'expanded': selected === example.label}">
                    <CopyBtn v-if="selected === example.label" :text="example.desc"
                             @copy="text => schema.aiForm.prompt = text"/>
                    <pre class="p-3 w100">{{ example.desc }}</pre>
                </div>
            </div>
        </div>
        <div v-else>
            <WaitingAnimation class="mb-4"/>
            <div class="text-muted text-center">
                Generating your schema. You will be redirected to your project when done.
            </div>
        </div>
        <UButton class="mt-5" @click="save" :loading="schema.aiForm.loading" v-if="!schema.aiForm.loading">
            Generate
        </UButton>
    </form>
</template>

<style lang="scss">
.gen-with-ai {
    .u-textarea .u-form-group .u-input {
        min-height: 13em;
        line-height: 1.2;
    }

    .ai-example {
        border-radius: var(--border-radius);
        overflow: hidden;

        .header {
            cursor: pointer;
        }

        .body {
            position: relative;
            padding: 0;
            height: 0;
            transition: 0.25s;

            &.expanded {
                height: 11em;
                transition: 0.25s;
            }

            pre {
                margin-bottom: 0;
                padding: 1em;
                background-color: var(--bg);
            }

            .copy-btn {
                position: absolute;
                right: 0.5em;
                top: 0.5em;
            }
        }
    }
}
</style>
