import { _sleep } from "@/helpers/misc"
import { FileHelper } from "nvd-js-helpers/FileHelper";
import JSZip from "jszip";

export class CanvasDownloader {
    tab
    maxWidth
    maxHeight

    constructor(tab) {
        this.tab = tab
    }

    async captureImage({ name = 'generated', bg = '#fff' }) {
        let canvas = this.tab.canvas
        let element = canvas.element
        const padding = 50
        let widthO = canvas.width
        let heightO = canvas.height
        let maxWidth = this.maxWidth = canvas.maxX - canvas.minX + padding
        let maxHeight = this.maxHeight = canvas.maxY - canvas.minY + padding

        canvas.setZoom(1)
        canvas.updateOrigin(0, 0)
        canvas.updateCanvasSize(maxWidth, maxHeight)

        const maxSize = 2000
        let numChunks = Math.ceil(Math.max(maxHeight, maxWidth) / maxSize)
        let isPortrait = maxHeight > maxWidth
        let w = (isPortrait || numChunks === 1 ? maxWidth : maxSize) + padding * 4
        let h = (isPortrait && numChunks > 1 ? maxSize : maxHeight)
        const minX = canvas.minX - padding * 2
        const minY = canvas.minY
        canvas.updateCanvasSize(w, h)
        await _sleep(500)

        const images = []
        for (let i = 0; i < numChunks; i++) {
            let ox = isPortrait ? -minX : -minX - (i * maxSize)
            let oy = isPortrait ? -minY - (i * maxSize) : -minY

            canvas.updateOrigin(ox, oy)
            await _sleep(500)

            // create temp canvas
            let tempC = document.createElement('canvas')
            let ctx = tempC.getContext('2d')
            // adjust the width of the temp canvas
            tempC.width = this.maxWidth = w + padding * 2
            tempC.height = this.maxHeight = h + padding * 2
            // draw background
            ctx.fillStyle = bg
            ctx.fillRect(0, 0, tempC.width, tempC.height)

            // draw the canvas img onto the temp canvas
            ctx.drawImage(element, padding, padding, w, h)

            // watermark
            ctx.font = `italic 16px Roboto, "Helvetica Neue", Arial, sans-serif`
            ctx.fillStyle = 'rgba(0, 0, 0, 0.2)'
            const appName = `Generated with ${window.location.hostname}`
            ctx.fillText(appName, 10, tempC.height - 10)

            images.push(tempC.toDataURL('image/png', 1).replace('image/png', 'image/octet-stream'))
        }

        canvas.updateCanvasSize(widthO, heightO)
        canvas.resetZoomAndCenter()

        return images
    }

    downloadImage({ name = 'generated', bg = '#fff' }) {
        return this.captureImage({ name, bg }).then(async (dataUrls) => {
            const zip = new JSZip();

            dataUrls.forEach((dataUrl, index) => {
                const imgName = `${name}_${index + 1}.png`;
                zip.file(imgName, dataUrl.split(',')[1], { base64: true });
            });

            const zipData = await zip.generateAsync({
                type: "blob",
            });

            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(zipData);
            link.download = `${name}.zip`;
            link.click();
        })
    }

    downloadPdf({ name = 'generated', bg = '#fff' }) {
        return this.captureImage({ name, bg }).then(async dataUrls => {
            let height = this.maxHeight
            let width = this.maxWidth
            const jsPdfLimit = 6000
            const heightRatio = jsPdfLimit / height
            const widthRatio = jsPdfLimit / width
            const ratio = Math.min(heightRatio, widthRatio)
            if (ratio < 1) {
                height = Math.round(height * ratio)
                width = Math.round(width * ratio)
            }

            const jsPDF = (await import('jspdf')).jsPDF

            let doc = new jsPDF({
                orientation: width > height ? 'landscape' : 'portrait',
                unit: 'px',
                hotfixes: ['px_scaling'],
                format: [width, height]
            })
            for (let i = 0; i < dataUrls.length; i++) {
                if (i > 0) doc.addPage([width, height], width > height ? 'landscape' : 'portrait')
                doc.addImage(dataUrls[i], 'png', 0, 0, width, height, '', 'FAST')
            }
            doc.save(name + '.pdf')
        })
    }
}
