import { MoveMultipleObjectsCommand } from "@/commands/MoveMultipleObjectsCommand.js"
import { MoveObjectCommand } from "@/commands/MoveObjectCommand.js"
import { CANVAS_SETTINGS_KEY, LINE_TYPES } from "@/data/constants"
import { _sleep } from "@/helpers/misc"
import { useStorage } from 'nvd-use-storage'
import { CanvasSelectedItems } from "@/modules/canvas/CanvasSelectedItems.js"
import { Mouse } from "@/modules/canvas/Mouse.js"
import { canvasFont, fontSize } from "@/modules/canvas/styles.js"
import { Connection } from "@/modules/canvas/table/Connection.js"
import { Note } from "@/modules/canvas/table/Note.js"
import { Table } from "@/modules/canvas/table/Table.js"
import { Easing, Tween } from "@tweenjs/tween.js"
import { Storage } from "nvd-js-helpers/storage-helper";
import { cssVar } from "../../helpers/misc";
import { LogicalConnection } from "./table/LogicalConnection";
import { useMenuStore } from "../../stores/menu.store";
import { useSettingsStore } from "../../stores/settings.store";

export class ApmCanvas {
    element
    ctx
    width = 800
    height = 600
    origin = {x: 0, y: 0}
    lastOrigin = {x: 0, y: 0}
    mouse
    draggedObject = null
    hoveredObject = null
    hoveredItem = null
    selectedObject = null
    highlightedItem = null
    editedNote = null
    editedTable = null
    editedField = null
    selectedLogicalTable = null
    minX = 0
    minY = 0
    maxX = 1600
    maxY = 1200
    zoom = {scale: 1, delta: 0.4, min: 0.06, max: 2.5}
    selectedItems
    tab
    settings = {}

    constructor(tab) {
        this.tab = tab
        this.mouse = new Mouse()
        this.selectedItems = new CanvasSelectedItems()
        this.settings = useStorage(CANVAS_SETTINGS_KEY)
    }

    calculateCenter() {
        const x = (-this.origin.x + this.width / 2) / this.zoom.scale
        const y = (-this.origin.y + this.height / 2) / this.zoom.scale
        return {x, y}
    }

    addNewItem(item) {
        let {x, y} = this.calculateCenter()
        item.left = item.left || x - 100
        item.top = item.top || y - 100
    }

    setEditedNote(note) {
        this.editedNote = note
    }

    setEditedTable(table) {
        this.editedTable = table
    }

    setEditedField(field) {
        this.editedField = field
    }

    updateSettings(key, value) {
        this.settings[key] = value
        Storage.setObject(CANVAS_SETTINGS_KEY, this.settings)
    }

    setSelectedObject(object) {
        if (!!object && !!this.selectedLogicalTable) {
            return this.setSelectedObject(null)
        }
        this.selectedObject = object
    }

    setDraggedObject(object) {
        this.draggedObject = object
        this.onDrag(object)
    }

    setHoveredObject(object) {
        this.hoveredObject = object
        this.onHover(object)
        if (!object) return this.setHoveredItem(null)
        this.setHoveredItem(object)
    }

    setHoveredItem(object) {
        this.hoveredItem = object ? object.fields ? new Table(this, object) : new Note(this, object) : null
    }

    setSelectedLogicalTable(object) {
        this.selectedLogicalTable = object
        if (!!object) this.setSelectedObject(null)
    }

    get isBiggerThanViewPort() {
        return this.width < this.maxX - this.minX || this.height < this.maxY - this.minY
    }

    get isTranslated() {
        const offset = 100
        const {x, y} = this.origin
        return x < -offset || x > offset || y < -offset || y > offset
    }

    get hasOffscreenItems() {
        return !this.isPointVisible(this.minX, this.minY)
            || !this.isPointVisible(this.minX, this.maxY)
            || !this.isPointVisible(this.maxX, this.minY)
            || !this.isPointVisible(this.minX, this.maxY)
    }

    draw(tab) {
        const menu = useMenuStore()
        // set defaults
        this.ctx.textBaseline = 'top'
        this.ctx.font = canvasFont

        this.ctx.save()
        this.clear()

        this.ctx.translate(this.origin.x, this.origin.y)

        this.ctx.scale(this.zoom.scale, this.zoom.scale)

        const rows = {}
        const connections = {}
        const logicalConnections = {}
        const pendingConnections = {}
        let minX = null
        let minY = null
        let maxX = null
        let maxY = null

        let items = tab.schema.schema_data.tables.concat(tab.schema.schema_data.notes)
        let tableObjects = {}
        for (const itemData of items) {
            if (itemData.checked === false) continue
            if (itemData.resource === 'note' && !itemData.content) continue

            let item = itemData.fields ? (new Table(this, itemData)) : new Note(this, itemData)
            item.draw()

            // update canvas points
            if (minX === null || item.left < minX) minX = item.left
            if (maxX === null || item.right > maxX) maxX = item.right
            if (minY === null || item.top < minY) minY = item.top
            if (maxY === null || item.bottom > maxY) maxY = item.bottom

            if (!item.rows) continue
            tableObjects[item.name] = item

            this.ctx.globalCompositeOperation = 'destination-over'
            if (tab.settings.view === 'detailed_view' || tab.settings.view === 'physical_relationships_view')
                for (const row of item.rows) {
                    // cache row for future ref
                    const rowId = `${item.name}--${row.name}`
                    rows[rowId] = row
                    // check for outgoing connection
                    if (row.hasConnection) {
                        let connection = new Connection(this, row)
                        let toRow = rows[connection.toRowId]
                        if (toRow) {
                            connection.toRow = toRow
                            connection.draw()
                        } else {
                            connections[connection.toRowId] = connections[connection.toRowId] || []
                            connections[connection.toRowId].push(connection)
                        }
                    }
                    // check for incoming connections
                    let incomingCons = connections[rowId]
                    if (incomingCons) {
                        for (const incomingCon of incomingCons) {
                            incomingCon.toRow = row
                            incomingCon.draw()
                        }
                    }
                }

            const connectionId = item.name
            logicalConnections[connectionId] = item

            for (const logicalConnection of item.logicalConnections) {
                if (logicalConnection.fromItem && logicalConnection.toItem)
                    logicalConnection.draw()
                else if (logicalConnection.fromItem && !logicalConnection.toItem) {
                    let toItem = tableObjects[logicalConnection.toItemId]
                    if (toItem) {
                        logicalConnection.toItem = toItem
                        logicalConnection.draw()
                    } else {
                        pendingConnections[logicalConnection.toItemId] = pendingConnections[logicalConnection.toItemId] || []
                        pendingConnections[logicalConnection.toItemId].push(logicalConnection)
                    }
                }
            }


            let pendingConnection = pendingConnections[connectionId]
            if (pendingConnection) {
                for (const connection of pendingConnection) {
                    connection.toItem = item
                    connection.draw()
                }
            }

            this.ctx.globalCompositeOperation = 'source-over'
            item = null
        }

        this.drawMouseConnector()

        if (minX) this.minX = minX
        if (maxX) this.maxX = maxX
        if (minY) this.minY = minY
        if (maxY) this.maxY = maxY

        this.ctx.restore()
    }

    async locateItem(item) {
        let cx = (this.width / this.zoom.scale) / 2
        let cy = (this.height / this.zoom.scale) / 2
        await this.updateOrigin((-item.left + cx - 100) * this.zoom.scale, (-item.top + cy - 100) * this.zoom.scale, async () => {
            this.highlightedItem = item
            await _sleep(600)
            this.highlightedItem = null
        })
    }

    get vpRight() {
        return (-this.origin.x + this.width) / this.zoom.scale
    }

    get vpBottom() {
        return (-this.origin.y + this.height) / this.zoom.scale
    }

    isPointVisible(x, y) {
        return x * this.zoom.scale > -this.origin.x && x < this.vpRight
            && y * this.zoom.scale > -this.origin.y && y < this.vpBottom
    }

    initialize(element) {
        this.element = element
        this.ctx = element.getContext('2d')
        this.updateCanvasSize()
        this.mouse.initialize(this)
    }

    setZoom(scale) {
        return new Tween(this.zoom)
            .to({scale}, 500)
            .easing(Easing.Cubic.Out)
            .onComplete(() => {
            })
            .start()
    }

    zoomIn() {
        if (this.zoom.scale < this.zoom.max) this.setZoom(this.zoom.scale + this.zoom.delta > this.zoom.max ? this.zoom.max : this.zoom.scale + this.zoom.delta)
    }

    zoomOut() {
        if (this.zoom.scale > this.zoom.min) this.setZoom(this.zoom.scale - this.zoom.delta > this.zoom.min ? this.zoom.scale - this.zoom.delta : this.zoom.min)
    }

    resetZoom(animate = true) {
        this.setZoom(1)
        let func = animate ? d => {
        } : null
        this.updateOrigin(0, 0, func)
    }

    resetZoomAndCenter() {
        this.setZoom(1)
        let sizeX = this.sizeX
        let cx = -this.minX + (this.width - sizeX) / 2
        this.updateOrigin(cx, 0, d => {
        })
    }

    clear() {
        let w = Math.max(parseInt(this.maxX), parseInt(this.width))
        let h = Math.max(parseInt(this.maxY), parseInt(this.height))
        this.ctx.clearRect(0, 0, w, h)
    }

    updateCanvasSize(w, h) {
        if (w) this.width = w
        if (h) this.height = h

        const dpr = window.devicePixelRatio

        this.element.width = this.width * dpr
        this.element.height = this.height * dpr

        this.ctx.scale(dpr, dpr)

        this.element.style.width = `${this.width}px`
        this.element.style.height = `${this.height}px`

        this.onSizeUpdate()
    }

    updateOrigin(x, y, onComplete) {
        if (!onComplete) {
            this.origin.x = x
            this.origin.y = y
            this.onOriginUpdated(this.origin)
            return
        }

        return new Tween(this.origin)
            .to({x, y}, 500)
            .easing(Easing.Cubic.Out)
            .onComplete(onComplete)
            .start()

    }

    // events
    onDragEnd() {
        const settings = useSettingsStore()
        if (settings.settings.lockDragging) {
            this.setDraggedObject(null)
        }
        if (this.draggedObject) {
            let selectedItems = this.selectedItems?.items || []
            if (selectedItems.length) {
                // following line handles the scenario when a user selects some items but the drags an item that isn't selected using shift key
                if (!selectedItems.includes(this.draggedObject)) selectedItems.push(this.draggedObject)

                let command = new MoveMultipleObjectsCommand(this.tab, {
                    objs: selectedItems,
                    left: this.mouse.dx / this.zoom.scale,
                    top: this.mouse.dy / this.zoom.scale
                })
                command.execute()
            } else {
                let command = new MoveObjectCommand(this.tab, {
                    obj: this.draggedObject,
                    left: this.mouse.dx / this.zoom.scale,
                    top: this.mouse.dy / this.zoom.scale
                })
                command.execute()
            }

            this.setDraggedObject(null)
        }
    }

    onOriginUpdated() {
    }

    onSizeUpdate() {
    }

    onSelect(item) {
        if (!item) {
            this.setSelectedObject(null)
            this.selectedItems.clear()
            return
        }
        this.setSelectedObject(item)
    }

    onHover(item) {
    }

    onDrag(item) {
    }

    drawMouseConnector() {
        // draw logical relationship connector when only 1 table is selected
        if (!this.selectedLogicalTable)
            return
        let mouseX = (-this.origin.x + this.mouse.x) / this.zoom.scale
        let mouseY = (-this.origin.y + this.mouse.y) / this.zoom.scale

        let tableX = this.selectedLogicalTable.cx
        let tableY = this.selectedLogicalTable.cy
        let direction = ['right', 'left']
        const directions = {left: -1, right: 1}

        if (this.selectedLogicalTable.cx > mouseX) {
            tableX = this.selectedLogicalTable.left
            direction = ['left', 'right']
        } else if (this.selectedLogicalTable.cx < mouseX) {
            tableX = this.selectedLogicalTable.right
        }

        if (this.selectedLogicalTable.cy < mouseY) {
            tableY = this.selectedLogicalTable.bottom
        } else if (this.selectedLogicalTable.cy > mouseY) {
            tableY = this.selectedLogicalTable.top
        }

        let from = direction[0]
        let to = direction[1]

        this.ctx.globalCompositeOperation = 'destination-over'
        this.ctx.beginPath();
        this.ctx.strokeStyle = cssVar('--warning')
        this.ctx.lineWidth = 3
        this.ctx.setLineDash([6]);
        const lineType = this.settings?.lineType || LINE_TYPES.BEZIER
        const offset = lineType === LINE_TYPES.FLOWCHART ? 30 : (lineType === LINE_TYPES.STRAIGHT ? 20 : 100)

        let fromX = tableX
        let fromY = tableY
        let toX = mouseX
        let toY = mouseY

        let cp1x = fromX + offset * directions[from]
        let cp1y = fromY
        let cp2x = toX + offset * directions[to]
        let cp2y = toY

        this.ctx.moveTo(fromX, fromY);


        if (lineType === LINE_TYPES.FLOWCHART) {
            const cpx = Math.max(cp1x, cp2x)
            this.ctx.lineTo(cpx, cp1y)
            this.ctx.lineTo(cpx, cp2y)
            this.ctx.lineTo(toX, toY)
        } else if (lineType === LINE_TYPES.STRAIGHT) {
            this.ctx.lineTo(cp1x, cp1y)
            this.ctx.lineTo(cp2x, cp2y)
            this.ctx.lineTo(toX, toY)
        } else {
            this.ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, toX, toY)
        }
        this.ctx.stroke();
    }

    getMax(a, b, key) {
        let item1 = new Table(this, a)
        let item2 = new Note(this, b)
        return Math.max(item1[key], item2[key])
    }

    newItemPosition(type = 'table') {
        const gap = 40
        let top = gap
        let left = gap

        let lastItem = type === 'note' ? this.tab.schema.schema_data.notes[this.tab.schema.schema_data.notes.length - 1] : this.tab.schema.schema_data.tables[this.tab.schema.schema_data.tables.length - 1]

        if (lastItem) {
            lastItem = type === 'note' ? new Note(this, lastItem) : new Table(this, lastItem)

            let lastTable = this.tab.schema.schema_data.tables?.length ? new Table(this, this.tab.schema.schema_data.tables[this.tab.schema.schema_data.tables.length - 1]) : {}
            let lastNote = this.tab.schema.schema_data.notes?.length ? new Note(this, this.tab.schema.schema_data.notes[this.tab.schema.schema_data.notes.length - 1]) : {}

            top = Math.max(lastTable.top || 0, lastNote.top || 0, lastItem.top || 0)
            left = Math.max(lastTable.right || 0, lastNote.right || 0, lastItem.right || 0) + gap


            if (left > this.width - 200) {
                top = Math.max(lastTable.bottom || 0, lastNote.bottom || 0, lastItem.bottom || 0) + gap
                left = gap
            }
        }
        return {top, left}
    }


    getItemPositionsForTables(tables) {
        const gap = 40

        let lastPositions = this.newItemPosition('table')

        let data = []

        for (const table of tables) {
            if (table.top !== null && table.left !== null) continue
            table.top = lastPositions.top
            table.left = lastPositions.left

            let tableObj = new Table(this, table)

            let top = Math.max(tableObj.top || 0)
            let left = Math.max(tableObj.right || 0) + gap

            if (left > this.width - tableObj.width) {
                top = Math.max(tableObj.bottom || 0) + gap * 2
                left = gap
            }

            lastPositions.top = top
            lastPositions.left = left

            data.push({
                p_id: table.p_id,
                resource: table.resource,
                top: tableObj.top,
                left: tableObj.left
            })
        }
        return data
    }

    get sizeX() {
        return this.maxX - this.minX
    }

    get sizeY() {
        return this.maxY - this.minY
    }

    fitToScreen() {
        let sizeX = this.sizeX
        let sizeY = this.sizeY
        let xRatio = this.width / sizeX
        let yRatio = this.height / sizeY
        let zoom = Math.max(Math.min(xRatio, yRatio) * 0.95, this.zoom.min)
        let cx = -this.minX * zoom + (this.width - sizeX * zoom) / 2
        let cy = -this.minY * zoom + (this.height - sizeY * zoom) / 2

        this.updateOrigin(cx, cy, d => {
        })
        this.setZoom(zoom > this.zoom.max ? this.zoom.max : zoom < this.zoom.min ? this.zoom.min : zoom)
    }

    fitWidth() {
        let sizeX = this.sizeX
        let xRatio = this.width / sizeX
        let zoom = Math.max(xRatio * 0.95, this.zoom.min)
        let cx = -this.minX * zoom + (this.width - sizeX * zoom) / 2

        this.updateOrigin(cx, 0, d => {
        })
        this.setZoom(zoom > this.zoom.max ? this.zoom.max : zoom < this.zoom.min ? this.zoom.min : zoom)
    }
}
