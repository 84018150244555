import { LINE_TYPES } from "@/data/constants"
import { cssVar } from "nvd-js-helpers/misc"
import { canvasHelper } from "@/modules/canvas/canvas-helper.js"

export class Connection {
    canvas
    toRowId
    fromRow
    toRow

    constructor(canvas, row) {
        this.canvas = canvas
        this.toRowId = `${row.fk_table}--${row.fk_field}`
        this.fromRow = row
    }

    draw() {
        // if (this.isOutOfScreen()) return
        let direction = ['right', 'left']
        if (this.fromRow.right <= this.toRow.left) direction = ['right', 'left']
        else if (this.fromRow.left >= this.toRow.right) direction = ['left', 'right']
        else if (this.fromRow.right <= this.toRow.right) direction = ['right', 'right']
        else if (this.fromRow.left >= this.toRow.left) direction = ['left', 'left']

        this.drawConnection(...direction)
    }

    drawConnection(from, to) {
        let fromX = this.fromRow[from]
        let toX = this.toRow[to]
        const fromY = Math.round(this.fromRow.top + this.fromRow.height() / 2)
        const toY = Math.round(this.toRow.top + this.toRow.height() / 2)

        // if (!this.canvas.isPointVisible(fromX, fromY) && !this.canvas.isPointVisible(toX, toY)) return

        const ctx = this.canvas.ctx
        const directions = { left: -1, right: 1 }

        const lineType = this.canvas?.settings?.lineType || LINE_TYPES.BEZIER
        const offset = lineType === LINE_TYPES.FLOWCHART ? 30 : (lineType === LINE_TYPES.STRAIGHT ? 20 : 100)
        const endPointSize = 3
        const arrowHeight = 5
        const arrowLength = 8
        let color = this.toRow?.table?.color || this.canvas?.settings?.lineColor || '#bbb'
        let lineWidth = 1

        let isFromHovered = this.fromRow.table.isHoveredObject
        let isToHovered = this.toRow.table.isHoveredObject
        if (isFromHovered || isToHovered) {
            color = this.toRow?.table?.color || cssVar('--primary')
            lineWidth = 2

            this.toRow.highlighted = true
            if (isToHovered) this.fromRow.highlighted = true

            ctx.globalCompositeOperation = 'source-over'
            this.toRow.draw()
            if (isToHovered) this.fromRow.draw()
            ctx.globalCompositeOperation = 'destination-over'
        }

        ctx.lineWidth = lineWidth
        ctx.fillStyle = color
        ctx.strokeStyle = color

        let cp1x = fromX + offset * directions[from]
        let cp1y = fromY
        let cp2x = toX + offset * directions[to]
        let cp2y = toY

        // the dot
        if (!this.fromRow.data.rel_type || (this.fromRow.data.rel_type === 'one_to_one' || this.fromRow.data.rel_type === 'one_to_many')) {
            canvasHelper.circle(ctx, fromX, fromY, endPointSize)
            ctx.fill()
        } else if (this.fromRow.data.rel_type === 'many_to_many' || this.fromRow.data.rel_type === 'many_to_one') {
            ctx.moveTo(fromX + arrowLength * directions[from], fromY)
            ctx.lineTo(fromX, fromY + arrowHeight)
            ctx.lineTo(fromX, fromY - arrowHeight)
            ctx.lineTo(fromX + arrowLength * directions[from], fromY)
            ctx.stroke()
        }

        // the line
        ctx.beginPath()
        ctx.moveTo(fromX, fromY)
        if (lineType === LINE_TYPES.FLOWCHART) {
            const cpx = Math.max(cp1x, cp2x)
            ctx.lineTo(cpx, cp1y)
            ctx.lineTo(cpx, cp2y)
            ctx.lineTo(toX, toY)
        } else if (lineType === LINE_TYPES.STRAIGHT) {
            ctx.lineTo(cp1x, cp1y)
            ctx.lineTo(cp2x, cp2y)
            ctx.lineTo(toX, toY)
        } else {
            ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, toX, toY)
        }
        ctx.stroke()
        ctx.closePath()

        // the arrow
        ctx.beginPath()
        if (!this.fromRow.data.rel_type || this.fromRow.data.rel_type === 'one_to_one' || this.fromRow.data.rel_type === 'many_to_one') {
            ctx.moveTo(toX, toY)
            ctx.lineTo(toX + arrowLength * directions[to], toY + arrowHeight)
            ctx.lineTo(toX + arrowLength * directions[to], toY - arrowHeight)
            ctx.lineTo(toX, toY)
            ctx.fill()
        } else if (this.fromRow.data.rel_type === 'one_to_many' || this.fromRow.data.rel_type === 'many_to_many') {
            ctx.moveTo(toX + arrowLength * directions[to], toY)
            ctx.lineTo(toX, toY + arrowHeight)
            ctx.lineTo(toX, toY - arrowHeight)
            ctx.lineTo(toX + arrowLength * directions[to], toY)
            ctx.stroke()
        }
        ctx.closePath()
    }
}
