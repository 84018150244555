import {useNotify} from "nvd-u/composables/Notifiy";
import i18n from "../locales/translations";

export function parseJwt(token) {
    try {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    } catch (e) {
        console.log('error while parsing token', e)
        return null
    }
}

export function copyText(text, header, subheader) {
    if (navigator.clipboard) {
        const notify = useNotify()
        navigator.clipboard.writeText(text).then(() => {
            // @ts-ignore
            notify.success(i18n.global.t(`dialog.${header}`), i18n.global.t(`dialog.${subheader}`))
        })
    }
}

export function showSimpleToast(text, duration = 3000) {
    let div = document.createElement('div')
    div.style = `
                padding: 0.5em;
                background-color: var(--dark);
                color: var(--bg);
                position: fixed;
                right: 1em;
                top: 1em;
                z-index: 999;
                border-radius: 0.5em;
                box-shadow: var(--shadow)
            `
    div.textContent = text
    document.body.append(div)
    setTimeout(() => {
        div.remove()
    }, duration)
}

export function _debounce(func, timeout = 300) {
    let timer;
    return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            func.apply(this, args);
        }, timeout);
    };
}

export function _sleep(milliseconds) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds)
    })
}

export function _deepClone(obj) {
    return JSON.parse(JSON.stringify(obj))
}

export class DebounceFn {
    timer = null
    delay = 0

    constructor(delay) {
        this.delay = delay
    }

    run(fn) {
        clearTimeout(this.timer)
        this.timer = setTimeout(fn, this.delay)
    }
}

export function randomInt(from, to) {
    return Math.floor((Math.random() + from) * to)
}

export function cssVar(variable, value) {
    let r = document.querySelector(':root')
    if (value) {
        return r.style.setProperty(variable, value)
    }
    let rs = getComputedStyle(r)
    return rs.getPropertyValue(variable)
}


export function isValidLink(value) {
    const pattern = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/
    return pattern.test(value)
}
