<script setup lang="ts"></script>

<template>
    <div class="all-center gap-4 border-b1 py-3">
        <slot></slot>
    </div>
</template>

<style scoped lang="scss">

</style>
