import { defineStore } from "pinia";
import { FetchRequest } from "../helpers/fetch-request";
import { useStorage } from 'nvd-use-storage'
import { COPIED_OBJECT_KEY, data_types, TABS_KEY } from "@/data/constants";
import { undoRedoActions, undoRedoGetters } from "@/stores/undoRedo";
import { tabHelper } from "@/helpers/tabHelper";
import { _deepClone } from "@/helpers/misc";
import { defaultHistory, newComment } from "@/data/tab-template";
import { ApmCanvas } from "@/modules/canvas/ApmCanvas";
import { SchemaToDbml } from "@/components/code-editor/schemaToDbml";
import router from "@/router/routes";
import { env } from "@/env";
import { useMenuStore } from "@/stores/menu.store";
import dayjs from "dayjs";
import { useAuthStore } from "@/stores/auth.store";
import { UpdateTableCommand } from "@/commands/UpdateTableCommand";
import { useSchemaStore } from "@/stores/schema.store";
import { DeleteTableCommand } from "@/commands/DeleteTableCommand";
import { Storage } from "nvd-js-helpers/storage-helper";
import { DeleteMultipleItemsCommand } from "../commands/DeleteMultipleItemsCommand";
import i18n from "@/locales/translations";
import { TabSettings } from "../classes/TabSettings";

let tabs = Storage.getObject(TABS_KEY, false)
if (tabs && tabs.length) {
    tabs = tabs.filter(tab => {
        tab.updated = false
        return !!tab.schema.slug
    })
}

export const useTabsStore = defineStore('tabs', {
    state: () => ({
        tabs: tabs?.length ? tabs : [],
        selectedTab: null,
        dataTypeReq: new FetchRequest('data_types', 'GET'),
        aiDbTypesReq: new FetchRequest('supported_databases', 'get'),
        createCommentReq: new FetchRequest('', 'POST'),
        getCommentsReq: new FetchRequest('', 'GET'),
        restoreReq: new FetchRequest('', 'GET'),
        copiedObject: useStorage(COPIED_OBJECT_KEY),
        showNewTabConfirmationDialogue: false
    }),
    getters: {
        ...undoRedoGetters,
        dataTypesList: state => state.dataTypeReq.data.data_types ? state.dataTypeReq?.data?.data_types[state.selectedTab?.schema?.db] : [],
        dbTypes: state => Object.keys(state.dataTypeReq.data?.data_types || {}),
        aiDbTypes: state => {
            if (state.aiDbTypesReq.loaded && state.aiDbTypesReq.data) return state.aiDbTypesReq.data
            return data_types
        },
        isSchemaReadonly: state => state.selectedTab?.schema?.is_read_only,
        isTemplateReadonly: state => state.selectedTab?.schema?.template && !state.selectedTab?.schema?.is_owner,
    },
    actions: {
        // mutations
        async selectTab(tab, fitToScreen = false) {
            const menu = useMenuStore()
            const schema = useSchemaStore()
            // get the most recent data
            if (tab.hasOwnProperty('updated') && !tab.updated) {
                tab.loading = true
                let req = schema.singleSchemaReq
                req.url = `schemas/${tab.schema.slug}`
                await req.send().then(data => {
                    tab.updated = true
                    tab.schema = data
                    tab.loading = false
                    fitToScreen = true

                    menu.determineMetaData(tab)
                }).catch(error => {
                    if (typeof error === 'string' && error.toLowerCase().includes('schema does not exist') || error.toLowerCase().includes('does not belong')) {
                        this.tabs.splice(this.tabs.findIndex(t => t.id === tab.id), 1)
                        Storage.setObject(TABS_KEY, this.tabs)
                        window.location.href = '/dashboard'
                    }
                })
            }
            tab.id = tabHelper.tabId(tab)
            tab.dbml = tab.dbml || ''
            tab.history = tab.history || _deepClone(defaultHistory)
            tab.comments = tab.comments || []
            tab.canvas = tab.canvas || new ApmCanvas(tab)
            tab.newComment = tab.newComment || { ...newComment }
            tab.settings = tab.settings || new TabSettings()

            await new SchemaToDbml(tab).convert()

            this.selectedTab = tab

            let id = tab.schema.slug || tab.schema.id || 'new'
            let url = `/designer/schema/${id}`
            if (menu.embedded) {
                url = `/designer/schema/${id}?embed=true`
            }
            router.push(url)
            document.title = tab.schema.title + " | " + env.appName

            setTimeout(() => {
                // check if it needs auto sorting
                if ((tab.schema.schema_data.tables.length || tab.schema.schema_data.notes.length) && !(tab.schema.schema_data.tables.concat(tab.schema.schema_data.notes).find(o => o.top && o.left)))
                    schema.sortItems()
            }, 500)


            if (fitToScreen) {
                setTimeout(() => menu.handleMenuItemClick({ id: 'fitToScreen' }), 100)
            }
        },
        setCopiedObject(obj) {
            this.copiedObject = obj
            Storage.setObject(COPIED_OBJECT_KEY, obj)
        },
        setShowNewTabConfirmationDialogue(payload) {
            this.showNewTabConfirmationDialogue = payload
        },
        // actions
        ...undoRedoActions,
        selectFirstTab() {
            this.selectTab(this.tabs[0])
        },
        saveTabsInStorage() {
            let tabs = this.tabs.map(tab => ({
                schema: _deepClone(tab.schema),
                settings: tab.settings
            }))
            Storage.setObject(TABS_KEY, tabs, false)
        },
        loadComments() {
            if (!this.selectedTab.schema.slug) return
            if (this.isSchemaReadonly || this.isTemplateReadonly) return
            if(!this.selectedTab.schema.id || this.selectedTab.schema.template) return
            this.getCommentsReq.url = `schemas/${this.selectedTab.schema.slug}/schema_comments`
            this.getCommentsReq.send().then(comments => {
                this.selectedTab.comments = comments
            })
        },
        createComment() {
            const auth = useAuthStore()
            this.createCommentReq.url = `schemas/${this.selectedTab.schema.id}/schema_comments`
            let dataToSend = {
                ...this.selectedTab.newComment,
                commenter: auth.user?.tmpid,
            }

            let comment = {
                ...dataToSend,
                user_id: dataToSend.commenter,
                commenter: auth.user?.name,
                time: dayjs().format('DD MMM YYYY HH:mm'),
                avatar: auth.user.avatar
            }
            this.selectedTab.comments.push(comment)
            this.selectedTab.newComment = { ...newComment }

            this.createCommentReq.send({ body: JSON.stringify(dataToSend) }).then(res => {
                let _comment = res.content ? res : res.pop()
                for (const key in _comment) {
                    comment[key] = _comment[key]
                }
            }).catch(res => {
                this.selectedTab.comments.shift()
            })
        },
        saveTable(data) {
            let command = new UpdateTableCommand(this.selectedTab, {
                table: this.selectedTab.canvas.editedTable,
                data
            })
            if (this.selectedTab.canvas.editedTable.$newTable) {
                delete this.selectedTab.canvas.editedTable.$newTable
            }
            command.execute()

            this.selectedTab.canvas.setEditedTable(null)
        },
        deleteTable(table) {
            (new DeleteTableCommand(this.selectedTab, { table })).execute()
            this.selectedTab.canvas.setEditedTable(null)
            this.selectedTab.canvas.setSelectedObject(null)
            this.selectedTab.canvas.setHoveredObject(null)
        },
        closeTab() {
            this.saveTabsInStorage()
        },
        async handleWindowClosed() {
            document.body.onbeforeunload = e => {
                let unsavedTabs = this.tabs.filter(tab => tab.unsaved)
                if (unsavedTabs.length) {
                    e.preventDefault()
                    return 'There is unsaved data. Are you sure you want to close this window?'
                }
            }
        },
        resetCopiedObject() {
            this.copiedObject = null
            Storage.setObject(COPIED_OBJECT_KEY, null)
        },
        findTabBySchemaId(schemaId) {
            return this.tabs.find(tab => tab.schema?.id == schemaId || tab.id == schemaId)
        },
        deleteSelectedItems(items = null) {
            const command = new DeleteMultipleItemsCommand(this.selectedTab, { items: items || this.selectedTab.canvas.selectedItems.items })
            command._description = this.getDeleteMultipleTitle()
            command.execute()

            this.selectedTab.canvas.selectedItems.items = []
        },
        clearSelectedItems() {
            this.selectedTab.canvas.selectedItems.items = []
            this.selectedTab.canvas.selectedObject = null
            this.selectedTab.canvas.setSelectedLogicalTable(null)
        },
        getDeleteMultipleTitle() {
            let title = ''
            if (!this.selectedTab?.canvas?.selectedItems?.items?.length) return title
            let isTables = this.selectedTab.canvas.selectedItems.items.findIndex(obj => !!obj.fields)
            let isNotes = this.selectedTab.canvas.selectedItems.items.findIndex(obj => !!!obj.fields)
            if (isTables !== -1 && isNotes !== -1)
                title = i18n.global.t('designer.delete_n_items', { count: this.selectedTab.canvas.selectedItems.items.length })
            else if (isTables !== -1)
                title = i18n.global.t('designer.delete_n_tables', { count: this.selectedTab.canvas.selectedItems.items.length })
            else
                title = i18n.global.t('designer.delete_n_notes', { count: this.selectedTab.canvas.selectedItems.items.length })
            return title
        },
        highlightTableMarkup(tableName) {
            let editor = this.selectedTab.editor
            if (!editor) return

            editor.findAll(tableName, {
                wrap: true,
                caseSensitive: false,
                wholeWord: true,
                regExp: false
            });

            let range = editor.selection.getAllRanges()[0];
            if (range) {
                // Scroll to the first occurrence
                editor.scrollToLine(range.start.row, true, true, function () {
                });
            }
        },
        selectAll() {
            this.selectedTab.canvas.selectedItems.selectMany(this.selectedTab.schema.schema_data.tables.concat(this.selectedTab.schema.schema_data.notes))
        }
    }
})
