import {useSocketIo} from 'src/composables/useSocketIo'
import {useTabsStore} from 'src/stores/tabs.store'
import {useSchemaStore} from 'src/stores/schema.store'
import {useMenuStore} from 'src/stores/menu.store'
import {useAuthStore} from 'src/stores/auth.store'
import {useNotify} from "nvd-u/composables/Notifiy";
import {SchemaToDbml} from "src/components/code-editor/schemaToDbml.js";
import {FileHelper} from "@/helpers/file-helper";
import {tabHelper} from "@/helpers/tabHelper";
import {useConfirm} from "nvd-u/composables/Confirm";
import {FetchRequest} from "../helpers/fetch-request";

function isUnauthenticated(updated_by) {
    const auth = useAuthStore()
    return !auth.user || (auth.user.id === updated_by)
}

function updateItems(items, updated_by, schemaId) {
    const tab = useTabsStore()
    if (isUnauthenticated(updated_by)) return
    let currentTab = tab.findTabBySchemaId(schemaId)
    if (!currentTab) return
    items.forEach(item => {
        let existing
        existing = item.resource === 'table' ? currentTab.schema.schema_data.tables.find(t => t.p_id === item.p_id) :
            currentTab.schema.schema_data.notes.find(n => n.p_id === item.p_id)
        if (existing) {
            for (const [key, value] of Object.entries(item)) {
                existing[key] = value
            }
        } else {
            item.resource === 'table' ?
                currentTab.schema.schema_data.tables.push(item)
                :
                currentTab.schema.schema_data.notes.push(item)
        }
    })
    new SchemaToDbml(currentTab).convert()
}

function removeItems(items, updated_by, schemaId) {
    const tab = useTabsStore()
    if (isUnauthenticated(updated_by)) return
    let currentTab = tab.findTabBySchemaId(schemaId)
    if (!currentTab) return
    items.forEach(item => {
        let itemIndex
        if (item.resource === 'table') {
            itemIndex = currentTab.schema.schema_data.tables.findIndex(t => t.p_id === item.p_id)
            if (itemIndex !== -1)
                currentTab.schema.schema_data.tables.splice(itemIndex, 1)
        } else if (item.resource === 'note') {
            itemIndex = currentTab.schema.schema_data.notes.findIndex(n => n.p_id === item.p_id)
            if (itemIndex !== -1)
                currentTab.schema.schema_data.notes.splice(itemIndex, 1)
        }
        if (itemIndex === -1) return
    })
    new SchemaToDbml(currentTab).convert()
}

function updateSchema(schema, updated_by) {
    const tab = useTabsStore()
    if (isUnauthenticated(updated_by)) return
    let selectedTab = tab.tabs.find(t => t.schema.slug === schema.slug)
    if (!selectedTab) return
    for (const [key, value] of Object.entries(schema)) {
        selectedTab.schema[key] = value
    }
}

function updateSorting(items, updated_by, schemaId) {
    const tab = useTabsStore()
    if (isUnauthenticated(updated_by)) return
    let currentTab = tab.findTabBySchemaId(schemaId)
    if (!currentTab) return
    items.forEach(item => {
        let existing
        existing = item.resource === 'table' ? currentTab.schema.schema_data.tables.find(t => t.p_id === item.p_id) :
            currentTab.schema_data.notes.find(n => n.p_id === item.p_id)
        if (existing) {
            existing.top = item.top
            existing.left = item.left
        }
    })
}

function updateFields(fields, updated_by, schemaId) {
    const tab = useTabsStore()
    if (isUnauthenticated(updated_by)) return

    let currentTab = tab.findTabBySchemaId(schemaId)
    if (!currentTab) return
    fields.forEach(field => {
        let table = currentTab.schema.schema_data.tables.find(t => t.p_id === field.parent_id)
        if (table) {
            let existingField = table.fields.find(f => f.p_id === field.p_id)
            if (!existingField) return table.fields.push(field)
            for (const [key, value] of Object.entries(field)) {
                existingField[key] = value
            }
            // if any field is checked, table is checked
            // if all fields are unchecked, table is unchecked
            table.checked = !!table.fields.find(f => f.checked);
        }
    })
}

function connectUser(data) {
    const auth = useAuthStore()
    const tab = useTabsStore()
    const notify = useNotify()
    let currentTab = tab.findTabBySchemaId(data.schema_id)
    if (!currentTab) return
    currentTab.schema?.members?.forEach(member => {
        let idx = data.activeUsers.findIndex(m => m.id === member.id)
        if (idx !== -1) {
            if (member.id !== auth.user.id && tab.selectedTab.id === currentTab.id)
                notify.success(`Member Joined`, `${member.name || member.email} just joined live collaboration`)
            Object.keys(member).forEach(key => {
                member[key] = data.activeUsers[idx][key]
            })
        }
    })
}

function disconnectUser(data) {
    const auth = useAuthStore()
    const tab = useTabsStore()
    const notify = useNotify()
    let currentTab = tab.findTabBySchemaId(data.schema_id)
    if (!currentTab) return
    currentTab.schema?.members?.forEach(member => {
        let idx = data.inactiveUsers.findIndex(m => m.id === member.id)
        if (idx !== -1) {
            if (member.id !== auth.user.id && tab.selectedTab.id === currentTab.id)
                notify.success(`Member Left`, `${member.name || member.email} just left live collaboration`)
            Object.keys(member).forEach(key => {
                member[key] = data.inactiveUsers[idx][key]
            })
        }
    })
}

function updateMembers(data) {
    const tab = useTabsStore()
    const auth = useAuthStore()
    let currentTab = tab.findTabBySchemaId(data.schema_id)
    if (!currentTab) return
    if (!data.members_list.find(m => m.id === auth.user.id)) {
        const socketIo = useSocketIo(currentTab.id)
        socketIo.io.send(JSON.stringify({
            "command": "unsubscribe",
            "identifier": `{"channel":"SchemaChannel", "id":"${currentTab.id}"}`
        }))
        return
    }
    return currentTab.schema.members = data.members_list
}

export function onEditorEvents(selectedTab) {
    let io = useSocketIo(selectedTab.id).io
    const auth = useAuthStore()
    const schema = useSchemaStore()
    const menu = useMenuStore()
    const notify = useNotify()
    const tabs = useTabsStore()
    if (!auth.isLoggedIn) return

    let tablesToUpdatePositions = []

    io.onmessage = async event => {
        let data = JSON.parse(event.data)
        if (!data || !data.message || !data.message.data?.type) return
        data = data.message.data
        if (!data) return
        console.log("Event Received ", data)
        switch (data.type) {
            case 'update note':
            case 'update table':
            case 'update resources': {
                updateItems(data.schema, data.updated_by, data.schema_id)
                break;
            }
            case 'remove resource': {
                removeItems(data.schema, data.updated_by, data.schema_id)
                break;
            }
            case 'update schema': {
                updateSchema(data.schema, data.updated_by)
                break;
            }
            case 'update sorting': {
                updateSorting(data.schema, data.updated_by, data.schema_id)
                break;
            }
            case 'update field': {
                updateFields(data.schema, data.updated_by, data.schema_id)
                break;
            }
            case 'memberJoined': {
                connectUser(data)
                break;
            }
            case 'memberLeft': {
                disconnectUser(data)
                break;
            }
            case 'collaborator_updated': {
                updateMembers(data)
                break;
            }
            case 'ai template': {
                // todo - validate tab/user checks
                schema.aiForm.loading = false
                menu.toggleCreateNewSchemaModalOpen(false)
                let tab = {schema: data}
                let existingTab = tabs.tabs.find(t => t.schema.id === tab.schema.id)
                if (!existingTab)
                    schema.openNewTab(tab)
                else
                    for (const [key, value] of Object.entries(data)) {
                        existingTab.schema[key] = value
                    }
                new SchemaToDbml(tabs.selectedTab).convert()
                schema.sortItems(false)
                break;
            }

            case 'ai import' : {
                let tab = tabs.tabs.find(t => t.schema.id === data.id)
                if (!tab) return

                tab.isImporting = true

                for (const [key, value] of Object.entries(data)) {
                    tab.schema[key] = value
                }

                tab.canvas.getItemPositionsForTables(tab.schema.schema_data.tables)

                new SchemaToDbml(tab).convert()
                schema.importLoading = false
                menu.toggleImportSqlModal(false)

                break;
            }

            case 'ai complete': {
                let tab = tabs.tabs.find(t => t.schema.id === data.id)
                if (!tab) return

                delete tab['isImporting']

                for (const [key, value] of Object.entries(data)) {
                    tab.schema[key] = value
                }

                let updateTablePositions = tab.canvas.getItemPositionsForTables(tab.schema.schema_data.tables)
                let sortItemsReq = new FetchRequest(`schemas/${tab.schema.slug}/sort_items`, 'POST')
                sortItemsReq.config.body = JSON.stringify({
                    data: updateTablePositions
                })
                sortItemsReq.send().catch(() => selectedTab.unsaved = true)

                new SchemaToDbml(tabs.selectedTab).convert()
                // const confirm = await useConfirm('Ai Generation Completed', 'Would you like to auto sort the entire project right now?')
                // if (confirm) {
                notify.success('Ai Generation Completed', 'You can continue your work')
                schema.importLoading = false
                menu.toggleImportSqlModal(false)
                // }
            }
        }
    }
}
