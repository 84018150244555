import { Command } from "@/commands/Command.js"
import { Note } from "@/modules/canvas/table/Note.js"
import { Table } from "@/modules/canvas/table/Table.js"

export class SortItemsCommand extends Command {
    _description = "Sort Items"
    oldPositions = []

    async run() {
        // update canvas height first
        const canvasParentEl = document.querySelector('.schema-editor')
        if (canvasParentEl) {
            const rect = canvasParentEl.getBoundingClientRect()
            this.tab.canvas.updateCanvasSize(rect.width, rect.height)
        }


        const gap = 40
        let items = this.tab.schema.schema_data.tables.concat(this.tab.schema.schema_data.notes)
        let currentLeft = 0
        let currentTop = 0
        let nextRowTop = 0
        for (let i = 0; i < items.length; i++) {
            let item = items[i].fields ? new Table(this.tab.canvas, items[i]) : new Note(this.tab.canvas, items[i])
            this.oldPositions.push([item.left, item.top])

            if (currentLeft + item.width >= this.tab.canvas.width) {
                currentTop = nextRowTop
                currentLeft = 0
            }

            item.left = item.data.left = currentLeft + gap
            item.top = item.data.top = currentTop + gap
            currentLeft = item.right
            if ((item.bottom) > nextRowTop) nextRowTop = item.bottom

            this.tab.canvas.updateOrigin(0, 0)
        }
        super.run()
    }


    async undo() {
        let items = this.tab.schema.schema_data.tables.concat(this.tab.schema.schema_data.notes)
        for (let i = 0; i < items.length; i++) {
            let item = items[i]
            item.left = this.oldPositions[i][0]
            item.top = this.oldPositions[i][1]
        }

        super.undo()

        const schema = ((await import('../stores/schema.store')).useSchemaStore)()
        schema.saveSortedItems()

    }
}
