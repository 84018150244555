<script setup>
import UModal from "nvd-u/components/UModal.vue";
import UButton from "nvd-u/components/UButton.vue";
import { useAuthStore } from "../../stores/auth.store";
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useAppStore } from "@/stores/app.store";

const router = useRouter()
const auth = useAuthStore()
const app = useAppStore()

const tableLimitReached = computed(() => {
    return auth.planLimitReached.includes('visit your account dashboard to update your billing/account settings')
})

const projectLimitReached = computed(() => {
    return auth.planLimitReached.includes('visit your account dashboard to delete any older schemas')
})

const linkTitle = computed(() => {
    return 'Go to Dashboard'
})

const getActionLink = computed(() => {
    return '/dashboard'
})

const upgrade = () => {
    app.closeAllModals()
    router.push('/account/billing')
}
</script>

<template>
    <UModal v-model="auth.planLimitReached" title="Plan Limit Reached" dont-close-on-ok dont-close-on-cancel>
        <template #header>
            <h2 class="text-danger text-center" style="margin: 0">Plan Limit Reached</h2>
        </template>
        <div class="text-center">
            {{ auth.planLimitReached }}
        </div>
        <template #footer>
            <div class="d-flex flex-grow-1 flex-column align-items-center">
                <UButton @click="upgrade">Upgrade Now</UButton>
                <div class="text-with-line">OR</div>
                <RouterLink @click="app.closeAllModals" :to="getActionLink">{{ linkTitle }}
                </RouterLink>
            </div>
        </template>
    </UModal>
</template>
