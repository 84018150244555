<script setup>
import UButton from 'nvd-u/components/UButton.vue'
import { useRouter } from "vue-router";

const router = useRouter()
</script>
<template>
    <UButton class="font-weight-bold" style="background-color: var(--bg);color: var(--primary)" compact
             @click="router.push('/account/billing')">
        Free - Upgrade Now
    </UButton>
</template>
<style lang="scss" scoped></style>
