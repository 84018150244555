import { Command } from "@/commands/Command.js"

export class MoveObjectCommand extends Command {
    _description = "Move Object"

    label() {
        return this.payload.obj.hasOwnProperty('content') ? 'Move note' : 'Move table'
    }

    async saveData() {
        const schema = ((await import('../stores/schema.store')).useSchemaStore)()
        const { obj } = this.payload
        let data = {
            resource: obj.resource,
            top: obj.top,
            left: obj.left,
            p_id: obj.p_id || 'new'
        }
        if (obj.resource === 'table')
            await schema.saveTable({
                data,
                saveSilently: true
            }, obj)
        else if (obj.resource === 'note')
            await schema.saveSchema({
                data,
                saveSilently: true
            })
    }

    async run() {
        const { obj, left, top } = this.payload
        obj.left += left
        obj.top += top


        // call schema save request here
        this.saveData()
        super.run()
    }

    undo() {
        const { obj, left, top } = this.payload
        obj.left -= left
        obj.top -= top
        this.saveData()
        super.undo()
    }
}
