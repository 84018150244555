import {createI18n} from 'vue-i18n'
import {locales} from "@/locales";
import {env} from "@/env"


const locale = (localStorage.getItem('currentLanguage') && env.localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : env.defaultLocale;
const i18n = createI18n({
    locale: locale,
    fallbackLocale: 'en',
    silentTranslationWarn: true,
    messages: locales,
    allowComposition: true
})

export default i18n
