import { cssVar } from "@/helpers/misc"
import { ApmCanvas } from "@/modules/canvas/ApmCanvas.js"
import { fontSize } from "@/modules/canvas/styles"
import { useSettingsStore } from "../../../stores/settings.store";

export class CanvasItem {
    /** @type ApmCanvas */
    canvas
    width = fontSize * 15
    height = 0
    borderRadius = fontSize
    shadow = { x: 3, y: 3, blur: fontSize, color: '#33333340' }
    data

    constructor(canvas, data) {
        this.canvas = canvas
        this.data = data
        this.color = data.color
        this.left = data.left
        this.top = data.top
    }

    get ctx() {
        return this.canvas.ctx
    }

    get right() {
        return this.left + this.width
    }

    get bottom() {
        return this.top + this.height
    }

    get cx() {
        return this.left + this.width / 2
    }

    get cy() {
        return this.top + this.height / 2
    }

    get relCx() {
        return this.relLeft + this.relWidth / 2
    }

    get relCy() {
        return this.relTop + this.relHeight / 2
    }

    get relLeft() {
        return this.canvas.origin.x + (this.left * this.canvas.zoom.scale)
    }

    get relTop() {
        return this.canvas.origin.y + (this.top * this.canvas.zoom.scale)
    }

    get relRight() {
        return this.canvas.origin.x + (this.right * this.canvas.zoom.scale)
    }

    get relBottom() {
        return this.canvas.origin.y + (this.bottom * this.canvas.zoom.scale)
    }

    get relWidth() {
        return this.width * this.canvas.zoom.scale
    }

    get relHeight() {
        return this.height * this.canvas.zoom.scale
    }

    isOutOfScreen() {
        return !this.canvas.isPointVisible(this.left, this.top)
            && !this.canvas.isPointVisible(this.right, this.bottom)
            && !this.canvas.isPointVisible(this.right, this.top)
            && !this.canvas.isPointVisible(this.left, this.bottom)
            && !this.canvas.isPointVisible(this.left + this.width * 0.5, this.top + this.height * 0.5) // checking for midpoint i.e, horizontal and vertical center
            // dividing entire table into 4 quarters and checking if any of it is visible
            && !this.canvas.isPointVisible(this.left, this.top + this.height * 0.25)
            && !this.canvas.isPointVisible(this.left, this.top + this.height * 0.75)
            && !this.canvas.isPointVisible(this.right, this.top + this.height * 0.25)
            && !this.canvas.isPointVisible(this.right, this.top + this.height * 0.75)

    }

    update() {
        const settings = useSettingsStore()
        if (!this.canvas.draggedObject) {
            if (this.hasMouseOver) {
                this.canvas.setHoveredObject(this.data)
            } else if (this.canvas.hoveredObject === this?.data) {
                this.canvas.setHoveredObject(null)
            }
        }

        let selectedItems = this.canvas?.selectedItems?.items || []

        // check for drag lock before dragging
        if (!settings.settings.lockDragging && (this.canvas.draggedObject === this.data || (selectedItems.includes(this.data) && this.canvas.mouse.pressed))) {
            this.left += this.canvas.mouse.dx / this.canvas.zoom.scale
            this.top += this.canvas.mouse.dy / this.canvas.zoom.scale
        }
    }

    drawSelectedState() {
        const isSelected = this.canvas.selectedItems.items.includes(this.data)
        const isHighlighted = this.canvas.highlightedItem === this.data
        if (isSelected || isHighlighted) {
            const ctx = this.ctx
            ctx.beginPath()
            ctx.strokeStyle = isHighlighted ? cssVar('--warning') : cssVar('--primary')
            ctx.lineWidth = isHighlighted ? 3 : 2
            ctx.strokeRect(this.left, this.top, this.width, this.height)
            ctx.stroke()
            ctx.closePath()
        }
    }

    drawLogicalState() {
        if (this.canvas.selectedLogicalTable?.data !== this.data) return
        const ctx = this.ctx
        ctx.save()
        ctx.beginPath()
        ctx.strokeStyle = cssVar('--warning')
        ctx.lineWidth = 3
        ctx.setLineDash([6]);
        ctx.strokeRect(this.left, this.top, this.width, this.height)
        ctx.stroke()
        ctx.closePath()
        ctx.restore()
    }

    get isHoveredObject() {
        return this.canvas.hoveredObject === this.data
    }

    get hasMouseOver() {
        const mouse = this.canvas.mouse
        let mouseX = mouse.x - this.canvas.origin.x
        let mouseY = mouse.y - this.canvas.origin.y

        let itemL = this.left
        let itemT = this.top
        let itemB = this.bottom
        let itemR = this.right

        const scale = this.canvas.zoom.scale
        if (scale !== 1) {
            itemL *= scale
            itemT *= scale
            itemB *= scale
            itemR *= scale
        }

        return itemL < mouseX && mouseX < itemR
            && itemT < mouseY && mouseY < itemB
    }
}
