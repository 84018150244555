import { colorHelper } from "@/helpers/color-helper"
import { cssVar } from "@/helpers/misc"
import { canvasHelper } from "@/modules/canvas/canvas-helper.js"
import { canvasFont, colorDark, colorLight, fontSize } from "@/modules/canvas/styles"
import { TableChild } from "@/modules/canvas/table/TableChild.js"

export class TableComments extends TableChild {
    comment = ''
    paddingY = 0.75 * fontSize
    lines = []


    constructor(table, comment) {
        super(table)
        this.comment = comment

        this.update()
    }

    height() {
        const lines = this.table.isHoveredObject ? this.lines.length : 1
        return this.paddingY * 2 + fontSize * lines
    }

    update() {
        this.lines = canvasHelper.getLines(this.ctx, this.comment, this.table.width - this.paddingX * 2)
        this.top = this.table.top + this.table.height - this.height()
    }

    draw() {
        this.update()
        this.ctx.font = `italic ${canvasFont}`
        this.ctx.fillStyle = cssVar('--gray')

        if (!this.table.isHoveredObject) {
            this.ctx.fillText(this.lines[0].slice(0, -2) + '...', this.table.left + this.paddingX, this.top + this.paddingY)
            return
        }

        this.lines.forEach((line, idx) => {
            this.ctx.fillText(line, this.table.left + this.paddingX, this.top + fontSize * idx + this.paddingY)
        })
    }
}
