import { Command } from "./Command";
import { DeleteResourceCommand } from "./DeleteResourceCommand";

export class DeleteMultipleItemsCommand extends Command {
    _description = 'Delete Multiple Items'
    oldItems = []

    run() {
        const { items } = this.payload

        this.oldItems = items.map(item => {
            item.oldIndex = item.resource === 'table' ? this.tab.schema.schema_data.tables.indexOf(item) : this.tab.schema.schema_data.notes.indexOf(item)
            return item
        })

        // deleting from local
        items.forEach(item => {
            if (item.resource === 'table')
                this.tab.schema.schema_data.tables.splice(this.tab.schema.schema_data.tables.indexOf(item), 1)
            else if (item.resource === 'note')
                this.tab.schema.schema_data.notes.splice(this.tab.schema.schema_data.notes.indexOf(item), 1)
        })

        // remove from remote
        let command = new DeleteResourceCommand(this.tab.schema.slug, {
            data: items.map(i => {
                return {
                    p_id: i.p_id,
                    resource: i.resource
                }
            })
        })
        command.run()

        // updating markup
        this.updateDbml()

        this.tab.canvas.selectedItems.items = []

        super.run()

    }

    async undo() {
        let tables = []
        let notes = []

        // restore locally
        this.oldItems.forEach(item => {
            if (item.resource === 'table') {
                item.fields.forEach(field => delete field['p_id'])
                tables.push(item)
                this.tab.schema.schema_data.tables.splice(item.oldIndex, 0, item)
            } else if (item.resource === 'note') {
                notes.push(item)
                this.tab.schema.schema_data.notes.splice(item.oldIndex, 0, item)
            }
            delete item['oldIndex']
            delete item['p_id']
        })

        this.updateDbml()

        // recreate on the server

        let payload = {
            notes,
            tables
        }

        const schema = ((await import('../stores/schema.store')).useSchemaStore)()
        const res = await schema.savePastedItems(payload)
        if (res) {
            if (res.tables) {
                res.tables.forEach(table => {
                    let localTable = tables.find(t => t.name === table.name)
                    if (localTable)
                        for (const [key, value] of Object.entries(table)) {
                            localTable[key] = value
                        }
                })
            }
            if (res.notes) {
                res.notes.forEach(note => {
                    for (const [key, value] of Object.entries(note)) {
                        let localNote = notes.find(n => n.content === note.content && !n.p_id)
                        if (localNote)
                            localNote[key] = value
                    }
                })
            }
        }

        super.undo()
    }
}
