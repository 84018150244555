<script setup lang="ts">
import {useAuthStore} from 'src/stores/auth.store'
import {useMenuStore} from 'src/stores/menu.store'
import {inject, onBeforeMount, onMounted} from 'vue'
import {useRouter} from 'vue-router'
import {parseJwt} from "../helpers/misc";
import {useSchemaStore} from "../stores/schema.store";

const router = useRouter()
const auth = useAuthStore()
const menu = useMenuStore()
const schema = useSchemaStore()

async function checkToken() {
    let params = new URLSearchParams(window.location.search)
    let t = params.get('t')
    if (t) {
        let user = parseJwt(t)
        if (user) {
            user.oauth = t
            auth.afterLogin({user})
            let admin = params.get('admin')
            if (admin) {
                window.location.href = '/dashboard'
                return
            }

            // checking if there's a schema id in the link
            let schema = window.location.href.split('/schema/')[1]?.split('?t')[0]
            if (schema) {
                schema.loadSchema(schema)
            } else {
                window.location.href = '/'
            }
            return true
        }
    }
    return false
}

onBeforeMount(async () => {
    await checkToken()
    menu.clearLocalStorage()
    if (auth.isLoggedIn) router.replace('/dashboard')
    else router.replace('/login')
})

</script>

<template></template>
