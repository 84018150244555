import {defineStore} from "pinia";
import {KeyboardShortcut} from "../classes/KeyboardShortcut";
import i18n from "@/locales/translations";
import {isMacOs} from "nvd-js-helpers/osHelper";
import {useTabsStore} from "./tabs.store";
import {useMenuStore} from "./menu.store";
import {useSettingsStore} from "./settings.store";
import {useVersionsStore} from "./versions.store";

export const useKbShortcutsStore = defineStore('kb-shortcuts', {
    state: () => ({
        shortcuts: {},
    }),
    actions: {
        new() {
            const menu = useMenuStore()
            menu.newSchema()
        },
        async save() {
            const menu = useMenuStore()
            await menu.save()
        },
        load() {
            const menu = useMenuStore()
            menu.loadSchema()
        },
        import() {
            const menu = useMenuStore()
            menu.importSchema()
        },
        undo() {
            const menu = useMenuStore()
            menu.undo()
        },
        redo() {
            const menu = useMenuStore()
            menu.redo()
        },
        table() {
            const menu = useMenuStore()
            menu.table()
        },
        note() {
            const menu = useMenuStore()
            menu.note()
        },
        async exportSql() {
            const menu = useMenuStore()
            await menu.toggleExportSql()
        },
        showKeyboardShortcuts() {
            const menu = useMenuStore()
            menu.showKeyboardShortcuts()
        },
        toggleMinimap() {
            const menu = useMenuStore()
            menu.toggleMinimap()
        },
        toggleSidebar() {
            const menu = useMenuStore()
            menu.toggleSidebar()
        },
        toggleGrid() {
            const menu = useMenuStore()
            menu.toggleGrid()
        },
        toggleMarkup() {
            const menu = useMenuStore()
            menu.toggleMarkup()
        },
        lineType() {
            const menu = useMenuStore()
            menu.lineType()
        },
        showTutorial() {
            const menu = useMenuStore()
            menu.showTutorial()
        },
        showHistory() {
            const menu = useMenuStore()
            menu.showHistory()
        },
        properties() {
            const menu = useMenuStore()
            menu.properties()
        },
        selectAll() {
            const menu = useMenuStore()
            menu.selectAll()
        },
        deleteSelection() {
            const tab = useTabsStore()
            if (tab.selectedTab?.canvas?.selectedItems?.items?.length) {
                tab.deleteSelectedItems()
            }
        },
        clearSelection() {
            const tab = useTabsStore()
            const menu = useMenuStore()
            tab.clearSelectedItems()

            // exit fullscreen mode
            menu.presentation_view = false
        },
        async togglePresentationMode() {
            // restricting it to only exiting presentation mode
            if (document.exitFullscreen) {
                await document.exitFullscreen();
            } else if (document.webkitExitFullscreen) {
                await document.webkitExitFullscreen();
            } else if (document.mozCancelFullScreen) {
                await document.mozCancelFullScreen();
            } else if (document.msExitFullscreen) {
                await document.msExitFullscreen();
            }
        },
        initialize() {
            this.shortcuts = {}
            this.add(new KeyboardShortcut(i18n.global.t('dialog.new_schema'), 'N', false, {alt: true}).handler(this.new))
            this.add(new KeyboardShortcut(i18n.global.t('dialog.save_schema'), 'S', true).handler(this.save))
            this.add(new KeyboardShortcut(i18n.global.t('dialog.load_schema'), 'L', true).handler(this.load))
            this.add(new KeyboardShortcut(i18n.global.t('dialog.import_schema'), 'I', true).handler(this.import))
            this.add(new KeyboardShortcut(i18n.global.t('menu.undo'), 'Z', true).handler(this.undo))
            this.add(new KeyboardShortcut(i18n.global.t('menu.redo'), 'Y', true).handler(this.redo))
            this.add(new KeyboardShortcut(i18n.global.t('menu.redo'), 'Z', true, {shift: true}).handler(this.redo))
            this.add(new KeyboardShortcut(i18n.global.t('dialog.insert_new_table'), 'T', true, {alt: true}).handler(this.table))
            this.add(new KeyboardShortcut(i18n.global.t('dialog.insert_new_note'), 'N', true, {alt: true}).handler(this.note))
            this.add(new KeyboardShortcut(i18n.global.t('dialog.export_sql'), 'E', true).handler(this.exportSql))
            this.add(new KeyboardShortcut(i18n.global.t('dialog.show_this_dialog'), 'K', true).handler(this.showKeyboardShortcuts))
            this.add(new KeyboardShortcut(i18n.global.t('menu.toggle_minimap'), 'M', false, {alt: true}).handler(this.toggleMinimap))
            this.add(new KeyboardShortcut(i18n.global.t('menu.toggle_grid'), 'G', false, {alt: true}).handler(this.toggleGrid))
            this.add(new KeyboardShortcut(i18n.global.t('menu.toggle_sidebar'), 'B', false, {alt: true}).handler(this.toggleSidebar))
            this.add(new KeyboardShortcut(i18n.global.t('menu.toggle_presentation_mode'), 'P', false, {
                alt: true,
                shift: true
            }).handler(this.togglePresentationMode))
            this.add(new KeyboardShortcut(i18n.global.t('menu.linetype'), 'L', false, {alt: true}).handler(this.lineType))
            this.add(new KeyboardShortcut(i18n.global.t('menu.show_tutorial'), 'U', false, {alt: true}).handler(this.showTutorial))
            this.add(new KeyboardShortcut(i18n.global.t('menu.history'), 'H', false, {alt: true}).handler(this.showHistory))
            this.add(new KeyboardShortcut(i18n.global.t('menu.properties'), 'P', false, {alt: true}).handler(this.properties))
            this.add(new KeyboardShortcut(i18n.global.t('menu.select_all'), 'A', true).handler(this.selectAll))
            this.add(new KeyboardShortcut(i18n.global.t('menu.delete_selected'), 'delete', false).handler(this.deleteSelection))
            this.add(new KeyboardShortcut(i18n.global.t('menu.delete_selected'), 'backspace', false).handler(this.deleteSelection))
            this.add(new KeyboardShortcut(i18n.global.t('menu.clear_selection'), 'escape', false).handler(this.clearSelection))
        },
        eventListener(e: KeyboardEvent) {
            const settings = useSettingsStore()
            const versions = useVersionsStore()

            // @ts-ignore
            if (!e.target.querySelector('.schema-editor canvas')) return true
            if (!settings.settings.enableShortcuts) return true
            if (versions.isPreviewing) return true

            let keysPressed = new KeyboardShortcut(
                '', e.key,
                isMacOs() ? e.metaKey : e.ctrlKey,
                {
                    shift: e.shiftKey,
                    alt: e.altKey
                }).keys

            let item = this.shortcuts[keysPressed]
            if (!item) return
            e.preventDefault()
            item.execute(e)
        },
        add(shortcut: KeyboardShortcut) {
            this.shortcuts[shortcut.keys] = shortcut
        },
    }
})