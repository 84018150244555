<script setup>
import MainHeader from "./components/layout/header/MainHeader.vue";
import { useRoute } from "vue-router";
import { computed, onBeforeMount } from "vue";
import Sidebar from "./components/layout/sidebar/Sidebar.vue";
import { useMenuStore } from "./stores/menu.store";
import { useTheme } from "@/composables/useTheme";
import SettingsModal from "./views/modals/SettingsModal.vue";
import { useAuthStore } from "@/stores/auth.store";
import { useBillingStore } from "@/stores/billing.store";
import PlanLimitReachedModal from "@/views/modals/PlanLimitReachedModal.vue";
import NewSchemaModal from "@/views/modals/NewSchemaModal.vue";

const route = useRoute()
const menu = useMenuStore()
const auth = useAuthStore()
const billing = useBillingStore()
const theme = useTheme()
const showHeader = computed(() => {
    if (menu.embedded) return false
    return !['login', 'register', 'app', 'forgot-password', 'edit-password', 'invite', 'non-profit-register'].includes(route.name)
})

onBeforeMount(() => {
    if (auth.isLoggedIn) {
        auth.reFetchUser()
    }
})

</script>

<template>
    <div id="app">
        <div class="main-container d-flex flex-column">
            <MainHeader v-if="showHeader"/>
            <div class="d-flex flex-grow-1 content-container">
                <Sidebar class="mr-4 dashboard-sidebar"
                         v-if="route.name && !['login','register','home','app','team','forgot-password','edit-password','invite','non-profit-register'].includes(route.name)"/>
                <main :class="route.name" class="flex-grow-1 main-content d-flex flex-column">
                    <router-view></router-view>
                </main>
            </div>
        </div>
        <SettingsModal/>
        <PlanLimitReachedModal/>
        <NewSchemaModal/>
    </div>
</template>


<style lang="scss">
.main-container {
    height: 100vh;
    width: 100vw;
    overflow: hidden;

    .content-container {
        overflow-y: auto;
        overflow-x: hidden;
    }

    .main-content {
        max-width: 1000px;

        &.invoices-history {
            max-width: 100%;
            min-width: 800px;
            overflow-x: auto;
        }

        &.app {
            max-width: 100%;
            overflow-y: hidden;
        }

        &.subscription {
            max-width: 100%;
        }

        &.team {
            max-width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .dashboard-sidebar {
        display: none;
    }
}
</style>
