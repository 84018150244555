import {isMacOs} from 'nvd-js-helpers/osHelper'

export class KeyboardShortcut {
    label = ''
    key = ''
    ctrl = false
    alt = false
    shift = false
    _handler: Function
    macAltKeyMappings = {
        'ß': 's'
    }

    constructor(label: string, key: string, ctrl = false, props: Partial<KeyboardShortcut> = {}) {
        for (const key in props) {
            this[key] = props[key]
        }
        this.label = label
        this.key = key
        this.ctrl = ctrl
    }

    get keys() {
        let key = this.key
        if (this.alt && isMacOs()) key = this.macAltKeyMappings[key] || this.key
        return ((this.alt ? 'alt' : '') + (this.ctrl ? 'ctrl' : '') + (this.shift ? 'shift' : '') + key).toLowerCase()
    }

    get ctrlLabel() {
        return isMacOs() ? '⌘' : 'Ctrl'
    }

    get altLabel() {
        return isMacOs() ? '⌥' : 'Alt'
    }

    get formattedKeys() {
        let str = ""
        if (this.ctrl) str = str.concat(`${this.ctrlLabel} + `)
        if (this.alt) str = str.concat(`${this.altLabel} + `)
        if (this.shift) str = str.concat(`Shift + `)
        str = str.concat(` ${this.key}`)
        return str
    }

    handler(fn: Function) {
        this._handler = fn
        return this
    }

    execute(e) {
        this._handler(e)
    }
}
