import { defineStore } from "pinia";
import { useTabsStore } from "./tabs.store";
import { _deepClone, _sleep } from "nvd-js-helpers/misc";
import { FetchRequest } from "../helpers/fetch-request";
import { useNotify } from "nvd-u/composables/Notifiy";
import { dayjs } from "nvd-js-helpers/dayjs";
import { useSettingsStore } from "./settings.store";
import { SchemaToDbml } from "../components/code-editor/schemaToDbml";

export const useVersionsStore = defineStore('versions', {
    state: () => ({
        versions: new FetchRequest('', 'POST'),
        selectedVersion: null,
        originalSchema: null,
        expanded: {},
        isPreviewing: false,
        previewReq: new FetchRequest('', 'GET'),
        restoreReq: new FetchRequest('', 'POST')
    }),
    getters: {},
    actions: {
        load() {
            const tab = useTabsStore()
            if (!tab.selectedTab.schema.id) return
            this.versions.url = `schemas/${tab.selectedTab.schema.slug}/versions`
            this.versions.send()
        },
        async reset(tabId = null) {
            const tab = useTabsStore()
            let selectedTab = tab.selectedTab
            if (tabId) {
                let oldTab = tab.tabs.find(t => t.id === tabId)
                if (oldTab) selectedTab = oldTab
            }
            if (this.originalSchema) {
                selectedTab.schema.schema_data = this.originalSchema
                this.originalSchema = null
            }
            this.selectedVersion = null
            this.expanded = {}
            await _sleep(600)
            this.isPreviewing = false
        },
        preview(version) {
            const tab = useTabsStore()
            this.previewReq.url = `schemas/${tab.selectedTab.schema.slug}/show_version?v_id=${version.id}`
            if (!this.selectedVersion) {
                this.originalSchema = tab.selectedTab.schema.schema_data
            }
            this.selectedVersion = version

            // prevent unnecessary http request
            if (version.schema_data) {
                this.setPreview(version)
                return
            }

            this.previewReq.send().then(this.setPreview)
        },
        async setPreview({ schema_data }) {
            const tab = useTabsStore()
            this.selectedVersion.schema_data = typeof schema_data === 'string' ? JSON.parse(schema_data) : schema_data
            this.isPreviewing = true
            tab.selectedTab.schema.schema_data = typeof schema_data === 'string' ? JSON.parse(schema_data) : schema_data
            new SchemaToDbml(tab.selectedTab).convert()
        },
        closePreview() {
            this.selectedVersion = null
            this.originalSchema = null
            this.isPreviewing = false
        },
        restoreToVersion() {
            const tab = useTabsStore()
            const notify = useNotify()
            const settings = useSettingsStore()
            let version = { ...this.selectedVersion }
            this.restoreReq.url = `schemas/${tab.selectedTab.schema.slug}/restore_version`
            this.restoreReq.config.body = JSON.stringify({
                v_id: version.id
            })
            this.closePreview()
            settings.modals.history = false

            this.restoreReq.send()
                .then((data) => {
                    notify.success(`Restored from ${dayjs.unix(version.created_at).format('MMM D, YYYY hh:mm a')}`)
                    // update local schema here if needed
                    let selectedTab = tab.tabs.find(t => t.id === data.id)
                    if (!selectedTab) return
                    selectedTab.schema.schema_data = _deepClone(data.schema_data)
                    selectedTab.schema.tables_count = data.tables_count
                    selectedTab.schema.fields_count = data.fields_count
                })
        }
    }
})
