export const tempEmailProviders = [
    "0-mail.com",
    "027168.com",
    "0815.ru",
    "0815.ry",
    "0815.su",
    "0845.ru",
    "0box.eu",
    "0clickemail.com",
    "0n0ff.net",
    "0nelce.com",
    "0v.ro",
    "0w.ro",
    "0wnd.net",
    "0wnd.org",
    "0x207.info",
    "1-8.biz",
    "1-tm.com",
    "10-minute-mail.com",
    "1000rebates.stream",
    "100likers.com",
    "105kg.ru",
    "10dk.email",
    "10mail.com",
    "10mail.org",
    "10minut.com.pl",
    "10minut.xyz",
    "10minutemail.be",
    "10minutemail.cf",
    "10minutemail.co.uk",
    "10minutemail.co.za",
    "10minutemail.com",
    "10minutemail.de",
    "10minutemail.ga",
    "10minutemail.gq",
    "10minutemail.ml",
    "10minutemail.net",
    "10minutemail.nl",
    "10minutemail.pro",
    "10minutemail.us",
    "10minutemailbox.com",
    "10minutemails.in",
    "10minutenemail.de",
    "10minutesmail.com",
    "10minutesmail.fr",
    "10minutmail.pl",
    "10x9.com",
    "11163.com",
    "123-m.com",
    "12hosting.net",
    "12houremail.com",
    "12minutemail.com",
    "12minutemail.net",
    "12storage.com",
    "140unichars.com",
    "147.cl",
    "14n.co.uk",
    "15qm.com",
    "1blackmoon.com",
    "1ce.us",
    "1chuan.com",
    "1clck2.com",
    "1fsdfdsfsdf.tk",
    "1mail.ml",
    "1pad.de",
    "1s.fr",
    "1secmail.com",
    "1secmail.net",
    "1secmail.org",
    "1st-forms.com",
    "1to1mail.org",
    "1usemail.com",
    "1webmail.info",
    "1zhuan.com",
    "2012-2016.ru",
    "20email.eu",
    "20email.it",
    "20mail.eu",
    "20mail.in",
    "20mail.it",
    "20minutemail.com",
    "20minutemail.it",
    "20mm.eu",
    "2120001.net",
    "21cn.com",
    "247web.net",
    "24hinbox.com",
    "24hourmail.com",
    "24hourmail.net",
    "2anom.com",
    "2chmail.net",
    "2ether.net",
    "2fdgdfgdfgdf.tk",
    "2odem.com",
    "2prong.com",
    "2wc.info",
    "300book.info",
    "30mail.ir",
    "30minutemail.com",
    "30wave.com",
    "3202.com",
    "33mail.com",
    "36ru.com",
    "3d-painting.com",
    "3l6.com",
    "3mail.ga",
    "3trtretgfrfe.tk",
    "4-n.us",
    "4057.com",
    "418.dk",
    "42o.org",
    "4gfdsgfdgfd.tk",
    "4k5.net",
    "4mail.cf",
    "4mail.ga",
    "4nextmail.com",
    "4nmv.ru",
    "4tb.host",
    "4warding.com",
    "4warding.net",
    "4warding.org",
    "50set.ru",
    "55hosting.net",
    "5ghgfhfghfgh.tk",
    "5gramos.com",
    "5july.org",
    "5mail.cf",
    "5mail.ga",
    "5minutemail.net",
    "5oz.ru",
    "5tb.in",
    "5x25.com",
    "5ymail.com",
    "60minutemail.com",
    "672643.net",
    "675hosting.com",
    "675hosting.net",
    "675hosting.org",
    "6hjgjhgkilkj.tk",
    "6ip.us",
    "6mail.cf",
    "6mail.ga",
    "6mail.ml",
    "6paq.com",
    "6somok.ru",
    "6url.com",
    "75hosting.com",
    "75hosting.net",
    "75hosting.org",
    "7days-printing.com",
    "7mail.ga",
    "7mail.ml",
    "7tags.com",
    "80665.com",
    "8127ep.com",
    "8mail.cf",
    "8mail.ga",
    "8mail.ml",
    "99.com",
    "99cows.com",
    "99experts.com",
    "9mail.cf",
    "9me.site",
    "9mot.ru",
    "9ox.net",
    "9q.ro",
    "a-bc.net",
    "a45.in",
    "a7996.com",
    "aa5zy64.com",
    "abacuswe.us",
    "abakiss.com",
    "abcmail.email",
    "abilitywe.us",
    "abovewe.us",
    "absolutewe.us",
    "abundantwe.us",
    "abusemail.de",
    "abuser.eu",
    "abyssmail.com",
    "ac20mail.in",
    "academiccommunity.com",
    "academywe.us",
    "acceleratewe.us",
    "accentwe.us",
    "acceptwe.us",
    "acclaimwe.us",
    "accordwe.us",
    "accreditedwe.us",
    "acentri.com",
    "achievementwe.us",
    "achievewe.us",
    "acornwe.us",
    "acrossgracealley.com",
    "acrylicwe.us",
    "activatewe.us",
    "activitywe.us",
    "acucre.com",
    "acuitywe.us",
    "acumenwe.us",
    "adaptivewe.us",
    "adaptwe.us",
    "add3000.pp.ua",
    "addictingtrailers.com",
    "adeptwe.us",
    "adfskj.com",
    "adios.email",
    "adiq.eu",
    "aditus.info",
    "admiralwe.us",
    "ado888.biz",
    "adobeccepdm.com",
    "adoniswe.us",
    "adpugh.org",
    "adroh.com",
    "adsd.org",
    "adubiz.info",
    "advantagewe.us",
    "advantimo.com",
    "adventurewe.us",
    "adventwe.us",
    "advisorwe.us",
    "advocatewe.us",
    "adwaterandstir.com",
    "aegde.com",
    "aegia.net",
    "aegiscorp.net",
    "aegiswe.us",
    "aelo.es",
    "aeonpsi.com",
    "afarek.com",
    "affiliate-nebenjob.info",
    "affiliatedwe.us",
    "affilikingz.de",
    "affinitywe.us",
    "affluentwe.us",
    "affordablewe.us",
    "afrobacon.com",
    "afterhourswe.us",
    "agedmail.com",
    "agendawe.us",
    "agger.ro",
    "agilewe.us",
    "agorawe.us",
    "agtx.net",
    "aheadwe.us",
    "ahem.email",
    "ahk.jp",
    "ahmedkhlef.com",
    "aiafhg.com",
    "air2token.com",
    "airmailbox.website",
    "airsi.de",
    "ajaxapp.net",
    "akapost.com",
    "akerd.com",
    "akgq701.com",
    "al-qaeda.us",
    "albionwe.us",
    "alchemywe.us",
    "aleeas.com",
    "alfaceti.com",
    "aliaswe.us",
    "alienware13.com",
    "aligamel.com",
    "alisongamel.com",
    "alivance.com",
    "alivewe.us",
    "all-cats.ru",
    "allaccesswe.us",
    "allamericanwe.us",
    "allaroundwe.us",
    "alldirectbuy.com",
    "allegiancewe.us",
    "allegrowe.us",
    "allemojikeyboard.com",
    "allgoodwe.us",
    "alliancewe.us",
    "allinonewe.us",
    "allofthem.net",
    "alloutwe.us",
    "allowed.org",
    "alloywe.us",
    "allprowe.us",
    "allseasonswe.us",
    "allstarwe.us",
    "allthegoodnamesaretaken.org",
    "allurewe.us",
    "almondwe.us",
    "alph.wtf",
    "alpha-web.net",
    "alphaomegawe.us",
    "alpinewe.us",
    "altairwe.us",
    "altitudewe.us",
    "altuswe.us",
    "ama-trade.de",
    "ama-trans.de",
    "amadeuswe.us",
    "amail.club",
    "amail.com",
    "amail1.com",
    "amail4.me",
    "amazon-aws.org",
    "amberwe.us",
    "ambiancewe.us",
    "ambitiouswe.us",
    "amelabs.com",
    "americanawe.us",
    "americasbestwe.us",
    "americaswe.us",
    "amicuswe.us",
    "amilegit.com",
    "amiri.net",
    "amiriindustries.com",
    "amplewe.us",
    "amplifiedwe.us",
    "amplifywe.us",
    "ampsylike.com",
    "analogwe.us",
    "analysiswe.us",
    "analyticalwe.us",
    "analyticswe.us",
    "analyticwe.us",
    "anappfor.com",
    "anappthat.com",
    "andreihusanu.ro",
    "andthen.us",
    "animesos.com",
    "anit.ro",
    "ano-mail.net",
    "anon-mail.de",
    "anonaddy.com",
    "anonbox.net",
    "anonmail.top",
    "anonmails.de",
    "anonymail.dk",
    "anonymbox.com",
    "anonymized.org",
    "anonymousness.com",
    "anotherdomaincyka.tk",
    "ansibleemail.com",
    "anthony-junkmail.com",
    "antireg.com",
    "antireg.ru",
    "antispam.de",
    "antispam24.de",
    "antispammail.de",
    "anyalias.com",
    "aoeuhtns.com",
    "apfelkorps.de",
    "aphlog.com",
    "apkmd.com",
    "appc.se",
    "appinventor.nl",
    "appixie.com",
    "apps.dj",
    "appzily.com",
    "arduino.hk",
    "ariaz.jetzt",
    "armyspy.com",
    "aron.us",
    "arroisijewellery.com",
    "art-en-ligne.pro",
    "artman-conception.com",
    "arur01.tk",
    "arurgitu.gq",
    "arvato-community.de",
    "aschenbrandt.net",
    "asdasd.nl",
    "asdasd.ru",
    "ashleyandrew.com",
    "ask-mail.com",
    "asorent.com",
    "ass.pp.ua",
    "astonut.tk",
    "astroempires.info",
    "asu.mx",
    "asu.su",
    "at.hm",
    "at0mik.org",
    "atnextmail.com",
    "attnetwork.com",
    "augmentationtechnology.com",
    "ausgefallen.info",
    "auti.st",
    "autorobotica.com",
    "autosouvenir39.ru",
    "autotwollow.com",
    "autowb.com",
    "aver.com",
    "averdov.com",
    "avia-tonic.fr",
    "avls.pt",
    "awatum.de",
    "awdrt.org",
    "awiki.org",
    "awsoo.com",
    "axiz.org",
    "axon7zte.com",
    "axsup.net",
    "ayakamail.cf",
    "azazazatashkent.tk",
    "azcomputerworks.com",
    "azmeil.tk",
    "azwev.site",
    "b1of96u.com",
    "b2bx.net",
    "b2cmail.de",
    "badgerland.eu",
    "badoop.com",
    "badpotato.tk",
    "balaket.com",
    "banit.club",
    "banit.me",
    "bank-opros1.ru",
    "bareed.ws",
    "barryogorman.com",
    "bartdevos.be",
    "basscode.org",
    "bauwerke-online.com",
    "bazaaboom.com",
    "bbbbyyzz.info",
    "bbhost.us",
    "bcaoo.com",
    "bcast.ws",
    "bcb.ro",
    "bccto.me",
    "bdmuzic.pw",
    "bearsarefuzzy.com",
    "beddly.com",
    "beefmilk.com",
    "belamail.org",
    "belljonestax.com",
    "beluckygame.com",
    "benipaula.org",
    "bepureme.com",
    "beribase.ru",
    "beribaza.ru",
    "berirabotay.ru",
    "best-john-boats.com",
    "bestchoiceusedcar.com",
    "bestlistbase.com",
    "bestoption25.club",
    "bestparadize.com",
    "bestsoundeffects.com",
    "besttempmail.com",
    "betr.co",
    "bgtmail.com",
    "bgx.ro",
    "bidourlnks.com",
    "big1.us",
    "bigprofessor.so",
    "bigstring.com",
    "bigwhoop.co.za",
    "bij.pl",
    "binka.me",
    "binkmail.com",
    "binnary.com",
    "bio-muesli.info",
    "bio-muesli.net",
    "bione.co",
    "bitwhites.top",
    "bitymails.us",
    "blackgoldagency.ru",
    "blackmarket.to",
    "bladesmail.net",
    "blip.ch",
    "blnkt.net",
    "block521.com",
    "blogmyway.org",
    "blogos.net",
    "blogspam.ro",
    "blondemorkin.com",
    "bluedumpling.info",
    "bluewerks.com",
    "bnote.com",
    "boatmail.us",
    "bobmail.info",
    "bobmurchison.com",
    "bofthew.com",
    "bonobo.email",
    "boofx.com",
    "bookthemmore.com",
    "bootybay.de",
    "borged.com",
    "borged.net",
    "borged.org",
    "bot.nu",
    "boun.cr",
    "bouncr.com",
    "boxformail.in",
    "boximail.com",
    "boxomail.live",
    "boxtemp.com.br",
    "bptfp.net",
    "brandallday.net",
    "brasx.org",
    "breakthru.com",
    "brefmail.com",
    "brennendesreich.de",
    "briggsmarcus.com",
    "broadbandninja.com",
    "bsnow.net",
    "bspamfree.org",
    "bspooky.com",
    "bst-72.com",
    "btb-notes.com",
    "btc.email",
    "btcmail.pw",
    "btcmod.com",
    "btizet.pl",
    "buccalmassage.ru",
    "budaya-tionghoa.com",
    "budayationghoa.com",
    "buffemail.com",
    "bugmenever.com",
    "bugmenot.com",
    "bulrushpress.com",
    "bum.net",
    "bumpymail.com",
    "bunchofidiots.com",
    "bund.us",
    "bundes-li.ga",
    "bunsenhoneydew.com",
    "burnermail.com",
    "burnthespam.info",
    "burstmail.info",
    "businessbackend.com",
    "businesssuccessislifesuccess.com",
    "buspad.org",
    "bussitussi.com",
    "buy-blog.com",
    "buymoreplays.com",
    "buyordie.info",
    "buyusdomain.com",
    "buyusedlibrarybooks.org",
    "buzzcluby.com",
    "byebyemail.com",
    "byespm.com",
    "bylup.com",
    "byom.de",
    "c51vsgq.com",
    "cachedot.net",
    "californiafitnessdeals.com",
    "caltiger.net.in ",
    "cam4you.cc",
    "camping-grill.info",
    "candymail.de",
    "cane.pw",
    "capitalistdilemma.com",
    "car101.pro",
    "carbtc.net",
    "cars2.club",
    "carsencyclopedia.com",
    "cartelera.org",
    "caseedu.tk",
    "cashflow35.com",
    "casualdx.com",
    "cavi.mx",
    "cbair.com",
    "cbes.net",
    "cc.liamria",
    "ccmail.uk",
    "cdfaq.com",
    "cdpa.cc",
    "ceed.se",
    "cek.pm",
    "cellurl.com",
    "centermail.com",
    "centermail.net",
    "cetpass.com",
    "cfo2go.ro",
    "chacuo.net",
    "chaichuang.com",
    "chalupaurybnicku.cz",
    "chammy.info",
    "chasefreedomactivate.com",
    "chatich.com",
    "cheaphub.net",
    "cheatmail.de",
    "chenbot.email",
    "chibakenma.ml",
    "chickenkiller.com",
    "chielo.com",
    "childsavetrust.org",
    "chilkat.com",
    "chinamkm.com",
    "chithinh.com",
    "chitthi.in",
    "choco.la",
    "chogmail.com",
    "choicemail1.com",
    "chong-mail.com",
    "chong-mail.net",
    "chong-mail.org",
    "chumpstakingdumps.com",
    "cigar-auctions.com",
    "civx.org",
    "ckaazaza.tk",
    "ckiso.com",
    "cl-cl.org",
    "cl0ne.net",
    "claimab.com",
    "clandest.in",
    "classesmail.com",
    "clearwatermail.info",
    "click-email.com",
    "clickdeal.co",
    "clipmail.eu",
    "clixser.com",
    "clonemoi.tk",
    "cloud-mail.top",
    "cloudns.cx",
    "clrmail.com",
    "cmail.club",
    "cmail.com",
    "cmail.net",
    "cmail.org",
    "cnamed.com",
    "cndps.com",
    "cnew.ir",
    "cnmsg.net",
    "cnsds.de",
    "co.cc",
    "cobarekyo1.ml",
    "cocoro.uk",
    "cocovpn.com",
    "codeandscotch.com",
    "codivide.com",
    "coffeetimer24.com",
    "coieo.com",
    "coin-host.net",
    "coinlink.club",
    "coldemail.info",
    "compareshippingrates.org",
    "completegolfswing.com",
    "comwest.de",
    "conf.work",
    "consumerriot.com",
    "contbay.com",
    "cooh-2.site",
    "coolandwacky.us",
    "coolimpool.org",
    "coreclip.com",
    "cosmorph.com",
    "courrieltemporaire.com",
    "coza.ro",
    "crankhole.com",
    "crapmail.org",
    "crastination.de",
    "crazespaces.pw",
    "crazymailing.com",
    "cream.pink",
    "crepeau12.com",
    "cross-law.ga",
    "cross-law.gq",
    "crossmailjet.com",
    "crossroadsmail.com",
    "crunchcompass.com",
    "crusthost.com",
    "cs.email",
    "csh.ro",
    "cszbl.com",
    "ctmailing.us",
    "ctos.ch",
    "cu.cc",
    "cubiclink.com",
    "cuendita.com",
    "cuirushi.org",
    "cuoly.com",
    "cupbest.com",
    "curlhph.tk",
    "curryworld.de",
    "cust.in",
    "cutout.club",
    "cutradition.com",
    "cuvox.de",
    "cyber-innovation.club",
    "cyber-phone.eu",
    "cylab.org",
    "d1yun.com",
    "d3p.dk",
    "daabox.com",
    "dab.ro",
    "dacoolest.com",
    "daemsteam.com",
    "daibond.info",
    "daily-email.com",
    "daintly.com",
    "damai.webcam",
    "dammexe.net",
    "damnthespam.com",
    "dandikmail.com",
    "darkharvestfilms.com",
    "daryxfox.net",
    "dasdasdascyka.tk",
    "dash-pads.com",
    "dataarca.com",
    "datarca.com",
    "datazo.ca",
    "datenschutz.ru",
    "datum2.com",
    "davidkoh.net",
    "davidlcreative.com",
    "dawin.com",
    "daymail.life",
    "daymailonline.com",
    "dayrep.com",
    "dbunker.com",
    "dcemail.com",
    "ddcrew.com",
    "de-a.org",
    "dea-21olympic.com",
    "deadaddress.com",
    "deadchildren.org",
    "deadfake.cf",
    "deadfake.ga",
    "deadfake.ml",
    "deadfake.tk",
    "deadspam.com",
    "deagot.com",
    "dealja.com",
    "dealrek.com",
    "deekayen.us",
    "defomail.com",
    "degradedfun.net",
    "deinbox.com",
    "delayload.com",
    "delayload.net",
    "delikkt.de",
    "delivrmail.com",
    "demen.ml",
    "dengekibunko.ga",
    "dengekibunko.gq",
    "dengekibunko.ml",
    "der-kombi.de",
    "derkombi.de",
    "derluxuswagen.de",
    "desoz.com",
    "despam.it",
    "despammed.com",
    "dev-null.cf",
    "dev-null.ga",
    "dev-null.gq",
    "dev-null.ml",
    "devnullmail.com",
    "deyom.com",
    "deypo.com",
    "dharmatel.net",
    "dhm.ro",
    "dhy.cc",
    "dialogus.com",
    "diapaulpainting.com",
    "dicopto.com",
    "digdig.org",
    "digital-message.com",
    "digitalesbusiness.info",
    "digitalmail.info",
    "digitalmariachis.com",
    "digitalsanctuary.com",
    "dildosfromspace.com",
    "dim-coin.com",
    "dingbone.com",
    "diolang.com",
    "directmail24.net",
    "disaq.com",
    "disbox.net",
    "disbox.org",
    "discard.cf",
    "discard.email",
    "discard.ga",
    "discard.gq",
    "discard.ml",
    "discard.tk",
    "discardmail.com",
    "discardmail.de",
    "discos4.com",
    "disign-concept.eu",
    "disign-revelation.com",
    "dispo.in",
    "dispomail.eu",
    "disposable-e.ml",
    "disposable-email.ml",
    "disposable.cf",
    "disposable.ga",
    "disposable.ml",
    "disposable.site",
    "disposableaddress.com",
    "disposableemailaddresses.com",
    "disposableinbox.com",
    "disposablemails.com",
    "dispose.it",
    "disposeamail.com",
    "disposemail.com",
    "disposemymail.com",
    "dispostable.com",
    "divad.ga",
    "divermail.com",
    "divismail.ru",
    "diwaq.com",
    "dlemail.ru",
    "dmarc.ro",
    "dndent.com",
    "dnses.ro",
    "doanart.com",
    "dob.jp",
    "dodgeit.com",
    "dodgemail.de",
    "dodgit.com",
    "dodgit.org",
    "dodsi.com",
    "doiea.com",
    "dolphinnet.net",
    "domforfb1.tk",
    "domforfb18.tk",
    "domforfb19.tk",
    "domforfb2.tk",
    "domforfb23.tk",
    "domforfb27.tk",
    "domforfb29.tk",
    "domforfb3.tk",
    "domforfb4.tk",
    "domforfb5.tk",
    "domforfb6.tk",
    "domforfb7.tk",
    "domforfb8.tk",
    "domforfb9.tk",
    "domozmail.com",
    "donemail.ru",
    "dongqing365.com",
    "dontreg.com",
    "dontsendmespam.de",
    "doquier.tk",
    "dotman.de",
    "dotmsg.com",
    "dotslashrage.com",
    "doublemail.de",
    "douchelounge.com",
    "dozvon-spb.ru",
    "dp76.com",
    "dr69.site",
    "drdrb.com",
    "drdrb.net",
    "dred.ru",
    "drevo.si",
    "drivetagdev.com",
    "droolingfanboy.de",
    "dropcake.de",
    "dropjar.com",
    "droplar.com",
    "dropmail.me",
    "dsgvo.ru",
    "dsiay.com",
    "dspwebservices.com",
    "duam.net",
    "duck2.club",
    "dudmail.com",
    "duk33.com",
    "dukedish.com",
    "dump-email.info",
    "dumpandjunk.com",
    "dumpmail.de",
    "dumpyemail.com",
    "durandinterstellar.com",
    "duskmail.com",
    "dwse.edu.pl",
    "dyceroprojects.com",
    "dz17.net",
    "e-mail.com",
    "e-mail.org",
    "e-marketstore.ru",
    "e-tomarigi.com",
    "e3z.de",
    "e4ward.com",
    "eanok.com",
    "easy-trash-mail.com",
    "easynetwork.info",
    "easytrashmail.com",
    "eatmea2z.club",
    "eay.jp",
    "ebbob.com",
    "ebeschlussbuch.de",
    "ecallheandi.com",
    "ecolo-online.fr",
    "edgex.ru",
    "edinburgh-airporthotels.com",
    "edv.to",
    "ee1.pl",
    "ee2.pl",
    "eeedv.de",
    "eelmail.com",
    "efxs.ca",
    "egzones.com",
    "einmalmail.de",
    "einrot.com",
    "einrot.de",
    "eintagsmail.de",
    "elearningjournal.org",
    "electro.mn",
    "elitevipatlantamodels.com",
    "elki-mkzn.ru",
    "email-fake.cf",
    "email-fake.com",
    "email-fake.ga",
    "email-fake.gq",
    "email-fake.ml",
    "email-fake.tk",
    "email-jetable.fr",
    "email-lab.com",
    "email-temp.com",
    "email.com",
    "email.edu.pl",
    "email.it",
    "email.net",
    "email.omshanti.edu.in",
    "email.ucms.edu.pk",
    "email1.pro",
    "email60.com",
    "emailage.cf",
    "emailage.ga",
    "emailage.gq",
    "emailage.ml",
    "emailage.tk",
    "emailate.com",
    "emailcu.icu",
    "emaildienst.de",
    "emaildrop.io",
    "emailfake.com",
    "emailfake.ml",
    "emailfreedom.ml",
    "emailgenerator.de",
    "emailgo.de",
    "emailias.com",
    "emailigo.de",
    "emailinfive.com",
    "emailisvalid.com",
    "emaillime.com",
    "emailmiser.com",
    "emailna.co",
    "emailnax.com",
    "emailo.pro",
    "emailondeck.com",
    "emailportal.info",
    "emailproxsy.com",
    "emailresort.com",
    "emails.ga",
    "emailsecurer.com",
    "emailsensei.com",
    "emailsingularity.net",
    "emailspam.cf",
    "emailspam.ga",
    "emailspam.gq",
    "emailspam.ml",
    "emailspam.tk",
    "emailsy.info",
    "emailtech.info",
    "emailtemporanea.com",
    "emailtemporanea.net",
    "emailtemporar.ro",
    "emailtemporario.com.br",
    "emailthe.net",
    "emailtmp.com",
    "emailto.de",
    "emailure.net",
    "emailwarden.com",
    "emailxfer.com",
    "emailz.cf",
    "emailz.ga",
    "emailz.gq",
    "emailz.ml",
    "emeil.in",
    "emeil.ir",
    "emeraldwebmail.com",
    "emil.com",
    "emkei.cf",
    "emkei.ga",
    "emkei.gq",
    "emkei.ml",
    "emkei.tk",
    "eml.pp.ua",
    "emlhub.com",
    "emlpro.com",
    "emltmp.com",
    "empireanime.ga",
    "emstjzh.com",
    "emz.net",
    "enayu.com",
    "enterto.com",
    "envy17.com",
    "eoffice.top",
    "eoopy.com",
    "epb.ro",
    "ephemail.net",
    "ephemeral.email",
    "eposta.buzz",
    "eposta.work",
    "eqiluxspam.ga",
    "ericjohnson.ml",
    "ero-tube.org",
    "esbano-ru.ru",
    "esc.la",
    "escapehatchapp.com",
    "esemay.com",
    "esgeneri.com",
    "esiix.com",
    "esprity.com",
    "estate-invest.fr",
    "eth2btc.info",
    "ether123.net",
    "ethereum1.top",
    "ethersports.org",
    "ethersportz.info",
    "etotvibor.ru",
    "etranquil.com",
    "etranquil.net",
    "etranquil.org",
    "euaqa.com",
    "evanfox.info",
    "eveav.com",
    "evilcomputer.com",
    "evopo.com",
    "evyush.com",
    "exdonuts.com",
    "existiert.net",
    "exitstageleft.net",
    "explodemail.com",
    "express.net.ua",
    "extremail.ru",
    "eyepaste.com",
    "ez.lv",
    "ezehe.com",
    "ezfill.com",
    "ezstest.com",
    "f4k.es",
    "f5.si",
    "facebook-email.cf",
    "facebook-email.ga",
    "facebook-email.ml",
    "facebookmail.gq",
    "facebookmail.ml",
    "fackme.gq",
    "fadingemail.com",
    "faecesmail.me",
    "fag.wf",
    "failbone.com",
    "faithkills.com",
    "fake-box.com",
    "fake-email.pp.ua",
    "fake-mail.cf",
    "fake-mail.ga",
    "fake-mail.ml",
    "fakedemail.com",
    "fakeinbox.cf",
    "fakeinbox.com",
    "fakeinbox.ga",
    "fakeinbox.info",
    "fakeinbox.ml",
    "fakeinbox.tk",
    "fakeinformation.com",
    "fakemail.fr",
    "fakemail.io",
    "fakemailgenerator.com",
    "fakemailz.com",
    "fallinhay.com",
    "fammix.com",
    "fanclub.pm",
    "fangoh.com",
    "fansworldwide.de",
    "fantasymail.de",
    "farrse.co.uk",
    "fast-email.info",
    "fast-mail.fr",
    "fastacura.com",
    "fastchevy.com",
    "fastchrysler.com",
    "fasternet.biz",
    "fastkawasaki.com",
    "fastmail.com",
    "fastmazda.com",
    "fastmitsubishi.com",
    "fastnissan.com",
    "fastsubaru.com",
    "fastsuzuki.com",
    "fasttoyota.com",
    "fastyamaha.com",
    "fatflap.com",
    "fbma.tk",
    "fddns.ml",
    "fdfdsfds.com",
    "fer-gabon.org",
    "fermaxxi.ru",
    "fettometern.com",
    "fexbox.org",
    "fexbox.ru",
    "fexpost.com",
    "ficken.de",
    "fictionsite.com",
    "fightallspam.com",
    "figjs.com",
    "figshot.com",
    "figurescoin.com",
    "fiifke.de",
    "filbert4u.com",
    "filberts4u.com",
    "film-blog.biz",
    "filzmail.com",
    "findemail.info",
    "findu.pl",
    "fir.hk",
    "firemailbox.club",
    "fitnesrezink.ru",
    "fivemail.de",
    "fixmail.tk",
    "fizmail.com",
    "fleckens.hu",
    "flemail.ru",
    "flowu.com",
    "flu.cc",
    "fluidsoft.us",
    "flurred.com",
    "fly-ts.de",
    "flyinggeek.net",
    "flyspam.com",
    "foobarbot.net",
    "footard.com",
    "foreastate.com",
    "forecastertests.com",
    "foreskin.cf",
    "foreskin.ga",
    "foreskin.gq",
    "foreskin.ml",
    "foreskin.tk",
    "forgetmail.com",
    "fornow.eu",
    "forspam.net",
    "forward.cat",
    "fosil.pro",
    "foxja.com",
    "foxtrotter.info",
    "fr.nf",
    "fr33mail.info",
    "fragolina2.tk",
    "frapmail.com",
    "frappina.tk",
    "free-email.cf",
    "free-email.ga",
    "free-temp.net",
    "freebabysittercam.com",
    "freeblackbootytube.com",
    "freecat.net",
    "freedom4you.info",
    "freedompop.us",
    "freefattymovies.com",
    "freehotmail.net",
    "freeinbox.email",
    "freelance-france.eu",
    "freeletter.me",
    "freemail.ms",
    "freemails.cf",
    "freemails.ga",
    "freemails.ml",
    "freemeil.ga",
    "freemeil.gq",
    "freemeil.ml",
    "freeml.net",
    "freeplumpervideos.com",
    "freerubli.ru",
    "freeschoolgirlvids.com",
    "freesistercam.com",
    "freeteenbums.com",
    "freundin.ru",
    "friendlymail.co.uk",
    "front14.org",
    "frwdmail.com",
    "ftp.sh",
    "ftpinc.ca",
    "fuckedupload.com",
    "fuckingduh.com",
    "fuckme69.club",
    "fucknloveme.top",
    "fuckxxme.top",
    "fudgerub.com",
    "fuirio.com",
    "fukaru.com",
    "fukurou.ch",
    "fuluj.com",
    "fulvie.com",
    "fun64.com",
    "funnycodesnippets.com",
    "funnymail.de",
    "furzauflunge.de",
    "fuwamofu.com",
    "fuwari.be",
    "fux0ringduh.com",
    "fxnxs.com",
    "fyii.de",
    "g14l71lb.com",
    "g1xmail.top",
    "g2xmail.top",
    "g3xmail.top",
    "g4hdrop.us",
    "gafy.net",
    "gage.ga",
    "galaxy.tv",
    "gally.jp",
    "gamail.top",
    "gamegregious.com",
    "gamgling.com",
    "garasikita.pw",
    "garbagecollector.org",
    "garbagemail.org",
    "gardenscape.ca",
    "garizo.com",
    "garliclife.com",
    "garrymccooey.com",
    "gav0.com",
    "gawab.com",
    "gbcmail.win",
    "gbmail.top",
    "gcmail.top",
    "gdmail.top",
    "gedmail.win",
    "geekforex.com",
    "geew.ru",
    "gehensiemirnichtaufdensack.de",
    "geldwaschmaschine.de",
    "gelitik.in",
    "genderfuck.net",
    "geronra.com",
    "geschent.biz",
    "get-mail.cf",
    "get-mail.ga",
    "get-mail.ml",
    "get-mail.tk",
    "get.pp.ua",
    "get1mail.com",
    "get2mail.fr",
    "getairmail.cf",
    "getairmail.com",
    "getairmail.ga",
    "getairmail.gq",
    "getairmail.ml",
    "getairmail.tk",
    "geteit.com",
    "getfun.men",
    "getmails.eu",
    "getnada.com",
    "getnowtoday.cf",
    "getonemail.com",
    "getonemail.net",
    "getover.de",
    "getsimpleemail.com",
    "gett.icu",
    "gexik.com",
    "ggmal.ml",
    "ghosttexter.de",
    "giacmosuaviet.info",
    "giaiphapmuasam.com",
    "giantmail.de",
    "gifto12.com",
    "ginzi.be",
    "ginzi.co.uk",
    "ginzi.es",
    "ginzi.net",
    "ginzy.co.uk",
    "ginzy.eu",
    "girlmail.win",
    "girlsindetention.com",
    "girlsundertheinfluence.com",
    "gishpuppy.com",
    "giveh2o.info",
    "givememail.club",
    "givmail.com",
    "glitch.sx",
    "globaltouron.com",
    "glubex.com",
    "glucosegrin.com",
    "gmailnator.com",
    "gmal.com",
    "gmatch.org",
    "gmial.com",
    "gmx1mail.top",
    "gmxmail.top",
    "gmxmail.win",
    "gnctr-calgary.com",
    "go2usa.info",
    "go2vpn.net",
    "goemailgo.com",
    "golemico.com",
    "gomail.in",
    "goonby.com",
    "goplaygame.ru",
    "gorillaswithdirtyarmpits.com",
    "goround.info",
    "gosuslugi-spravka.ru",
    "gothere.biz",
    "gotmail.com",
    "gotmail.net",
    "gotmail.org",
    "gowikibooks.com",
    "gowikicampus.com",
    "gowikicars.com",
    "gowikifilms.com",
    "gowikigames.com",
    "gowikimusic.com",
    "gowikinetwork.com",
    "gowikitravel.com",
    "gowikitv.com",
    "grandmamail.com",
    "grandmasmail.com",
    "great-host.in",
    "greencafe24.com",
    "greendike.com",
    "greenhousemail.com",
    "greensloth.com",
    "greggamel.com",
    "greggamel.net",
    "gregorsky.zone",
    "gregorygamel.com",
    "gregorygamel.net",
    "grish.de",
    "griuc.schule",
    "grn.cc",
    "groupbuff.com",
    "grr.la",
    "grugrug.ru",
    "gruz-m.ru",
    "gs-arc.org",
    "gsredcross.org",
    "gsrv.co.uk",
    "gsxstring.ga",
    "gudanglowongan.com",
    "guerillamail.biz",
    "guerillamail.com",
    "guerillamail.de",
    "guerillamail.info",
    "guerillamail.net",
    "guerillamail.org",
    "guerillamailblock.com",
    "guerrillamail.biz",
    "guerrillamail.com",
    "guerrillamail.de",
    "guerrillamail.info",
    "guerrillamail.net",
    "guerrillamail.org",
    "guerrillamailblock.com",
    "gustr.com",
    "gvpersons.com",
    "gxemail.men",
    "gynzi.co.uk",
    "gynzi.es",
    "gynzy.at",
    "gynzy.es",
    "gynzy.eu",
    "gynzy.gr",
    "gynzy.info",
    "gynzy.lt",
    "gynzy.mobi",
    "gynzy.pl",
    "gynzy.ro",
    "gynzy.sk",
    "gzb.ro",
    "h8s.org",
    "habitue.net",
    "hacccc.com",
    "hackersquad.tk",
    "hackthatbit.ch",
    "hahawrong.com",
    "haida-edu.cn",
    "hairs24.ru",
    "haltospam.com",
    "hamham.uk",
    "hangxomcuatoilatotoro.ml",
    "happy-new-year.top",
    "happydomik.ru",
    "harakirimail.com",
    "haribu.com",
    "hartbot.de",
    "hasanmail.ml",
    "hat-geld.de",
    "hatespam.org",
    "hawrong.com",
    "haydoo.com",
    "hazelnut4u.com",
    "hazelnuts4u.com",
    "hazmatshipping.org",
    "hccmail.win",
    "headstrong.de",
    "healteas.com",
    "heathenhammer.com",
    "heathenhero.com",
    "hecat.es",
    "heisei.be",
    "hellodream.mobi",
    "helloricky.com",
    "helpinghandtaxcenter.org",
    "helpjobs.ru",
    "heros3.com",
    "herp.in",
    "herpderp.nl",
    "hexud.com",
    "hezll.com",
    "hi5.si",
    "hiddentragedy.com",
    "hide.biz.st",
    "hidebox.org",
    "hidebusiness.xyz",
    "hidemail.de",
    "hidemail.pro",
    "hidemail.us",
    "hidemyass.com",
    "hidzz.com",
    "highbros.org",
    "hiltonvr.com",
    "himail.online",
    "hmail.us",
    "hmamail.com",
    "hmh.ro",
    "hoanggiaanh.com",
    "hoanglong.tech",
    "hochsitze.com",
    "hola.org",
    "holl.ga",
    "honeys.be",
    "honor-8.com",
    "hopemail.biz",
    "hornyalwary.top",
    "host1s.com",
    "hostcalls.com",
    "hostguru.top",
    "hostingmail.me",
    "hostlaba.com",
    "hot-mail.cf",
    "hot-mail.ga",
    "hot-mail.gq",
    "hot-mail.ml",
    "hot-mail.tk",
    "hotmai.com",
    "hotmailproduct.com",
    "hotmial.com",
    "hotpop.com",
    "hotprice.co",
    "hotsoup.be",
    "housat.com",
    "hpc.tw",
    "hrepy.com",
    "hs.vc",
    "ht.cx",
    "huangniu8.com",
    "hukkmu.tk",
    "hulapla.de",
    "humaility.com",
    "hungpackage.com",
    "hushmail.cf",
    "huskion.net",
    "hvastudiesucces.nl",
    "hwsye.net",
    "hypteo.com",
    "i2pmail.org",
    "i6.cloudns.cc",
    "iaoss.com",
    "ibnuh.bz",
    "icantbelieveineedtoexplainthisshit.com",
    "icemail.club",
    "ichigo.me",
    "icx.in",
    "icx.ro",
    "idrct.com",
    "idx4.com",
    "idxue.com",
    "ieatspam.eu",
    "ieatspam.info",
    "ieh-mail.de",
    "iencm.com",
    "iffymedia.com",
    "ige.es",
    "igg.biz",
    "ignoremail.com",
    "ihateyoualot.info",
    "ihazspam.ca",
    "iheartspam.org",
    "ikbenspamvrij.nl",
    "illistnoise.com",
    "ilovespam.com",
    "imail1.net",
    "imails.info",
    "imailt.com",
    "imgof.com",
    "imgv.de",
    "immo-gerance.info",
    "imstations.com",
    "imul.info",
    "in-ulm.de",
    "inbax.tk",
    "inbound.plus",
    "inbox.si",
    "inbox2.info",
    "inboxalias.com",
    "inboxbear.com",
    "inboxclean.com",
    "inboxclean.org",
    "inboxdesign.me",
    "inboxed.im",
    "inboxed.pw",
    "inboxkitten.com",
    "inboxproxy.com",
    "inboxstore.me",
    "inclusiveprogress.com",
    "incognitomail.com",
    "incognitomail.net",
    "incognitomail.org",
    "incoware.com",
    "incq.com",
    "ind.st",
    "indieclad.com",
    "indirect.ws",
    "indomaed.pw",
    "indomina.cf",
    "indoserver.stream",
    "indosukses.press",
    "ineec.net",
    "infocom.zp.ua",
    "inggo.org",
    "inkomail.com",
    "inmynetwork.tk",
    "inoutmail.de",
    "inoutmail.eu",
    "inoutmail.info",
    "inoutmail.net",
    "inpwa.com",
    "insanumingeniumhomebrew.com",
    "insorg-mail.info",
    "instaddr.ch",
    "instance-email.com",
    "instant-mail.de",
    "instantblingmail.info",
    "instantemailaddress.com",
    "instantmail.fr",
    "internet-v-stavropole.ru",
    "internetoftags.com",
    "interstats.org",
    "intersteller.com",
    "intopwa.com",
    "intopwa.net",
    "intopwa.org",
    "investore.co",
    "iozak.com",
    "ip4.pp.ua",
    "ip6.li",
    "ip6.pp.ua",
    "ipoo.org",
    "ippandansei.tk",
    "ipsur.org",
    "irabops.com",
    "irc.so",
    "irish2me.com",
    "irishspringrealty.com",
    "iroid.com",
    "ironiebehindert.de",
    "irssi.tv",
    "is.af",
    "isdaq.com",
    "ishop2k.com",
    "isosq.com",
    "istii.ro",
    "isukrainestillacountry.com",
    "it7.ovh",
    "italy-mail.com",
    "itcompu.com",
    "itfast.net",
    "itunesgiftcodegenerator.com",
    "iubridge.com",
    "iuemail.men",
    "iwi.net",
    "ixaks.com",
    "ixx.io",
    "j-p.us",
    "jafps.com",
    "jajxz.com",
    "janproz.com",
    "jaqis.com",
    "jdmadventures.com",
    "jdz.ro",
    "je-recycle.info",
    "jellow.ml",
    "jellyrolls.com",
    "jeoce.com",
    "jet-renovation.fr",
    "jetable.com",
    "jetable.fr.nf",
    "jetable.net",
    "jetable.org",
    "jetable.pp.ua",
    "jiooq.com",
    "jmail.ovh",
    "jmail.ro",
    "jnxjn.com",
    "jobbikszimpatizans.hu",
    "jobposts.net",
    "jobs-to-be-done.net",
    "joelpet.com",
    "joetestalot.com",
    "jopho.com",
    "joseihorumon.info",
    "josse.ltd",
    "jourrapide.com",
    "jpco.org",
    "jsrsolutions.com",
    "jumonji.tk",
    "jungkamushukum.com",
    "junk.to",
    "junk1e.com",
    "junkmail.ga",
    "junkmail.gq",
    "just-email.com",
    "justemail.ml",
    "juyouxi.com",
    "jwork.ru",
    "kademen.com",
    "kadokawa.cf",
    "kadokawa.ga",
    "kadokawa.gq",
    "kadokawa.ml",
    "kadokawa.tk",
    "kaengu.ru",
    "kagi.be",
    "kakadua.net",
    "kalapi.org",
    "kamen-market.ru",
    "kamsg.com",
    "kaovo.com",
    "kappala.info",
    "kara-turk.net",
    "karatraman.ml",
    "kariplan.com",
    "karta-kykyruza.ru",
    "kartvelo.com",
    "kasmail.com",
    "kaspop.com",
    "katztube.com",
    "kazelink.ml",
    "kbox.li",
    "kcrw.de",
    "keepmymail.com",
    "keinhirn.de",
    "keipino.de",
    "kekita.com",
    "kellychibale-researchgroup-uct.com",
    "kemptvillebaseball.com",
    "kennedy808.com",
    "kiani.com",
    "killmail.com",
    "killmail.net",
    "kimsdisk.com",
    "kingsq.ga",
    "kino-100.ru",
    "kiois.com",
    "kismail.ru",
    "kisstwink.com",
    "kitnastar.com",
    "kjkszpjcompany.com",
    "kkmail.be",
    "kksm.be",
    "klassmaster.com",
    "klassmaster.net",
    "klick-tipp.us",
    "klipschx12.com",
    "kloap.com",
    "kludgemush.com",
    "klzlk.com",
    "kmail.li",
    "kmhow.com",
    "knol-power.nl",
    "kobrandly.com",
    "kommunity.biz",
    "kon42.com",
    "konultant-jurist.ru",
    "kook.ml",
    "kopagas.com",
    "kopaka.net",
    "korona-nedvizhimosti.ru",
    "koshu.ru",
    "kosmetik-obatkuat.com",
    "kostenlosemailadresse.de",
    "koszmail.pl",
    "kpay.be",
    "kpooa.com",
    "kpost.be",
    "krd.ag",
    "krsw.tk",
    "kruay.com",
    "krypton.tk",
    "ksmtrck.tk",
    "kucix.com",
    "kuhrap.com",
    "kulmeo.com",
    "kulturbetrieb.info",
    "kurzepost.de",
    "kutakbisajauhjauh.gq",
    "kvhrr.com",
    "kvhrs.com",
    "kvhrw.com",
    "kwift.net",
    "kwilco.net",
    "kyal.pl",
    "kyois.com",
    "l-c-a.us",
    "l33r.eu",
    "l6factors.com",
    "labetteraverouge.at",
    "labworld.org",
    "lacedmail.com",
    "lackmail.net",
    "lackmail.ru",
    "lacto.info",
    "lags.us",
    "lain.ch",
    "lak.pp.ua",
    "lakelivingstonrealestate.com",
    "lakqs.com",
    "landmail.co",
    "laoeq.com",
    "larisia.com",
    "last-chance.pro",
    "lastmail.co",
    "lastmail.com",
    "lawlita.com",
    "lazyinbox.com",
    "lazyinbox.us",
    "ldaho.biz",
    "ldop.com",
    "ldtp.com",
    "le-tim.ru",
    "lee.mx",
    "leeching.net",
    "leetmail.co",
    "legalrc.loan",
    "lellno.gq",
    "lenovog4.com",
    "lerbhe.com",
    "letmeinonthis.com",
    "letthemeatspam.com",
    "lez.se",
    "lgxscreen.com",
    "lhsdv.com",
    "liamcyrus.com",
    "lifebyfood.com",
    "lifetimefriends.info",
    "lifetotech.com",
    "ligsb.com",
    "lillemap.net",
    "lilo.me",
    "lindenbaumjapan.com",
    "link2mail.net",
    "linkedintuts2016.pw",
    "linshiyouxiang.net",
    "linuxmail.so",
    "litedrop.com",
    "liveradio.tk",
    "lkgn.se",
    "llogin.ru",
    "loadby.us",
    "loan101.pro",
    "loaoa.com",
    "loapq.com",
    "locanto1.club",
    "locantofuck.top",
    "locantowsite.club",
    "locomodev.net",
    "login-email.cf",
    "login-email.ga",
    "login-email.ml",
    "login-email.tk",
    "logular.com",
    "loh.pp.ua",
    "loin.in",
    "lolfreak.net",
    "lolmail.biz",
    "lookugly.com",
    "lordsofts.com",
    "lortemail.dk",
    "losemymail.com",
    "lovemeet.faith",
    "lovemeleaveme.com",
    "lpfmgmtltd.com",
    "lr7.us",
    "lr78.com",
    "lroid.com",
    "lru.me",
    "ls-server.ru",
    "lsyx24.com",
    "luckymail.org",
    "lukecarriere.com",
    "lukemail.info",
    "lukop.dk",
    "luv2.us",
    "lyfestylecreditsolutions.com",
    "lzoaq.com",
    "m21.cc",
    "m4ilweb.info",
    "maboard.com",
    "mac-24.com",
    "macr2.com",
    "macromaid.com",
    "macromice.info",
    "magamail.com",
    "maggotymeat.ga",
    "magicbox.ro",
    "magim.be",
    "magspam.net",
    "maidlow.info",
    "mail-card.net",
    "mail-easy.fr",
    "mail-filter.com",
    "mail-help.net",
    "mail-hosting.co",
    "mail-hub.info",
    "mail-now.top",
    "mail-owl.com",
    "mail-share.com",
    "mail-temporaire.com",
    "mail-temporaire.fr",
    "mail-tester.com",
    "mail.by",
    "mail.darmajaya.ac.id",
    "mail.ru",
    "mail.tm",
    "mail.wtf",
    "mail0.ga",
    "mail1.top",
    "mail114.net",
    "mail1a.de",
    "mail1web.org",
    "mail21.cc",
    "mail22.club",
    "mail2rss.org",
    "mail333.com",
    "mail4trash.com",
    "mail666.ru",
    "mail7.io",
    "mail707.com",
    "mail72.com",
    "mailapp.top",
    "mailback.com",
    "mailbidon.com",
    "mailbiz.biz",
    "mailblocks.com",
    "mailbox.in.ua",
    "mailbox52.ga",
    "mailbox80.biz",
    "mailbox82.biz",
    "mailbox87.de",
    "mailbox92.biz",
    "mailboxy.fun",
    "mailbucket.org",
    "mailcat.biz",
    "mailcatch.com",
    "mailchop.com",
    "mailcker.com",
    "maildax.me",
    "mailde.de",
    "mailde.info",
    "maildrop.cc",
    "maildrop.cf",
    "maildrop.ga",
    "maildrop.gq",
    "maildrop.io",
    "maildrop.ml",
    "maildu.de",
    "maildx.com",
    "maileater.com",
    "mailed.in",
    "mailed.ro",
    "maileimer.de",
    "maileme101.com",
    "mailexpire.com",
    "mailf5.com",
    "mailfa.tk",
    "mailfall.com",
    "mailfirst.icu",
    "mailforspam.com",
    "mailfree.ga",
    "mailfree.gq",
    "mailfree.ml",
    "mailfreeonline.com",
    "mailfs.com",
    "mailguard.me",
    "mailgutter.com",
    "mailhazard.com",
    "mailhazard.us",
    "mailhex.com",
    "mailhub.pro",
    "mailhz.me",
    "mailimate.com",
    "mailin8r.com",
    "mailinatar.com",
    "mailinater.com",
    "mailinator.co.uk",
    "mailinator.com",
    "mailinator.gq",
    "mailinator.info",
    "mailinator.net",
    "mailinator.org",
    "mailinator.us",
    "mailinator0.com",
    "mailinator1.com",
    "mailinator2.com",
    "mailinator2.net",
    "mailinator3.com",
    "mailinator4.com",
    "mailinator5.com",
    "mailinator6.com",
    "mailinator7.com",
    "mailinator8.com",
    "mailinator9.com",
    "mailincubator.com",
    "mailismagic.com",
    "mailita.tk",
    "mailjunk.cf",
    "mailjunk.ga",
    "mailjunk.gq",
    "mailjunk.ml",
    "mailjunk.tk",
    "mailmate.com",
    "mailme.gq",
    "mailme.ir",
    "mailme.lv",
    "mailme24.com",
    "mailmetrash.com",
    "mailmoat.com",
    "mailmoth.com",
    "mailms.com",
    "mailna.biz",
    "mailna.co",
    "mailna.in",
    "mailna.me",
    "mailnator.com",
    "mailnesia.com",
    "mailnull.com",
    "mailonaut.com",
    "mailorc.com",
    "mailorg.org",
    "mailox.fun",
    "mailpick.biz",
    "mailpluss.com",
    "mailpooch.com",
    "mailpoof.com",
    "mailpress.gq",
    "mailproxsy.com",
    "mailquack.com",
    "mailrock.biz",
    "mailsac.com",
    "mailscrap.com",
    "mailseal.de",
    "mailshell.com",
    "mailshiv.com",
    "mailsiphon.com",
    "mailslapping.com",
    "mailslite.com",
    "mailsucker.net",
    "mailt.net",
    "mailt.top",
    "mailtechx.com",
    "mailtemp.info",
    "mailtemporaire.com",
    "mailtemporaire.fr",
    "mailto.plus",
    "mailtome.de",
    "mailtothis.com",
    "mailtraps.com",
    "mailtrash.net",
    "mailtrix.net",
    "mailtv.net",
    "mailtv.tv",
    "mailuniverse.co.uk",
    "mailzi.ru",
    "mailzilla.com",
    "mailzilla.org",
    "mainerfolg.info",
    "makemenaughty.club",
    "makemetheking.com",
    "malahov.de",
    "malayalamdtp.com",
    "mama3.org",
    "mamulenok.ru",
    "mandraghen.cf",
    "manifestgenerator.com",
    "mannawo.com",
    "mansiondev.com",
    "manybrain.com",
    "mark-compressoren.ru",
    "marketlink.info",
    "markmurfin.com",
    "mask03.ru",
    "masonline.info",
    "maswae.world",
    "matamuasu.ga",
    "matchpol.net",
    "matra.site",
    "max-mail.org",
    "mbox.re",
    "mbx.cc",
    "mcache.net",
    "mciek.com",
    "mdhc.tk",
    "meantinc.com",
    "mebelnu.info",
    "mechanicalresumes.com",
    "medkabinet-uzi.ru",
    "meepsheep.eu",
    "meinspamschutz.de",
    "meltedbrownies.com",
    "meltmail.com",
    "memsg.site",
    "mentonit.net",
    "mepost.pw",
    "merry.pink",
    "messagebeamer.de",
    "messwiththebestdielikethe.rest",
    "metadownload.org",
    "metaintern.net",
    "metalunits.com",
    "mezimages.net",
    "mfsa.info",
    "mfsa.ru",
    "mhzayt.online",
    "miaferrari.com",
    "miauj.com",
    "midcoastcustoms.com",
    "midcoastcustoms.net",
    "midcoastsolutions.com",
    "midcoastsolutions.net",
    "midiharmonica.com",
    "midlertidig.com",
    "midlertidig.net",
    "midlertidig.org",
    "mierdamail.com",
    "migmail.net",
    "migmail.pl",
    "migumail.com",
    "mihep.com",
    "mijnhva.nl",
    "ministry-of-silly-walks.de",
    "minsmail.com",
    "mintemail.com",
    "mirai.re",
    "misterpinball.de",
    "miucce.com",
    "mji.ro",
    "mjj.edu.ge",
    "mjukglass.nu",
    "mkpfilm.com",
    "ml8.ca",
    "mm.my",
    "mm5.se",
    "mnode.me",
    "moakt.cc",
    "moakt.co",
    "moakt.com",
    "moakt.ws",
    "mobileninja.co.uk",
    "mobilevpn.top",
    "moburl.com",
    "mockmyid.com",
    "moeri.org",
    "mofu.be",
    "mohmal.com",
    "mohmal.im",
    "mohmal.in",
    "mohmal.tech",
    "moimoi.re",
    "molms.com",
    "momentics.ru",
    "monachat.tk",
    "monadi.ml",
    "moneypipe.net",
    "monumentmail.com",
    "moonwake.com",
    "moot.es",
    "moreawesomethanyou.com",
    "moreorcs.com",
    "morriesworld.ml",
    "morsin.com",
    "moruzza.com",
    "motique.de",
    "mountainregionallibrary.net",
    "mox.pp.ua",
    "moy-elektrik.ru",
    "moza.pl",
    "mozej.com",
    "mp-j.ga",
    "mr24.co",
    "mrvpm.net",
    "mrvpt.com",
    "msgos.com",
    "mspeciosa.com",
    "msrc.ml",
    "mswork.ru",
    "msxd.com",
    "mt2009.com",
    "mt2014.com",
    "mt2015.com",
    "mtmdev.com",
    "muathegame.com",
    "muchomail.com",
    "mucincanon.com",
    "muehlacker.tk",
    "muell.icu",
    "muell.monster",
    "muell.xyz",
    "muellemail.com",
    "muellmail.com",
    "munoubengoshi.gq",
    "musiccode.me",
    "mutant.me",
    "mvrht.com",
    "mvrht.net",
    "mwarner.org",
    "mxclip.com",
    "mxfuel.com",
    "mxid.org",
    "my-pomsies.ru",
    "my-teddyy.ru",
    "my10minutemail.com",
    "mybitti.de",
    "mycleaninbox.net",
    "mycorneroftheinter.net",
    "myde.ml",
    "mydemo.equipment",
    "myecho.es",
    "myemailboxy.com",
    "mygeoweb.info",
    "myindohome.services",
    "myinterserver.ml",
    "mykickassideas.com",
    "mymail-in.net",
    "mymail.infos.st",
    "mymail90.com",
    "mymailoasis.com",
    "mynetstore.de",
    "myopang.com",
    "mypacks.net",
    "mypartyclip.de",
    "myphantomemail.com",
    "mysamp.de",
    "myspaceinc.com",
    "myspaceinc.net",
    "myspaceinc.org",
    "myspacepimpedup.com",
    "myspamless.com",
    "mystvpn.com",
    "mysugartime.ru",
    "mytemp.email",
    "mytempemail.com",
    "mytempmail.com",
    "mytrashmail.com",
    "mywarnernet.net",
    "mywrld.site",
    "mywrld.top",
    "myzx.com",
    "mzico.com",
    "n1nja.org",
    "na-cat.com",
    "nabuma.com",
    "nada.email",
    "nada.ltd",
    "nafxo.com",
    "nagi.be",
    "nakedtruth.biz",
    "nanonym.ch",
    "naslazhdai.ru",
    "nationalgardeningclub.com",
    "nawmin.info",
    "nbzmr.com",
    "negated.com",
    "neko2.net",
    "nekochan.fr",
    "neomailbox.com",
    "neotlozhniy-zaim.ru",
    "nepwk.com",
    "nervmich.net",
    "nervtmich.net",
    "net1mail.com",
    "netcom.ws",
    "netmails.com",
    "netmails.net",
    "netricity.nl",
    "netris.net",
    "netviewer-france.com",
    "netzidiot.de",
    "nevermail.de",
    "newbpotato.tk",
    "newfilm24.ru",
    "newideasfornewpeople.info",
    "newmail.top",
    "next.ovh",
    "nextmail.info",
    "nextstopvalhalla.com",
    "nezdiro.org",
    "nezid.com",
    "nezumi.be",
    "nezzart.com",
    "nfast.net",
    "nguyenusedcars.com",
    "nh3.ro",
    "nice-4u.com",
    "nicknassar.com",
    "nincsmail.com",
    "nincsmail.hu",
    "niseko.be",
    "niwl.net",
    "nm7.cc",
    "nmail.cf",
    "nnh.com",
    "nnot.net",
    "nnoway.ru",
    "no-spam.ws",
    "no-ux.com",
    "noblepioneer.com",
    "nobugmail.com",
    "nobulk.com",
    "nobuma.com",
    "noclickemail.com",
    "nodezine.com",
    "nogmailspam.info",
    "noicd.com",
    "nokiamail.com",
    "nolemail.ga",
    "nomail.cf",
    "nomail.ga",
    "nomail.pw",
    "nomail2me.com",
    "nomorespamemails.com",
    "nonspam.eu",
    "nonspammer.de",
    "nonze.ro",
    "noref.in",
    "norseforce.com",
    "norwegischlernen.info",
    "nospam.ze.tc",
    "nospam4.us",
    "nospamfor.us",
    "nospamthanks.info",
    "nothingtoseehere.ca",
    "notif.me",
    "notmailinator.com",
    "notrnailinator.com",
    "notsharingmy.info",
    "now.im",
    "nowhere.org",
    "nowmymail.com",
    "nowmymail.net",
    "nproxi.com",
    "nthrl.com",
    "ntlhelp.net",
    "nubescontrol.com",
    "nullbox.info",
    "nurfuerspam.de",
    "nut.cc",
    "nutpa.net",
    "nuts2trade.com",
    "nvhrw.com",
    "nwldx.com",
    "nwytg.com",
    "nwytg.net",
    "ny7.me",
    "nypato.com",
    "nyrmusic.com",
    "o2stk.org",
    "o7i.net",
    "oalsp.com",
    "obfusko.com",
    "objectmail.com",
    "obobbo.com",
    "oborudovanieizturcii.ru",
    "obxpestcontrol.com",
    "odaymail.com",
    "odem.com",
    "odnorazovoe.ru",
    "oepia.com",
    "oerpub.org",
    "offshore-proxies.net",
    "ohaaa.de",
    "ohi.tw",
    "oida.icu",
    "oing.cf",
    "okclprojects.com",
    "okinawa.li",
    "okrent.us",
    "okzk.com",
    "olimp-case.ru",
    "olypmall.ru",
    "omail.pro",
    "omnievents.org",
    "omtecha.com",
    "one-mail.top",
    "one-time.email",
    "one2mail.info",
    "onekisspresave.com",
    "onemail.host",
    "oneoffemail.com",
    "oneoffmail.com",
    "onetm.jp",
    "onewaymail.com",
    "onlatedotcom.info",
    "online.ms",
    "onlineidea.info",
    "onqin.com",
    "ontyne.biz",
    "oohioo.com",
    "oolus.com",
    "oonies-shoprus.ru",
    "oopi.org",
    "oosln.com",
    "opayq.com",
    "openavz.com",
    "opendns.ro",
    "opentrash.com",
    "opmmedia.ga",
    "opp24.com",
    "optimaweb.me",
    "opwebw.com",
    "oranek.com",
    "ordinaryamerican.net",
    "oreidresume.com",
    "orgmbx.cc",
    "oroki.de",
    "oshietechan.link",
    "otherinbox.com",
    "ourklips.com",
    "ourpreviewdomain.com",
    "outlawspam.com",
    "outmail.win",
    "ovomail.co",
    "ovpn.to",
    "owleyes.ch",
    "owlpic.com",
    "ownsyou.de",
    "oxopoha.com",
    "ozyl.de",
    "p-banlis.ru",
    "p33.org",
    "p71ce1m.com",
    "pa9e.com",
    "pachilly.com",
    "packiu.com",
    "pagamenti.tk",
    "paharpurmim.ga",
    "pakadebu.ga",
    "pancakemail.com",
    "papierkorb.me",
    "paplease.com",
    "para2019.ru",
    "parlimentpetitioner.tk",
    "pastebitch.com",
    "patonce.com",
    "pavilionx2.com",
    "payperex2.com",
    "payspun.com",
    "pe.hu",
    "pebih.com",
    "pecinan.com",
    "pecinan.net",
    "pecinan.org",
    "penisgoes.in",
    "penoto.tk",
    "pepbot.com",
    "peterdethier.com",
    "petloca.com",
    "petrzilka.net",
    "pewpewpewpew.pw",
    "pfui.ru",
    "phone-elkey.ru",
    "photo-impact.eu",
    "photomark.net",
    "pi.vu",
    "piaa.me",
    "pig.pp.ua",
    "pii.at",
    "piki.si",
    "pimpedupmyspace.com",
    "pinehill-seattle.org",
    "pingir.com",
    "pipemail.space",
    "pisls.com",
    "pitaniezdorovie.ru",
    "pivo-bar.ru",
    "pjjkp.com",
    "placebomail10.com",
    "pleasenoham.org",
    "plexfirm.com",
    "plexolan.de",
    "plhk.ru",
    "ploae.com",
    "plw.me",
    "poehali-otdihat.ru",
    "pojok.ml",
    "pokemail.net",
    "pokiemobile.com",
    "polarkingxx.ml",
    "politikerclub.de",
    "polyfaust.net",
    "pooae.com",
    "poofy.org",
    "pookmail.com",
    "poopiebutt.club",
    "popcornfarm7.com",
    "popcornfly.com",
    "popesodomy.com",
    "popgx.com",
    "porjoton.com",
    "porsh.net",
    "posdz.com",
    "posta.store",
    "postacin.com",
    "postonline.me",
    "poutineyourface.com",
    "powered.name",
    "powerencry.com",
    "powlearn.com",
    "pp7rvv.com",
    "ppetw.com",
    "pptrvv.com",
    "pqoia.com",
    "pratikmail.com",
    "pratikmail.net",
    "pratikmail.org",
    "prazdnik-37.ru",
    "predatorrat.cf",
    "predatorrat.ga",
    "predatorrat.gq",
    "predatorrat.ml",
    "predatorrat.tk",
    "premium-mail.fr",
    "premiumnator.com",
    "primabananen.net",
    "prin.be",
    "privacy-mail.top",
    "privacy.net",
    "privatdemail.net",
    "privy-mail.com",
    "privy-mail.de",
    "privymail.de",
    "pro-tag.org",
    "procrackers.com",
    "profast.top",
    "projectcl.com",
    "promailt.com",
    "proprietativalcea.ro",
    "propscore.com",
    "protempmail.com",
    "proton.me",
    "protonmail.ch",
    "protonmail.com",
    "proxymail.eu",
    "proxyparking.com",
    "prtnx.com",
    "prtshr.com",
    "prtz.eu",
    "psh.me",
    "psles.com",
    "psnator.com",
    "psoxs.com",
    "puglieisi.com",
    "puji.pro",
    "punkass.com",
    "puppetmail.de",
    "purcell.email",
    "purelogistics.org",
    "put2.net",
    "puttanamaiala.tk",
    "putthisinyourspamdatabase.com",
    "pwrby.com",
    "qasti.com",
    "qbfree.us",
    "qc.to",
    "qibl.at",
    "qipmail.net",
    "qiq.us",
    "qisdo.com",
    "qisoa.com",
    "qmrbe.com",
    "qoika.com",
    "qopow.com",
    "qq.my",
    "qsl.ro",
    "qtum-ico.com",
    "quadrafit.com",
    "quick-mail.cc",
    "quickemail.info",
    "quickinbox.com",
    "quickmail.nl",
    "quicksend.ch",
    "ququb.com",
    "qvy.me",
    "qwickmail.com",
    "r4nd0m.de",
    "ra3.us",
    "rabin.ca",
    "rabiot.reisen",
    "rackabzar.com",
    "raetp9.com",
    "rainbowly.ml",
    "raketenmann.de",
    "rancidhome.net",
    "randomail.io",
    "randomail.net",
    "rapt.be",
    "raqid.com",
    "rax.la",
    "raxtest.com",
    "razemail.com",
    "rbb.org",
    "rcasd.com",
    "rcpt.at",
    "rdklcrv.xyz",
    "re-gister.com",
    "reality-concept.club",
    "reallymymail.com",
    "realtyalerts.ca",
    "rebates.stream",
    "receiveee.com",
    "recipeforfailure.com",
    "recode.me",
    "reconmail.com",
    "recyclemail.dk",
    "redfeathercrow.com",
    "reftoken.net",
    "regbypass.com",
    "regspaces.tk",
    "reimondo.com",
    "rejectmail.com",
    "rejo.technology",
    "reliable-mail.com",
    "remail.cf",
    "remail.ga",
    "remarkable.rocks",
    "remote.li",
    "reptilegenetics.com",
    "resgedvgfed.tk",
    "revolvingdoorhoax.org",
    "rfc822.org",
    "rhyta.com",
    "richfinances.pw",
    "riddermark.de",
    "rifkian.ga",
    "rippb.com",
    "risingsuntouch.com",
    "riski.cf",
    "rklips.com",
    "rkomo.com",
    "rm2rf.com",
    "rma.ec",
    "rmqkr.net",
    "rnailinator.com",
    "ro.lt",
    "robertspcrepair.com",
    "robot-mail.com",
    "rollindo.agency",
    "ronnierage.net",
    "rootfest.net",
    "rosebearmylove.ru",
    "rotaniliam.com",
    "rover.info",
    "rowe-solutions.com",
    "royal.net",
    "royaldoodles.org",
    "royalmarket.life",
    "royandk.com",
    "rppkn.com",
    "rsvhr.com",
    "rtrtr.com",
    "rtskiya.xyz",
    "rudymail.ml",
    "rumgel.com",
    "runi.ca",
    "rupayamail.com",
    "ruru.be",
    "rustydoor.com",
    "rvb.ro",
    "ryteto.me",
    "s0ny.net",
    "s33db0x.com",
    "sabrestlouis.com",
    "sackboii.com",
    "safaat.cf",
    "safermail.info",
    "safersignup.de",
    "safetymail.info",
    "safetypost.de",
    "saharanightstempe.com",
    "salmeow.tk",
    "samsclass.info",
    "sandcars.net",
    "sandelf.de",
    "sandwhichvideo.com",
    "sanfinder.com",
    "sanim.net",
    "sanstr.com",
    "sast.ro",
    "satisfyme.club",
    "satukosong.com",
    "sausen.com",
    "saynotospams.com",
    "scatmail.com",
    "scay.net",
    "schachrol.com",
    "schafmail.de",
    "schmeissweg.tk",
    "schrott-email.de",
    "scrsot.com",
    "sd3.in",
    "sdvft.com",
    "sdvgeft.com",
    "sdvrecft.com",
    "secmail.pw",
    "secretemail.de",
    "secure-mail.biz",
    "secure-mail.cc",
    "secured-link.net",
    "securehost.com.es",
    "seekapps.com",
    "seekjobs4u.com",
    "sejaa.lv",
    "selfdestructingmail.com",
    "selfdestructingmail.org",
    "send22u.info",
    "sendfree.org",
    "sendingspecialflyers.com",
    "sendnow.win",
    "sendspamhere.com",
    "senseless-entertainment.com",
    "server.ms",
    "services391.com",
    "sexforswingers.com",
    "sexical.com",
    "sexyalwasmi.top",
    "shadap.org",
    "shalar.net",
    "sharedmailbox.org",
    "sharklasers.com",
    "sheryli.com",
    "shhmail.com",
    "shhuut.org",
    "shieldedmail.com",
    "shieldemail.com",
    "shiftmail.com",
    "shipfromto.com",
    "shiphazmat.org",
    "shipping-regulations.com",
    "shippingterms.org",
    "shitaway.tk",
    "shitmail.de",
    "shitmail.me",
    "shitmail.org",
    "shmeriously.com",
    "shopxda.com",
    "shortmail.net",
    "shotmail.ru",
    "showslow.de",
    "shrib.com",
    "shut.name",
    "shut.ws",
    "siberpay.com",
    "sidelka-mytischi.ru",
    "siftportal.ru",
    "sify.com",
    "sika3.com",
    "sikux.com",
    "siliwangi.ga",
    "silvercoin.life",
    "sim-simka.ru",
    "simaenaga.com",
    "simpleitsecurity.info",
    "simplelogin.co",
    "sin.cl",
    "sinaite.net",
    "sinema.ml",
    "sinfiltro.cl",
    "singlespride.com",
    "sinnlos-mail.de",
    "sino.tw",
    "siteposter.net",
    "sizzlemctwizzle.com",
    "sjuaq.com",
    "skeefmail.com",
    "skrx.tk",
    "sky-inbox.com",
    "sky-ts.de",
    "skyrt.de",
    "slapsfromlastnight.com",
    "slaskpost.se",
    "slave-auctions.net",
    "slippery.email",
    "slipry.net",
    "slopsbox.com",
    "slothmail.net",
    "slushmail.com",
    "sluteen.com",
    "sly.io",
    "smallker.tk",
    "smapfree24.com",
    "smapfree24.de",
    "smapfree24.eu",
    "smapfree24.info",
    "smapfree24.org",
    "smartnator.com",
    "smarttalent.pw",
    "smashmail.de",
    "smellfear.com",
    "smellrear.com",
    "smellypotato.tk",
    "smtp99.com",
    "smwg.info",
    "snakemail.com",
    "snapwet.com",
    "sneakemail.com",
    "sneakmail.de",
    "snece.com",
    "social-mailer.tk",
    "socialfurry.org",
    "sofia.re",
    "sofimail.com",
    "sofort-mail.de",
    "sofortmail.de",
    "sofrge.com",
    "softkey-office.ru",
    "softpls.asia",
    "sogetthis.com",
    "sohai.ml",
    "sohus.cn",
    "soioa.com",
    "soisz.com",
    "solar-impact.pro",
    "solvemail.info",
    "solventtrap.wiki",
    "songsign.com",
    "sonshi.cf",
    "soodmail.com",
    "soodomail.com",
    "soodonims.com",
    "soon.it",
    "spacebazzar.ru",
    "spam-be-gone.com",
    "spam.care",
    "spam.la",
    "spam.org.es",
    "spam.su",
    "spam4.me",
    "spamail.de",
    "spamarrest.com",
    "spamavert.com",
    "spambob.com",
    "spambob.net",
    "spambob.org",
    "spambog.com",
    "spambog.de",
    "spambog.net",
    "spambog.ru",
    "spambooger.com",
    "spambox.info",
    "spambox.me",
    "spambox.org",
    "spambox.us",
    "spamcero.com",
    "spamcon.org",
    "spamcorptastic.com",
    "spamcowboy.com",
    "spamcowboy.net",
    "spamcowboy.org",
    "spamday.com",
    "spamdecoy.net",
    "spamex.com",
    "spamfighter.cf",
    "spamfighter.ga",
    "spamfighter.gq",
    "spamfighter.ml",
    "spamfighter.tk",
    "spamfree.eu",
    "spamfree24.com",
    "spamfree24.de",
    "spamfree24.eu",
    "spamfree24.info",
    "spamfree24.net",
    "spamfree24.org",
    "spamgoes.in",
    "spamgourmet.com",
    "spamherelots.com",
    "spamhereplease.com",
    "spamhole.com",
    "spamify.com",
    "spaminator.de",
    "spamkill.info",
    "spaml.com",
    "spaml.de",
    "spamlot.net",
    "spammer.fail",
    "spammotel.com",
    "spammy.host",
    "spamobox.com",
    "spamoff.de",
    "spamsalad.in",
    "spamslicer.com",
    "spamsphere.com",
    "spamspot.com",
    "spamstack.net",
    "spamthis.co.uk",
    "spamthis.network",
    "spamthisplease.com",
    "spamtrail.com",
    "spamtrap.ro",
    "spamtroll.net",
    "spamwc.cf",
    "spamwc.ga",
    "spamwc.gq",
    "spamwc.ml",
    "speedgaus.net",
    "sperma.cf",
    "spikio.com",
    "spindl-e.com",
    "spoofmail.de",
    "spr.io",
    "spritzzone.de",
    "spruzme.com",
    "spybox.de",
    "squizzy.de",
    "squizzy.net",
    "sroff.com",
    "sry.li",
    "ssoia.com",
    "stanfordujjain.com",
    "starlight-breaker.net",
    "starpower.space",
    "startfu.com",
    "startkeys.com",
    "statdvr.com",
    "stathost.net",
    "statiix.com",
    "stayhome.li",
    "steam-area.ru",
    "steambot.net",
    "stexsy.com",
    "stinkefinger.net",
    "stop-my-spam.cf",
    "stop-my-spam.com",
    "stop-my-spam.ga",
    "stop-my-spam.ml",
    "stop-my-spam.pp.ua",
    "stop-my-spam.tk",
    "stopspam.app",
    "storiqax.top",
    "storj99.com",
    "storj99.top",
    "streetwisemail.com",
    "stromox.com",
    "stuckmail.com",
    "stuffmail.de",
    "stumpfwerk.com",
    "stylist-volos.ru",
    "submic.com",
    "suburbanthug.com",
    "suckmyd.com",
    "sueshaw.com",
    "suexamplesb.com",
    "suioe.com",
    "super-auswahl.de",
    "supergreatmail.com",
    "supermailer.jp",
    "superplatyna.com",
    "superrito.com",
    "supersave.net",
    "superstachel.de",
    "superyp.com",
    "suremail.info",
    "sute.jp",
    "svip520.cn",
    "svk.jp",
    "svxr.org",
    "sweetpotato.ml",
    "sweetxxx.de",
    "swift-mail.net",
    "swift10minutemail.com",
    "sxbta.com",
    "sylvannet.com",
    "symphonyresume.com",
    "syosetu.gq",
    "syujob.accountants",
    "szerz.com",
    "tafmail.com",
    "tafoi.gr",
    "taglead.com",
    "tagmymedia.com",
    "tagyourself.com",
    "tahyu.com",
    "talkinator.com",
    "tanukis.org",
    "tapchicuoihoi.com",
    "taphear.com",
    "tapi.re",
    "tarzanmail.cf",
    "tastrg.com",
    "taukah.com",
    "tb-on-line.net",
    "tdtda.com",
    "tech69.com",
    "techblast.ch",
    "techemail.com",
    "techgroup.me",
    "technoproxy.ru",
    "teerest.com",
    "teewars.org",
    "tefl.ro",
    "telecomix.pl",
    "teleworm.com",
    "teleworm.us",
    "tellos.xyz",
    "teml.net",
    "temp-link.net",
    "temp-mail.com",
    "temp-mail.de",
    "temp-mail.org",
    "temp-mail.pp.ua",
    "temp-mail.ru",
    "temp-mails.com",
    "tempail.com",
    "tempalias.com",
    "tempe-mail.com",
    "tempemail.biz",
    "tempemail.co.za",
    "tempemail.com",
    "tempemail.net",
    "tempinbox.co.uk",
    "tempinbox.com",
    "tempmail.cn",
    "tempmail.co",
    "tempmail.com",
    "tempmail.de",
    "tempmail.dev",
    "tempmail.eu",
    "tempmail.it",
    "tempmail.pp.ua",
    "tempmail.us",
    "tempmail.ws",
    "tempmail2.com",
    "tempmailbox.net",
    "tempmaildemo.com",
    "tempmailer.com",
    "tempmailer.de",
    "tempmailer.net",
    "tempmaili.com",
    "tempmailo.com",
    "tempomail.fr",
    "tempomail.org",
    "temporarily.de",
    "temporarioemail.com.br",
    "temporary-email.org",
    "temporary-mail.net",
    "temporaryemail.net",
    "temporaryemail.us",
    "temporaryforwarding.com",
    "temporaryinbox.com",
    "temporarymailaddress.com",
    "tempr.email",
    "temprmail.com",
    "tempsky.com",
    "tempthe.net",
    "tempymail.com",
    "tensi.org",
    "ternaklele.ga",
    "testore.co",
    "testudine.com",
    "thanksnospam.info",
    "thankyou2010.com",
    "thatim.info",
    "thc.st",
    "the23app.com",
    "theaviors.com",
    "thebearshark.com",
    "thecarinformation.com",
    "thechildrensfocus.com",
    "thecity.biz",
    "thecloudindex.com",
    "thediamants.org",
    "thedirhq.info",
    "thejoker5.com",
    "thelightningmail.net",
    "thelimestones.com",
    "thembones.com.au",
    "themegreview.com",
    "themostemail.com",
    "thereddoors.online",
    "theroyalweb.club",
    "thescrappermovie.com",
    "theteastory.info",
    "thex.ro",
    "thichanthit.com",
    "thietbivanphong.asia",
    "thisisnotmyrealemail.com",
    "thismail.net",
    "thisurl.website",
    "thnikka.com",
    "thoas.ru",
    "thraml.com",
    "thrma.com",
    "throam.com",
    "thrott.com",
    "throwam.com",
    "throwawayemailaddress.com",
    "throwawaymail.com",
    "throwawaymail.pp.ua",
    "throya.com",
    "thrubay.com",
    "thunderbolt.science",
    "thunkinator.org",
    "thxmate.com",
    "tiapz.com",
    "tic.ec",
    "tilien.com",
    "timgiarevn.com",
    "timkassouf.com",
    "tinoza.org",
    "tinyurl24.com",
    "tipsb.com",
    "tittbit.in",
    "tiv.cc",
    "tizi.com",
    "tkitc.de",
    "tlpn.org",
    "tmail.com",
    "tmail.ws",
    "tmailinator.com",
    "tmails.net",
    "tmpbox.net",
    "tmpemails.com",
    "tmpeml.com",
    "tmpeml.info",
    "tmpjr.me",
    "tmpmail.net",
    "tmpmail.org",
    "tmpnator.live",
    "toddsbighug.com",
    "tofeat.com",
    "toiea.com",
    "tokem.co",
    "tokenmail.de",
    "tonaeto.com",
    "tonne.to",
    "tonymanso.com",
    "toomail.biz",
    "toon.ml",
    "top-shop-tovar.ru",
    "top101.de",
    "top1mail.ru",
    "top1post.ru",
    "topinrock.cf",
    "topmail2.com",
    "topmail2.net",
    "topofertasdehoy.com",
    "topranklist.de",
    "toprumours.com",
    "tormail.org",
    "toss.pw",
    "tosunkaya.com",
    "totallynotfake.net",
    "totalvista.com",
    "totesmail.com",
    "totoan.info",
    "tourcc.com",
    "tp-qa-mail.com",
    "tqoai.com",
    "tqosi.com",
    "tradermail.info",
    "tranceversal.com",
    "trash-amil.com",
    "trash-mail.at",
    "trash-mail.cf",
    "trash-mail.com",
    "trash-mail.de",
    "trash-mail.ga",
    "trash-mail.gq",
    "trash-mail.ml",
    "trash-mail.tk",
    "trash-me.com",
    "trash2009.com",
    "trash2010.com",
    "trash2011.com",
    "trashcanmail.com",
    "trashdevil.com",
    "trashdevil.de",
    "trashemail.de",
    "trashemails.de",
    "trashinbox.com",
    "trashmail.at",
    "trashmail.com",
    "trashmail.de",
    "trashmail.gq",
    "trashmail.io",
    "trashmail.me",
    "trashmail.net",
    "trashmail.org",
    "trashmail.ws",
    "trashmailer.com",
    "trashmailgenerator.de",
    "trashmails.com",
    "trashymail.com",
    "trashymail.net",
    "trasz.com",
    "trayna.com",
    "trbvm.com",
    "trbvn.com",
    "trbvo.com",
    "trend-maker.ru",
    "trgfu.com",
    "trgovinanaveliko.info",
    "trialmail.de",
    "trickmail.net",
    "trillianpro.com",
    "trixtrux1.ru",
    "trollproject.com",
    "tropicalbass.info",
    "trungtamtoeic.com",
    "truthfinderlogin.com",
    "tryalert.com",
    "tryninja.io",
    "tryzoe.com",
    "ttirv.org",
    "ttszuo.xyz",
    "tualias.com",
    "tuofs.com",
    "turoid.com",
    "turual.com",
    "turuma.com",
    "tvchd.com",
    "tverya.com",
    "twinmail.de",
    "twkly.ml",
    "twocowmail.net",
    "twoweirdtricks.com",
    "twzhhq.online",
    "txen.de",
    "txtadvertise.com",
    "tyhe.ro",
    "tyldd.com",
    "tympe.net",
    "uacro.com",
    "uber-mail.com",
    "ubismail.net",
    "ubm.md",
    "ucche.us",
    "ucupdong.ml",
    "uemail99.com",
    "ufacturing.com",
    "uggsrock.com",
    "uguuchantele.com",
    "uhe2.com",
    "uhhu.ru",
    "uiu.us",
    "ujijima1129.gq",
    "uk.to",
    "ultra.fyi",
    "ultrada.ru",
    "uma3.be",
    "umail.net",
    "undo.it",
    "unicodeworld.com",
    "unids.com",
    "unimark.org",
    "unit7lahaina.com",
    "unmail.ru",
    "uooos.com",
    "upliftnow.com",
    "uplipht.com",
    "uploadnolimit.com",
    "upozowac.info",
    "urfunktion.se",
    "urhen.com",
    "uroid.com",
    "us.af",
    "us.to",
    "usa.cc",
    "usako.net",
    "usbc.be",
    "used-product.fr",
    "ushijima1129.cf",
    "ushijima1129.ga",
    "ushijima1129.gq",
    "ushijima1129.ml",
    "ushijima1129.tk",
    "utiket.us",
    "uu.gl",
    "uu2.ovh",
    "uuf.me",
    "uwork4.us",
    "uyhip.com",
    "vaasfc4.tk",
    "vaati.org",
    "valemail.net",
    "valhalladev.com",
    "vankin.de",
    "vctel.com",
    "vda.ro",
    "vddaz.com",
    "vdig.com",
    "veanlo.com",
    "vemomail.win",
    "venompen.com",
    "veo.kr",
    "ver0.cf",
    "ver0.ga",
    "ver0.gq",
    "ver0.ml",
    "ver0.tk",
    "vercelli.cf",
    "vercelli.ga",
    "vercelli.gq",
    "vercelli.ml",
    "verdejo.com",
    "vermutlich.net",
    "veryday.ch",
    "veryday.eu",
    "veryday.info",
    "veryrealemail.com",
    "vesa.pw",
    "vevs.de",
    "vfemail.net",
    "via.tokyo.jp",
    "vickaentb.tk",
    "victime.ninja",
    "victoriantwins.com",
    "vidchart.com",
    "viditag.com",
    "viewcastmedia.com",
    "viewcastmedia.net",
    "viewcastmedia.org",
    "vikingsonly.com",
    "vinernet.com",
    "vintomaper.com",
    "vipepe.com",
    "vipmail.name",
    "vipmail.pw",
    "vipxm.net",
    "viralplays.com",
    "virtualemail.info",
    "virustoaster.com",
    "visal007.tk",
    "visal168.cf",
    "visal168.ga",
    "visal168.gq",
    "visal168.ml",
    "visal168.tk",
    "vixletdev.com",
    "vixtricks.com",
    "vkcode.ru",
    "vmailing.info",
    "vmani.com",
    "vmpanda.com",
    "vnedu.me",
    "voidbay.com",
    "volaj.com",
    "voltaer.com",
    "vomoto.com",
    "vorga.org",
    "votiputox.org",
    "voxelcore.com",
    "vpn.st",
    "vps30.com",
    "vps911.net",
    "vradportal.com",
    "vremonte24-store.ru",
    "vrmtr.com",
    "vsimcard.com",
    "vssms.com",
    "vtxmail.us",
    "vubby.com",
    "vuiy.pw",
    "vusra.com",
    "vztc.com",
    "w-asertun.ru",
    "w3internet.co.uk",
    "wakingupesther.com",
    "walala.org",
    "walkmail.net",
    "walkmail.ru",
    "wallm.com",
    "wanko.be",
    "watch-harry-potter.com",
    "watchever.biz",
    "watchfull.net",
    "watchironman3onlinefreefullmovie.com",
    "wazabi.club",
    "wbdev.tech",
    "wbml.net",
    "web-contact.info",
    "web-ideal.fr",
    "web-inc.net",
    "web-mail.pp.ua",
    "web2mailco.com",
    "webcontact-france.eu",
    "webemail.me",
    "webhook.site",
    "webm4il.info",
    "webmail24.top",
    "webtrip.ch",
    "webuser.in",
    "wee.my",
    "wef.gr",
    "weg-werf-email.de",
    "wegwerf-email-addressen.de",
    "wegwerf-email-adressen.de",
    "wegwerf-email.at",
    "wegwerf-email.de",
    "wegwerf-email.net",
    "wegwerf-emails.de",
    "wegwerfadresse.de",
    "wegwerfemail.com",
    "wegwerfemail.de",
    "wegwerfemail.info",
    "wegwerfemail.net",
    "wegwerfemail.org",
    "wegwerfemailadresse.com",
    "wegwerfmail.de",
    "wegwerfmail.info",
    "wegwerfmail.net",
    "wegwerfmail.org",
    "wegwerpmailadres.nl",
    "wegwrfmail.de",
    "wegwrfmail.net",
    "wegwrfmail.org",
    "wekawa.com",
    "welikecookies.com",
    "wellsfargocomcardholders.com",
    "wemel.top",
    "wetrainbayarea.com",
    "wetrainbayarea.org",
    "wfgdfhj.tk",
    "wg0.com",
    "wh4f.org",
    "whatiaas.com",
    "whatifanalytics.com",
    "whatpaas.com",
    "whatsaas.com",
    "whiffles.org",
    "whopy.com",
    "whyspam.me",
    "wibblesmith.com",
    "wickmail.net",
    "widaryanto.info",
    "widget.gg",
    "wierie.tk",
    "wifimaple.com",
    "wifioak.com",
    "wikidocuslava.ru",
    "wilemail.com",
    "willhackforfood.biz",
    "willselfdestruct.com",
    "wimsg.com",
    "winemaven.info",
    "wins.com.br",
    "wlist.ro",
    "wmail.cf",
    "wmail.club",
    "wokcy.com",
    "wolfmail.ml",
    "wolfsmail.tk",
    "wollan.info",
    "worldspace.link",
    "wpdork.com",
    "wpg.im",
    "wralawfirm.com",
    "writeme.us",
    "wronghead.com",
    "ws.gy",
    "wsym.de",
    "wudet.men",
    "wuespdj.xyz",
    "wupics.com",
    "wuzup.net",
    "wuzupmail.net",
    "wwjmp.com",
    "wwwnew.eu",
    "wxnw.net",
    "x24.com",
    "xagloo.co",
    "xagloo.com",
    "xbaby69.top",
    "xcode.ro",
    "xcodes.net",
    "xcompress.com",
    "xcoxc.com",
    "xcpy.com",
    "xemaps.com",
    "xemne.com",
    "xents.com",
    "xeoty.com",
    "xjoi.com",
    "xkx.me",
    "xl.cx",
    "xmail.com",
    "xmailer.be",
    "xmaily.com",
    "xn--9kq967o.com",
    "xn--d-bga.net",
    "xojxe.com",
    "xost.us",
    "xoxox.cc",
    "xperiae5.com",
    "xrap.de",
    "xrho.com",
    "xvx.us",
    "xwaretech.com",
    "xwaretech.info",
    "xwaretech.net",
    "xww.ro",
    "xxhamsterxx.ga",
    "xxi2.com",
    "xxlocanto.us",
    "xxolocanto.us",
    "xxqx3802.com",
    "xy9ce.tk",
    "xyzfree.net",
    "xzsok.com",
    "yabai-oppai.tk",
    "yahmail.top",
    "yahooproduct.net",
    "yamail.win",
    "yanet.me",
    "yannmail.win",
    "yapped.net",
    "yaqp.com",
    "yarnpedia.ga",
    "ycare.de",
    "ycn.ro",
    "ye.vc",
    "yedi.org",
    "yeezus.ru",
    "yep.it",
    "yermail.net",
    "yhg.biz",
    "ynmrealty.com",
    "yodx.ro",
    "yogamaven.com",
    "yoggm.com",
    "yomail.info",
    "yoo.ro",
    "yopmail.com",
    "yopmail.fr",
    "yopmail.gq",
    "yopmail.net",
    "yopmail.pp.ua",
    "yordanmail.cf",
    "you-spam.com",
    "yougotgoated.com",
    "youmail.ga",
    "youmailr.com",
    "youneedmore.info",
    "youpymail.com",
    "yourdomain.com",
    "youremail.cf",
    "yourewronghereswhy.com",
    "yourlms.biz",
    "yourspamgoesto.space",
    "yourtube.ml",
    "yroid.com",
    "yspend.com",
    "ytpayy.com",
    "yugasandrika.com",
    "yui.it",
    "yuoia.com",
    "yuurok.com",
    "yxzx.net",
    "yyolf.net",
    "z-o-e-v-a.ru",
    "z0d.eu",
    "z1p.biz",
    "z86.ru",
    "zain.site",
    "zainmax.net",
    "zaktouni.fr",
    "zarabotokdoma11.ru",
    "zasod.com",
    "zaym-zaym.ru",
    "zcrcd.com",
    "zdenka.net",
    "ze.tc",
    "zebins.com",
    "zebins.eu",
    "zehnminuten.de",
    "zehnminutenmail.de",
    "zepp.dk",
    "zetmail.com",
    "zfymail.com",
    "zhaoqian.ninja",
    "zhaoyuanedu.cn",
    "zhcne.com",
    "zhewei88.com",
    "zhorachu.com",
    "zik.dj",
    "zipcad.com",
    "zipo1.gq",
    "zippymail.info",
    "zipsendtest.com",
    "zoaxe.com",
    "zoemail.com",
    "zoemail.net",
    "zoemail.org",
    "zoetropes.org",
    "zombie-hive.com",
    "zomg.info",
    "zsero.com",
    "zumpul.com",
    "zv68.com",
    "zxcv.com",
    "zxcvbnm.com",
    "zymuying.com",
    "zzi.us",
    "zzrgg.com",
    "zzz.com"
]
