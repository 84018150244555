import { cssVar } from "nvd-js-helpers/misc";

export const newField = {
    "name": "new_field",
    "type": "integer",
    "size": "",
    "default_value": "",
    "allow_null": false,
    "unique": false,
    "fk": false,
    "auto_increment": false,
    "pk": false,
    "order": "",
    "p_id": "new",
    "resource": 'field',
    "fk_table": '',
    "fk_field": '',
    "rel_type": '',
    "color": '',
    "parent_resource": 'table',
    "checked": true,
    "idx": '',
    "length": '',
}

export function newTable(table = {}) {
    return {
        p_id: "new",
        name: "Untitled",
        comment: "",
        color: cssVar('--primary'),
        fields: [],
        ...table,
        resource: "table",
        checked: true
    }
}

export const TableHelper = {
    nameToId(name, jsp) {
        return jsp.getContainer().id + '-' + name.toLowerCase().replace(/[^a-zA-Z\d]/g, '_')
    },
    fieldElementId(tableName, fieldName, jsp) {
        return `${this.nameToId(tableName, jsp)}-${this.nameToId(fieldName, jsp)}`
    },
}
