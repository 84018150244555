export class SchemaToDbml {
    tab = null
    content = '\n'

    constructor(tab) {
        this.tab = tab
    }

    async convert() {
        const settings = ((await import('../../stores/settings.store')).useSettingsStore)()
        const app = ((await import('../../stores/app.store')).useAppStore)()
        app.generatingDbmlModal = true
        for (const table of this.tab.schema.schema_data.tables) {
            if (settings.settings.allowTableHexCode)
                this.content += `${table.name} [color: ${table.color}] {\n`
            else
                this.content += `${table.name} {\n`

            for (const field of table.fields) {
                this.content += await this.getFiledMl(field)
            }

            this.content += `}\n\n`
        }

        this.tab.dbml = this.content
        app.generatingDbmlModal = false
        return this.content
    }

    async getFiledMl(field) {
        let content = `\t${field.name}`
        if (field.type) content += ` ${field.type}`
        if (field.size) content += `(${field.size})`

        if (field.pk) content += ` pk`
        if (field.allow_null) content += ` null`
        if (field.auto_increment) content += ` increments`
        if (field.unique) content += ` unique`
        if (field.default_value) content += ` def(${field.default_value})`
        if (field.fk) {
            let rel
            if (!field.rel_type || field.rel_type === 'one_to_one') {
                rel = '>'
            } else if (field.rel_type === 'one_to_many')
                rel = '>*'
            else if (field.rel_type === 'many_to_one')
                rel = '*>'
            else if (field.rel_type === 'many_to_many')
                rel = '*>*'
            content += ` ${rel} ${field.fk_table}.${field.fk_field}`
        }
        const settings = ((await import('../../stores/settings.store')).useSettingsStore)()
        if (settings.settings.allowFieldHexCode)
            if (field.color) content += ` ${field.color}`

        content += `\n`
        return content
    }
}
