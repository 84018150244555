<template>
    <div class="schema-list">
        <SchemaCard
            v-for="schema in schemas"
            :key="schemas.id"
            :schema="schema"
            @click="$emit('selected', schema)"
        />

        <div class="text-muted" v-if="!schemas?.length">{{ $t('dialog.nothing_here') }}</div>
    </div>
</template>

<script>
import SchemaCard from "./SchemaCard.vue"

export default {
    name: "SchemaList",
    components: { SchemaCard },
    props: {
        schemas: Array,
    },
    computed: {},
    data: () => ({}),
    methods: {},
}
</script>

<style scoped lang="scss">
.schema-list {
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr 1fr;
}
</style>
