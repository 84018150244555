import {defineStore} from "pinia";
import {FetchRequest} from "@/helpers/fetch-request";
import {useAuthStore} from "@/stores/auth.store";
import {useNotify} from "nvd-u/composables/Notifiy";
import router from "../router/routes";

export const MONTHLY = 'Billed monthly'
export const ANNUALLY = 'Billed annually'

export const useBillingStore = defineStore('billing', {
    state: () => ({
        billingInfoReq: new FetchRequest('billing_info'),
        stripeProducts: new FetchRequest('get_products'),
        verifyCouponReq: new FetchRequest('check_coupon', 'POST'),
        changePlanReq: new FetchRequest('upgrade_subscription', 'POST'),
        checkoutReq: new FetchRequest('create_checkout', 'POST'),
        cancelSubscriptionReq: new FetchRequest('cancel_subscription', 'POST'),
        updateInvoiceReq: new FetchRequest('/update_invoice', 'POST'),
        cancelSubscriptionModal: false,
        updateInvoiceModal: false,
        viewInvoiceModal: false,
        choices: [MONTHLY, ANNUALLY],
        choice: ANNUALLY,
        invoice: {},
        selectedPlan: null,
        coupon: ''
    }),
    getters: {
        invoices() {
            return this.billingInfoReq?.data?.invoices || []
        },
        subscriptionPlans() {
            if (!this.stripeProducts.data?.products) return []
            let products = this.stripeProducts.data?.products
            let plans = []
            products.forEach(product => {
                product.prices?.forEach(price => plans.push(price))
            })
            return plans
        },
        currentPlan() {
            return this.subscriptionPlans.find(p => p.stripe_id === this.billingInfoReq.data?.plan_id)
        },
        currentPlanTitle() {
            return this.currentPlan?.name || this.billingInfoReq?.data?.subscription || this.billingInfoReq?.data?.status?.toUpperCase()
        },
        currentPlanStatus() {
            return this.billingInfoReq.data?.status
        },
        hasFreeAccount() {
            return ['trialing', 'free'].includes(this.billingInfoReq.data?.status?.toLowerCase())
        },
        hasActiveSub() {
            return !this.hasFreeAccount && this.billingInfoReq?.data?.status?.toLowerCase() === 'active'
        },
        getActionTitle: (store: any) => (plan) => {
            if (store.currentPlan?.stripe_id === plan?.stripe_id) return 'plan_subscribed'
            if (store.hasFreeAccount || store.currentPlan?.price <= plan?.price) return 'plan_upgrade'
            else if (store.currentPlan?.price > plan?.price || plan?.name === 'Free') return 'plan_downgrade'
            return 'plan_continue'
        },
        modifyPaymentInfoOrCancelSubscriptionEligible() {
            const auth = useAuthStore()
            return auth.user['is_pro?'] && this.billingInfoReq.data?.payment_gateway?.toLowerCase() === 'stripe' && this.billingInfoReq.data?.subscription_id
        },
        isTeamPlan() {
            const auth = useAuthStore()
            return this.currentPlan?.name?.toLowerCase()?.includes('team') || auth.user.admin || (auth.user.membersLimit && auth.user.membersLimit > 1)
        },
        userAlreadySubscribed() {
            const auth = useAuthStore()
            return auth.user['is_pro?'] && (this.billingInfoReq.data?.subscription_id && this.billingInfoReq.data?.payment_gateway !== 'manual') &&
                !['trialing', 'canceled', 'free'].includes(this.billingInfoReq.data?.status?.toLowerCase()) && this.billingInfoReq.data?.plan_id !== 'trial'
        },
        isPartOfTeam() {
            return this.billingInfoReq.data?.payment_gateway?.toLowerCase() === 'team'
        },
        teamOwner(){
            return this.billingInfoReq.data?.owner
        }
    },
    actions: {
        loadBillingInfo() {
            this.billingInfoReq.send()
        },
        updatePaymentInfo() {
            window.location.href = this.billingInfoReq.data?.payment_url
        },
        cancelSubscription(payload) {
            const notify = useNotify()
            let data = {
                subscription: this.billingInfoReq.data?.subscription_id
            }

            payload.forEach(q => {
                data[q['Q']] = {
                    'answer': q['A'],
                    'more_info': q.more_info
                }
            })

            this.cancelSubscriptionReq.send({
                body: JSON.stringify(data)
            })
                .then(res => {
                    notify.success('Your subscription was cancelled successfully.')
                    this.loadBillingInfo()
                })
                .catch(error => {
                    this.error('Cancel subscription failed. Reason: ' + error)
                })
                .finally(() => {
                    this.cancelSubscriptionModal = false
                })
        },
        updateInvoice(invoice) {
            const notify = useNotify()
            this.updateInvoiceReq.config.body = JSON.stringify(invoice)
            this.updateInvoiceReq.send()
                .then(() => {
                    notify.success('Invoice updated successfully')
                    this.loadBillingInfo()
                })
                .finally(() => {
                    this.updateInvoiceModal = false
                })
        },
        changePlan() {
            const notify = useNotify()
            const auth = useAuthStore()
            // if selected plan is free and user is downgrading, cancel the subscription
            if (this.selectedPlan.name === 'Free') {
                this.selectedPlan = null
                return this.cancelSubscriptionModal = true
            }
            // if user is not already subscribed, proceed to checkout
            else if (this.userAlreadySubscribed) {
                let data = {
                    subscription: this.billingInfoReq.data?.subscription_id,
                    coupon: this.coupon,
                    plan: this.selectedPlan.stripe_id
                }
                if (data.hasOwnProperty('coupon') && !data.coupon) delete data['coupon']
                this.changePlanReq.send({
                    body: JSON.stringify(data)
                })
                    .then(() => {
                        notify.success('Plan changed successfully.')
                        auth.reFetchUser()
                        router.replace('/account/billing')
                    })
            }
            // if user is already subscribed, simply upgrade or downgrade
            else {
                let data = {
                    mode: 'subscription',
                    line_items: JSON.stringify([{
                        price: this.selectedPlan.stripe_id,
                        quantity: 1
                    }]),
                    success_url: `${window.location.origin}/account/billing?payment=success`,
                    cancel_url: `${window.location.origin}/account/billing?payment=fail`,
                    customer_email: auth.user.email,
                    customer: auth.user.stripe_customer,
                    coupon: this.coupon
                }
                if (data.hasOwnProperty('coupon') && !data.coupon) delete data['coupon']
                this.checkoutReq.send({
                    body: JSON.stringify(data)
                })
                    .then(res => {
                        window.location.href = res.session_url
                    })
            }
        }
    }
})