<script lang="ts" setup>
import AccountMultipleIcon from 'nvd-u/icons/AccountBoxMultiple.vue'
import AccountMultipleOutlineIcon from 'nvd-u/icons/AccountBoxMultipleOutline.vue'
import AccountIcon from 'nvd-u/icons/AccountCircle.vue'
import AccountOutlineIcon from 'nvd-u/icons/AccountCircleOutline.vue'
import CreditCard from 'nvd-u/icons/CreditCard.vue'
import CreditCardClock from 'nvd-u/icons/CreditCardClock.vue'
import CreditCardClockOutline from 'nvd-u/icons/CreditCardClockOutline.vue'
import CreditCardOutline from 'nvd-u/icons/CreditCardOutline.vue'
import CreditCardPlus from 'nvd-u/icons/CreditCardPlus.vue'
import CreditCardPlusOutline from 'nvd-u/icons/CreditCardPlusOutline.vue'
import SitemapFinderIcon from 'nvd-u/icons/FeatureSearch.vue'
import FeatureSearchOutlineIcon from 'nvd-u/icons/FeatureSearchOutline.vue'
import TextBoxMultipleIcon from 'nvd-u/icons/TextBoxMultiple.vue'
import TextBoxMultipleOutlineIcon from 'nvd-u/icons/TextBoxMultipleOutline.vue'
import DownloadIcon from "nvd-u/icons/Download.vue";
import DownloadOutlineIcon from "nvd-u/icons/DownloadOutline.vue"
import {computed} from 'vue'
import {useRoute} from 'vue-router'

let props = defineProps<{
    name: string,
}>()

const icons = {
    dashboard: [TextBoxMultipleOutlineIcon, TextBoxMultipleIcon],
    team: [AccountMultipleOutlineIcon, AccountMultipleIcon],
    account: [AccountOutlineIcon, AccountIcon],
    billing: [CreditCardOutline, CreditCard],
    subscription: [CreditCardOutline, CreditCard],
    history: [CreditCardClockOutline, CreditCardClock],
    paymentMethods: [CreditCardPlusOutline, CreditCardPlus],
    sitemapFinder: [FeatureSearchOutlineIcon, SitemapFinderIcon],
    downloads: [DownloadOutlineIcon, DownloadIcon],
}
const route = useRoute()
const comp = computed(() => route.name === props.name || route.matched[0].name === props.name ? 1 : 0)
</script>

<template>
    <component :is="icons[props.name][comp]"/>
</template>
