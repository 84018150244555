import { fontSize } from "@/modules/canvas/styles"
import { CanvasItem } from "@/modules/canvas/table/CanvasItem.js"
import { TableComments } from "@/modules/canvas/table/TableComments.js"
import { TableHeader } from "@/modules/canvas/table/TableHeader.js"
import { TableRow } from "@/modules/canvas/table/TableRow.js"
import { useSettingsStore } from "../../../stores/settings.store";
import { LogicalConnection } from "./LogicalConnection";
import { useMenuStore } from "../../../stores/menu.store";
import { useTabsStore } from "../../../stores/tabs.store";

export class Table extends CanvasItem {
    /** @type TableHeader */
    header
    rows = []
    logicalConnections = []
    comment


    constructor(canvas, data) {
        super(canvas, data)
        this.name = data.name

        this.addHeader()

        const tab = useTabsStore()

        if (tab.selectedTab.settings.view === 'simple_view') return
        if (tab.selectedTab.settings.view === 'physical_relationships_view') {
            let idx = 0
            data.fields.forEach((field) => {
                if (field.checked !== false && ((field.pk && this.isForeignKeyForSomeOtherTable(field.name, data.name)) || (field.fk && field.fk_table))) {
                    this.addRow(field, idx)
                    idx++
                }
            })
            return
        }
        if (tab.selectedTab.settings.view === 'detailed_view') {
            let idx = 0
            data.fields.forEach((field) => {
                if (field.checked !== false) {
                    this.addRow(field, idx)
                    idx++
                }
            })
            this.addComments()
        }
        if (tab.selectedTab.settings.view === 'logical_relationships_view')
            return this.addLogicalRelationships()
    }

    isForeignKeyForSomeOtherTable(fk_field, fk_table) {
        const tab = useTabsStore()
        let tables = tab.selectedTab?.schema?.schema_data?.tables
        if (!tables) return false
        let fieldFound = false
        tables.forEach(table => {
            table.fields.forEach(field => {
                if (field.fk && field.fk_field === fk_field && field.fk_table === fk_table)
                    fieldFound = true
            })
        })
        return fieldFound
    }

    addRow(field, idx) {
        let row = new TableRow(this, field, idx)
        this.rows.push(row)
        this.height += row.height()
        this.width = Math.max(this.ctx?.measureText(field.name).width + this.header.paddingX * 6 + this.ctx?.measureText(field.type).width, this.width)
    }

    addHeader() {
        this.header = new TableHeader(this)
        this.height += this.header.height()
        let name = this.ctx?.measureText(this.name)
        this.width = Math.max(name?.width + this.header.paddingX * 3, this.width)
    }

    addComments() {
        if (this.data.comment) {
            this.comment = new TableComments(this, this.data.comment)
            const h = this.isHoveredObject ? this.comment.height() : this.comment.paddingY * 2 + fontSize
            this.height += h
        }
    }

    addLogicalRelationships() {
        if (!this.data.logical_relationships) return
        this.data.logical_relationships.forEach(relation => {
            let connection = new LogicalConnection(this.canvas, this, relation.fk_table)
            this.logicalConnections.push(connection)
        })
    }

    draw() {
        this.update()
        if (this.isOutOfScreen()) return

        this.ctx.fillStyle = '#fff'
        this.ctx?.fillRect(this.left, this.top, this.width, this.height)

        this.header.draw()
        this.rows.forEach(row => row.draw())

        if (this.comment) this.comment.draw()

        this.drawSelectedState()
        this.drawLogicalState()
    }
}
