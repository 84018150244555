import i18n from "@/locales/translations";

export const TOKEN_KEY = 'oauth'
export const USER_KEY = 'user'
export const TEAM_MEMBERS_KEY = 'team_members'
export const TABS_KEY = 'tabs'
export const SETTINGS_KEY = 'settings'
export const CANVAS_SETTINGS_KEY = 'canvas_settings'
export const COPIED_OBJECT_KEY = 'copied_object'
export const LINE_TYPES = {
    BEZIER: 'Bezier',
    STRAIGHT: 'Straight',
    FLOWCHART: 'Flowchart',
}

export const DEFAULT_LANG = "en"

export const LANGUAGES = [
    {title: "English", value: "en"},
    {title: "Bengali", value: "bn"},
    {title: "Catalan", value: "ca"},
    {title: "Chinese", value: "zh"},
    {title: "Czech", value: "cs"},
    {title: "Danish", value: "da"},
    {title: "Esperanto", value: "eo"},
    {title: "German", value: "de"},
    {title: "Finnish", value: "fi"},
    {title: "French", value: "fr"},
    {title: "Greek", value: "gr"},
    {title: "Japanese", value: "ja"},
    {title: "Indonesian", value: "id"},
    {title: "Italian", value: "it"},
    {title: "Kannada", value: "kn"},
    {title: "Norwegian Bokmål", value: "nb_no"},
    {title: "Dutch", value: "nl"},
    {title: "Polish", value: "pl"},
    {title: "Persian", value: "fa"},
    {title: "Brazilian Portuguese", value: "pt_br"},
    {title: "Romanian", value: "ro"},
    {title: "Russian", value: "ru"},
    {title: "Spanish", value: "es"},
    {title: "Turkish", value: "tr"},
    {title: "Ukrainian", value: "ua"},
    {title: "Uzbek", value: "uz"},
    {title: "Vietnamese", value: "vi"},
]

export const LoginPageStates = {
    login: 'login',
    forgotPassword: 'forgotPassword',
    register: 'register',
}

export const FRAME_RATE = 60


export const CRITICAL_TABLE_LIMIT = 700
export const CRITICAL_FIELD_LIMIT = 1500


export const access_modes = [
    {title: 'ReadOnly', value: 0},
    {title: 'Read & Write', value: 1},
]


const ace_light = {
    // 'chrome': 'Chrome',
    // 'clouds': 'Clouds',
    // 'crimson_editor': 'Crimson Editor',
    // 'dawn': 'Dawn',
    // 'dreamweaver': 'Dreamweaver',
    // 'eclipse': 'Eclipse',
    // 'github': 'GitHub',
    // 'iplastic': 'IPlastic',
    // 'katzenmilch': 'KatzenMilch',
    // 'kuroir': 'Kuroir',
    // 'solarized_light': 'Solarized Light',
    // 'sqlserver': 'SQL Server',
    // 'textmate': 'TextMate',
    // 'tomorrow': 'Tomorrow',
    'xcode': 'XCode'
};

const ace_dark = {
    // 'ambiance': 'Ambiance',
    'chaos': 'Chaos',
    // 'clouds_midnight': 'Clouds Midnight',
    // 'cobalt': 'Cobalt',
    'dracula': 'Dracula',
    // 'gob': 'Greeon on Black',
    // 'gruvbox': 'Gruvbox',
    // 'idle_fingers': 'idle Fingers',
    // 'kr_theme': 'krTheme',
    // 'merbivore': 'Merbivore',
    // 'merbivore_soft': 'Merbivore Soft',
    // 'mono_industrial': 'Mono Industrial',
    'monokai': 'Monokai',
    // 'pastel_on_dark': 'Pastel on Dark',
    // 'solarized_dark': 'Solarized Dark',
    // 'terminal': 'Terminal',
    // 'tomorrow_night': 'Tomorrow Night',
    // 'tomorrow_night_blue': 'Tomorrow Night Blue',
    // 'tomorrow_night_bright': 'Tomorrow Night Bright',
    // 'tomorrow_night_eighties': 'Tomorrow Night 80s',
    // 'twilight': 'Twilight',
    // 'vibrant_ink': 'Vibrant Ink'
};

export const markupThemes = [
    ...Object.entries(ace_light).map(([key, value]) => ({key, value})),
    ...Object.entries(ace_dark).map(([key, value]) => ({key, value}))
]

export const DEFAULT_THEME = markupThemes[0]

export const THEME_KEY = 'appTheme'


export const data_types = {
    "ai": [
        "mongodb", "bigquery", "redshift", "databricks",
    ],
    "default": [
        "snowflake", "oracle", "postgresql", "sqlite", "mssql", "mysql",
    ],
    "exported": [
        "mongodb", "bigquery", "redshift", "databricks", "snowflake", "oracle", "postgresql", "sqlite", "mssql", "mysql",
    ]
}