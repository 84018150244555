<script lang="ts" setup>

</script>

<style scoped lang="scss">
.torso {
    animation: bounce2 2.5s ease-out infinite alternate;
}

.leaves, .leaves_2 {
    animation: rotate 2.5s ease-in-out infinite alternate;
}

.leaves_2 {
    transform-origin: 80% 90%;
}

.leaves {
    transform-origin: 15% 85%;
}
</style>

<template>
    <svg width="818" height="619" viewBox="0 0 818 619" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="undraw_meditation_re_gll0 1" clip-path="url(#clip0_714_1023)">
            <path class="Path 164"
                  d="M384.403 518.936C384.403 518.936 394.814 501.855 408.181 511.955C421.547 522.054 446.301 539.094 446.301 539.094C446.301 539.094 451.371 549.993 438.249 545.899C425.126 541.805 382.289 535.217 382.289 535.217L384.403 518.936Z"
                  fill="#FEB8B8"/>
            <g class="leaves">
                <path class="Vector"
                      d="M93.453 410.461C96.7707 383.783 113.304 357.497 138.747 348.815C128.713 376.274 128.715 406.398 138.753 433.856C142.663 444.431 148.112 455.786 144.435 466.444C142.147 473.076 136.549 478.15 130.293 481.323C124.035 484.495 117.091 486.008 110.234 487.49L108.884 488.606C97.9982 464.025 90.1353 437.139 93.453 410.461Z"
                      fill="#F0F0F0"/>
                <path class="Vector_2"
                      d="M139 349.32C124.183 365.921 114.974 386.769 112.681 408.903C112.102 413.659 112.277 418.477 113.199 423.178C114.245 427.729 116.491 431.917 119.703 435.306C122.634 438.527 126.005 441.482 128.103 445.359C129.147 447.359 129.756 449.558 129.891 451.811C130.026 454.063 129.683 456.319 128.885 458.429C127.033 463.741 123.383 468.07 119.667 472.179C115.541 476.742 111.183 481.415 109.429 487.464C109.217 488.197 108.092 487.825 108.304 487.093C111.356 476.569 121.572 470.591 126.444 461.112C128.717 456.689 129.671 451.554 127.54 446.885C125.676 442.802 122.202 439.752 119.206 436.517C116.025 433.256 113.69 429.266 112.405 424.895C111.247 420.256 110.887 415.453 111.34 410.693C112.189 399.959 114.72 389.426 118.841 379.479C123.482 368.101 130.034 357.6 138.216 348.431C138.722 347.864 139.503 348.756 139 349.32Z"
                      fill="white"/>
                <path class="Vector_3"
                      d="M113 401.733C109.338 400.844 106.112 398.682 103.898 395.634C101.683 392.586 100.623 388.85 100.909 385.094C100.969 384.334 102.153 384.392 102.093 385.153C101.82 388.651 102.807 392.131 104.876 394.965C106.944 397.799 109.957 399.8 113.371 400.607C114.113 400.784 113.737 401.908 113 401.733Z"
                      fill="white"/>
                <path class="Vector_4"
                      d="M118.001 433.869C124.614 429.921 129.496 423.625 131.673 416.237C131.888 415.505 133.013 415.878 132.798 416.609C130.518 424.292 125.425 430.834 118.534 434.927C117.877 435.317 117.348 434.257 118.001 433.869Z"
                      fill="white"/>
                <path class="Vector_5"
                      d="M124.581 369.223C125.926 369.862 127.409 370.157 128.896 370.082C130.383 370.006 131.828 369.563 133.101 368.791C133.753 368.393 134.282 369.454 133.634 369.849C132.223 370.695 130.627 371.183 128.984 371.27C127.341 371.357 125.702 371.041 124.209 370.348C124.063 370.295 123.943 370.188 123.873 370.049C123.803 369.91 123.789 369.749 123.833 369.6C123.883 369.451 123.989 369.328 124.129 369.258C124.269 369.187 124.432 369.175 124.581 369.223Z"
                      fill="white"/>
                <path class="Vector_6"
                      d="M216.301 396.465C215.901 396.725 215.501 396.985 215.101 397.255C209.742 400.779 204.679 404.732 199.961 409.075C199.591 409.405 199.221 409.745 198.861 410.085C187.605 420.671 178.413 433.254 171.751 447.195C169.105 452.745 166.885 458.489 165.111 464.375C162.661 472.505 160.651 481.515 155.801 488.165C155.303 488.865 154.762 489.533 154.181 490.165H110.351C110.251 490.115 110.151 490.075 110.051 490.025L108.301 490.105C108.371 489.795 108.451 489.475 108.521 489.165C108.561 488.985 108.611 488.805 108.651 488.625C108.681 488.505 108.711 488.385 108.731 488.275C108.741 488.235 108.751 488.195 108.761 488.165C108.781 488.055 108.811 487.955 108.831 487.855C109.271 486.065 109.724 484.275 110.191 482.485C110.191 482.475 110.191 482.475 110.201 482.465C113.791 468.835 118.551 455.385 125.201 443.085C125.401 442.715 125.601 442.335 125.821 441.965C128.849 436.438 132.324 431.167 136.211 426.205C138.348 423.495 140.621 420.895 143.021 418.415C149.235 412.019 156.416 406.64 164.301 402.475C180.021 394.175 198.221 390.995 215.021 396.065C215.451 396.195 215.871 396.325 216.301 396.465Z"
                      fill="#F0F0F0"/>
                <path class="Vector_7"
                      d="M216.202 397.022C194.377 401.357 174.472 412.458 159.315 428.75C155.989 432.199 153.229 436.151 151.134 440.46C149.229 444.723 148.501 449.419 149.025 454.059C149.426 458.396 150.339 462.785 149.679 467.143C149.308 469.369 148.471 471.491 147.223 473.371C145.974 475.251 144.342 476.845 142.434 478.05C137.758 481.176 132.238 482.435 126.796 483.479C120.755 484.637 114.461 485.745 109.419 489.519C108.808 489.976 108.134 489.002 108.744 488.545C117.517 481.979 129.273 483.357 138.87 478.722C143.348 476.559 147.202 473.034 148.311 468.023C149.281 463.641 148.344 459.114 147.899 454.727C147.322 450.208 147.86 445.616 149.466 441.353C151.335 436.951 153.939 432.9 157.166 429.372C164.307 421.313 172.669 414.427 181.949 408.966C192.504 402.675 204.059 398.235 216.111 395.84C216.857 395.692 216.943 396.875 216.202 397.022Z"
                      fill="white"/>
                <path class="Vector_8"
                      d="M163.886 423.217C161.498 420.303 160.224 416.635 160.29 412.868C160.357 409.1 161.761 405.48 164.25 402.652C164.756 402.082 165.666 402.841 165.16 403.412C162.837 406.041 161.529 409.414 161.475 412.922C161.42 416.43 162.62 419.842 164.86 422.542C165.346 423.129 164.37 423.801 163.886 423.217Z"
                      fill="white"/>
                <path class="Vector_9"
                      d="M148.532 451.887C156.189 452.716 163.877 450.628 170.063 446.04C170.676 445.585 171.349 446.56 170.738 447.014C164.291 451.777 156.286 453.933 148.32 453.053C147.561 452.969 147.776 451.803 148.532 451.887Z"
                      fill="white"/>
                <path class="Vector_10"
                      d="M192.706 404.233C193.395 405.553 194.402 406.681 195.634 407.516C196.867 408.351 198.288 408.867 199.77 409.017C200.53 409.092 200.313 410.257 199.558 410.183C197.922 410.009 196.354 409.438 194.989 408.518C193.625 407.598 192.507 406.359 191.732 404.907C191.648 404.777 191.616 404.619 191.644 404.465C191.672 404.312 191.757 404.176 191.882 404.083C192.011 403.994 192.171 403.96 192.325 403.988C192.479 404.016 192.616 404.104 192.706 404.233Z"
                      fill="white"/>
            </g>
            <path class="Vector_11"
                  d="M446.945 587.276C448.231 576.937 454.639 566.749 464.499 563.385C460.611 574.027 460.611 585.701 464.502 596.342C466.017 600.441 468.129 604.842 466.704 608.972C465.683 611.526 463.721 613.59 461.223 614.738C458.744 615.864 456.131 616.667 453.449 617.128L452.926 617.561C448.707 608.035 445.66 597.615 446.945 587.276Z"
                  fill="#F0F0F0"/>
            <path class="Vector_12"
                  d="M464.597 563.58C458.855 570.014 455.286 578.094 454.397 586.672C454.173 588.515 454.241 590.382 454.598 592.204C455.004 593.968 455.874 595.591 457.119 596.905C458.358 598.067 459.451 599.375 460.374 600.8C460.779 601.576 461.015 602.428 461.067 603.301C461.119 604.174 460.987 605.048 460.677 605.866C459.855 607.864 458.641 609.676 457.105 611.195C455.506 612.963 453.817 614.774 453.137 617.119C453.055 617.403 452.619 617.258 452.701 616.975C453.884 612.896 457.843 610.579 459.731 606.906C460.218 606.08 460.509 605.154 460.583 604.198C460.656 603.242 460.51 602.283 460.156 601.392C459.3 599.89 458.21 598.533 456.926 597.374C455.693 596.11 454.788 594.564 454.291 592.87C453.842 591.072 453.702 589.21 453.878 587.366C454.207 583.206 455.188 579.124 456.785 575.269C458.583 570.859 461.123 566.789 464.293 563.236C464.49 563.016 464.792 563.362 464.597 563.58Z"
                  fill="white"/>
            <path class="Vector_13"
                  d="M454.521 583.893C453.102 583.548 451.851 582.711 450.993 581.53C450.135 580.348 449.724 578.901 449.835 577.445C449.858 577.15 450.317 577.173 450.294 577.467C450.188 578.823 450.571 580.172 451.372 581.27C452.174 582.368 453.341 583.144 454.665 583.457C454.952 583.525 454.806 583.961 454.521 583.893Z"
                  fill="white"/>
            <path class="Vector_14"
                  d="M456.459 596.348C459.022 594.817 460.914 592.377 461.758 589.514C461.841 589.231 462.277 589.375 462.194 589.658C461.31 592.636 459.336 595.171 456.666 596.758C456.411 596.909 456.206 596.498 456.459 596.348Z"
                  fill="white"/>
            <path class="Vector_15"
                  d="M459.009 571.294C459.53 571.542 460.105 571.656 460.681 571.627C461.258 571.597 461.818 571.426 462.311 571.126C462.564 570.972 462.769 571.383 462.518 571.536C461.971 571.864 461.352 572.054 460.716 572.087C460.079 572.121 459.444 571.998 458.865 571.73C458.809 571.709 458.762 571.668 458.735 571.614C458.708 571.56 458.702 571.498 458.719 571.44C458.739 571.382 458.78 571.335 458.834 571.307C458.888 571.28 458.951 571.275 459.009 571.294Z"
                  fill="white"/>
            <path class="Vector_16"
                  d="M494.555 581.852C494.4 581.953 494.245 582.053 494.09 582.158C492.014 583.523 490.051 585.055 488.223 586.739C488.079 586.867 487.936 586.998 487.797 587.13C483.434 591.233 479.872 596.109 477.29 601.512C476.265 603.663 475.404 605.889 474.717 608.17C473.767 611.321 472.988 614.813 471.109 617.39C470.915 617.661 470.706 617.92 470.481 618.165H453.494C453.456 618.146 453.417 618.131 453.378 618.111L452.7 618.142C452.727 618.022 452.758 617.898 452.785 617.778C452.801 617.708 452.82 617.638 452.835 617.569C452.847 617.522 452.859 617.476 452.866 617.433C452.87 617.417 452.874 617.402 452.878 617.39C452.886 617.348 452.897 617.309 452.905 617.27C453.076 616.576 453.251 615.883 453.432 615.189L453.436 615.181C454.763 609.882 456.715 604.759 459.249 599.919C459.327 599.776 459.404 599.629 459.49 599.485C460.663 597.343 462.01 595.3 463.516 593.378C464.345 592.327 465.225 591.32 466.156 590.359C468.564 587.88 471.347 585.795 474.403 584.181C480.495 580.964 487.548 579.732 494.059 581.697C494.226 581.747 494.389 581.798 494.555 581.852Z"
                  fill="#F0F0F0"/>
            <path class="Vector_17"
                  d="M494.517 582.067C486.059 583.747 478.344 588.05 472.47 594.364C471.182 595.7 470.112 597.232 469.3 598.902C468.562 600.554 468.279 602.374 468.482 604.172C468.772 605.846 468.857 607.549 468.736 609.243C468.592 610.106 468.268 610.928 467.784 611.657C467.3 612.385 466.668 613.003 465.928 613.47C464.069 614.57 462.008 615.286 459.868 615.574C457.526 616.023 455.087 616.452 453.133 617.915C452.896 618.092 452.635 617.714 452.872 617.537C456.271 614.993 460.828 615.527 464.547 613.731C465.433 613.364 466.223 612.8 466.857 612.081C467.491 611.363 467.952 610.508 468.206 609.584C468.427 607.869 468.373 606.129 468.046 604.431C467.822 602.68 468.031 600.9 468.654 599.248C469.378 597.542 470.387 595.972 471.638 594.605C474.405 591.481 477.646 588.813 481.242 586.696C485.333 584.258 489.811 582.538 494.482 581.609C494.771 581.552 494.804 582.01 494.517 582.067Z"
                  fill="white"/>
            <path class="Vector_18"
                  d="M474.242 592.219C473.317 591.09 472.823 589.668 472.848 588.208C472.874 586.749 473.418 585.345 474.383 584.249C474.579 584.028 474.932 584.323 474.736 584.544C473.835 585.563 473.329 586.87 473.307 588.229C473.286 589.589 473.751 590.911 474.62 591.958C474.808 592.185 474.429 592.446 474.242 592.219Z"
                  fill="white"/>
            <path class="Vector_19"
                  d="M468.291 603.331C471.259 603.652 474.238 602.843 476.636 601.065C476.873 600.888 477.134 601.266 476.897 601.442C474.399 603.288 471.297 604.124 468.209 603.783C467.915 603.75 467.999 603.298 468.291 603.331Z"
                  fill="white"/>
            <path class="Vector_20"
                  d="M485.411 584.862C485.678 585.373 486.068 585.811 486.546 586.134C487.024 586.458 487.575 586.658 488.149 586.716C488.443 586.745 488.359 587.197 488.067 587.168C487.433 587.101 486.825 586.879 486.296 586.523C485.767 586.166 485.334 585.686 485.034 585.123C485.001 585.073 484.989 585.011 485 584.952C485.01 584.893 485.043 584.84 485.092 584.804C485.142 584.769 485.204 584.756 485.264 584.767C485.323 584.778 485.376 584.812 485.411 584.862Z"
                  fill="white"/>
            <g class="leaves_2">
                <path class="Vector_21"
                      d="M738.788 367.655C733.689 326.65 708.276 286.248 669.169 272.903C684.591 315.109 684.588 361.41 669.159 403.614C663.15 419.869 654.774 437.323 660.426 453.705C663.943 463.898 672.547 471.697 682.164 476.574C691.782 481.45 702.455 483.775 712.995 486.052L715.07 487.768C731.802 449.986 743.888 408.661 738.788 367.655Z"
                      fill="#F0F0F0"/>
                <path class="Vector_22"
                      d="M668.781 273.679C691.555 299.196 705.71 331.241 709.235 365.261C709.945 372.554 710.032 380.014 708.437 387.203C706.83 394.198 703.378 400.634 698.441 405.844C693.936 410.795 688.754 415.336 685.53 421.295C682.132 427.577 682.002 434.714 684.328 441.386C687.174 449.549 692.784 456.203 698.496 462.519C704.838 469.532 711.536 476.716 714.232 486.014C714.559 487.14 716.288 486.567 715.962 485.443C711.271 469.267 695.569 460.078 688.08 445.509C684.586 438.711 683.119 430.818 686.395 423.642C689.26 417.366 694.599 412.678 699.204 407.705C704.04 402.484 707.748 396.755 709.658 389.842C711.611 382.77 711.817 375.291 711.295 368.012C709.99 351.514 706.099 335.324 699.765 320.035C692.633 302.546 682.561 286.406 669.986 272.313C669.207 271.441 668.007 272.812 668.781 273.679Z"
                      fill="white"/>
                <path class="Vector_23"
                      d="M708.744 354.24C714.372 352.874 719.331 349.551 722.735 344.867C726.139 340.182 727.767 334.44 727.328 328.665C727.236 327.497 725.416 327.586 725.509 328.756C725.927 334.132 724.41 339.481 721.231 343.837C718.053 348.193 713.421 351.269 708.173 352.511C707.033 352.782 707.611 354.509 708.744 354.24Z"
                      fill="white"/>
                <path class="Vector_24"
                      d="M701.056 403.635C690.892 397.566 683.389 387.89 680.043 376.534C679.712 375.409 677.983 375.981 678.313 377.105C681.818 388.915 689.646 398.97 700.237 405.262C701.247 405.861 702.061 404.231 701.056 403.635Z"
                      fill="white"/>
                <path class="Vector_25"
                      d="M690.943 304.271C688.876 305.254 686.597 305.707 684.311 305.591C682.025 305.475 679.804 304.793 677.847 303.607C676.845 302.995 676.033 304.626 677.028 305.233C679.196 306.534 681.65 307.284 684.176 307.418C686.701 307.552 689.22 307.065 691.514 306.001C691.739 305.919 691.924 305.754 692.031 305.54C692.139 305.327 692.161 305.08 692.093 304.851C692.017 304.622 691.853 304.433 691.637 304.324C691.422 304.216 691.173 304.197 690.943 304.271Z"
                      fill="white"/>
                <path class="Vector_26"
                      d="M549.966 346.144C550.58 346.544 551.195 346.943 551.81 347.358C560.046 352.774 567.829 358.85 575.081 365.526C575.65 366.034 576.218 366.556 576.772 367.079C594.072 383.349 608.201 402.69 618.441 424.119C622.508 432.649 625.919 441.477 628.647 450.525C632.413 463.021 635.502 476.87 642.957 487.091C643.723 488.166 644.554 489.193 645.447 490.165H712.816C712.969 490.089 713.123 490.027 713.277 489.95L715.967 490.073C715.859 489.597 715.736 489.105 715.628 488.628C715.567 488.352 715.49 488.075 715.429 487.798C715.383 487.614 715.336 487.429 715.305 487.26C715.29 487.199 715.275 487.137 715.26 487.091C715.229 486.922 715.182 486.769 715.152 486.615C714.475 483.864 713.779 481.112 713.061 478.361C713.061 478.346 713.061 478.346 713.046 478.33C707.528 457.38 700.212 436.707 689.991 417.801C689.683 417.233 689.376 416.648 689.038 416.08C684.383 407.584 679.042 399.482 673.067 391.856C669.783 387.691 666.289 383.694 662.6 379.882C653.05 370.051 642.012 361.783 629.892 355.382C605.729 342.624 577.756 337.737 551.933 345.529C551.272 345.729 550.626 345.929 549.966 346.144Z"
                      fill="#F0F0F0"/>
                <path class="Vector_27"
                      d="M550.117 346.999C583.664 353.662 614.259 370.726 637.556 395.767C642.514 401.162 647.075 407.066 650.13 413.766C653.058 420.319 654.177 427.536 653.372 434.669C652.755 441.334 651.352 448.08 652.366 454.779C653.435 461.84 657.627 467.617 663.502 471.543C670.689 476.348 679.174 478.283 687.538 479.887C696.824 481.669 706.497 483.371 714.248 489.172C715.187 489.875 716.222 488.377 715.285 487.675C701.801 477.583 683.731 479.701 668.98 472.577C662.097 469.252 656.174 463.833 654.469 456.131C652.978 449.396 654.419 442.438 655.102 435.695C655.819 428.615 655.33 421.808 652.693 415.138C649.996 408.316 645.656 402.221 640.858 396.722C629.883 384.336 617.029 373.752 602.766 365.358C586.542 355.688 568.782 348.864 550.257 345.183C549.11 344.956 548.978 346.773 550.117 346.999Z"
                      fill="white"/>
                <path class="Vector_28"
                      d="M630.529 387.262C634.2 382.783 636.159 377.145 636.056 371.355C635.954 365.565 633.797 360 629.97 355.653C629.192 354.776 627.793 355.943 628.572 356.821C632.143 360.862 634.152 366.046 634.236 371.438C634.321 376.83 632.475 382.075 629.032 386.225C628.285 387.128 629.786 388.16 630.529 387.262Z"
                      fill="white"/>
                <path class="Vector_29"
                      d="M654.13 431.33C642.361 432.604 630.544 429.395 621.036 422.343C620.094 421.644 619.058 423.142 619.998 423.84C629.907 431.16 642.211 434.475 654.456 433.122C655.623 432.993 655.291 431.201 654.13 431.33Z"
                      fill="white"/>
                <path class="Vector_30"
                      d="M586.231 358.083C585.172 360.112 583.626 361.846 581.731 363.129C579.836 364.413 577.652 365.206 575.374 365.437C574.206 365.552 574.539 367.343 575.7 367.229C578.214 366.962 580.626 366.083 582.722 364.67C584.819 363.256 586.538 361.351 587.729 359.12C587.858 358.919 587.907 358.676 587.864 358.441C587.821 358.205 587.691 357.995 587.498 357.853C587.3 357.716 587.055 357.664 586.818 357.707C586.58 357.75 586.37 357.885 586.231 358.083Z"
                      fill="white"/>
            </g>
            <path class="Vector_31"
                  d="M817.327 491.165H1C0.734784 491.165 0.480429 491.06 0.292892 490.873C0.105356 490.685 0 490.431 0 490.165C0 489.9 0.105356 489.646 0.292892 489.458C0.480429 489.271 0.734784 489.165 1 489.165H817C817.265 489.165 817.52 489.271 817.707 489.458C817.895 489.646 818 489.9 818 490.165C818 490.718 817.879 491.165 817.327 491.165Z"
                  fill="#CACACA"/>
            <path class="Vector_32"
                  d="M286.327 579.165H106C105.735 579.165 105.48 579.06 105.293 578.873C105.105 578.685 105 578.431 105 578.165C105 577.9 105.105 577.646 105.293 577.458C105.48 577.271 105.735 577.165 106 577.165H286C286.265 577.165 286.52 577.271 286.707 577.458C286.895 577.646 287 577.9 287 578.165C287 578.718 286.879 579.165 286.327 579.165Z"
                  fill="#CACACA"/>
            <path class="Vector_33"
                  d="M569.327 618.165H389C388.735 618.165 388.48 618.06 388.293 617.873C388.105 617.685 388 617.431 388 617.165C388 616.9 388.105 616.646 388.293 616.458C388.48 616.271 388.735 616.165 389 616.165H569C569.265 616.165 569.52 616.271 569.707 616.458C569.895 616.646 570 616.9 570 617.165C570 617.718 569.879 618.165 569.327 618.165Z"
                  fill="#CACACA"/>
            <path class="Vector_34"
                  d="M739.327 578.165H559C558.735 578.165 558.48 578.06 558.293 577.873C558.105 577.685 558 577.431 558 577.165C558 576.9 558.105 576.646 558.293 576.458C558.48 576.271 558.735 576.165 559 576.165H739C739.265 576.165 739.52 576.271 739.707 576.458C739.895 576.646 740 576.9 740 577.165C740 577.718 739.879 578.165 739.327 578.165Z"
                  fill="#CACACA"/>
            <path class="Path 161"
                  d="M333.259 540.165C322.057 540.165 311.801 539.092 303.653 536.555C293.968 533.539 287.887 528.662 285.577 522.049C284.491 519.241 284.167 516.196 284.637 513.223C285.107 510.249 286.355 507.453 288.254 505.117C302.581 486.448 352.3 481.182 358.971 480.56L372.505 454.932C373.371 448.818 369.274 444.189 376.353 444.073L460.088 442.936C467.039 442.79 465.066 447.095 466.257 453.057L479.014 475.831L424.108 499.004L432.409 519.232L431.868 519.547C415.798 528.868 369.585 540.164 333.259 540.165Z"
                  fill="#2F2E41"/>
            <path class="Path 162"
                  d="M471.413 476.803C471.413 476.803 548.093 468.028 543.092 503.129C538.092 538.229 479.748 563.092 391.399 503.129L401.401 485.578C401.401 485.578 434.323 483.941 454.327 475.165L471.413 476.803Z"
                  fill="#2F2E41"/>
            <path class="Path 163"
                  d="M450.564 510.408C450.564 510.408 441.977 492.341 427.637 501.002C413.297 509.663 386.912 524.048 386.912 524.048C386.912 524.048 380.741 534.363 394.216 531.65C407.692 528.937 450.981 526.82 450.981 526.82L450.564 510.408Z"
                  fill="#FEB8B8"/>
            <g class="torso">
                <path class="Path 166"
                      d="M362.854 317.783C362.854 317.783 348.28 315.133 344.305 337.657C340.33 360.18 333.963 413.533 333.963 413.533L314.194 482.067C311.862 483.305 309.931 485.18 308.626 487.475C307.32 489.769 306.694 492.388 306.822 495.024C306.949 497.661 307.823 500.207 309.344 502.366C310.864 504.524 312.967 506.205 315.406 507.213C317.846 508.22 320.523 508.513 323.123 508.057C325.723 507.6 328.139 506.413 330.09 504.635C332.041 502.856 333.445 500.559 334.139 498.012C334.833 495.465 334.788 492.773 334.01 490.251L360.461 417.513L365.504 354.886V322.071C365.504 321.18 365.256 320.308 364.788 319.55C364.32 318.793 363.65 318.181 362.854 317.783Z"
                      fill="#FEB8B8"/>
                <path class="Path 166_2"
                      d="M465.438 317.783C465.438 317.783 480.012 315.133 483.987 337.657C487.961 360.18 494.328 413.533 494.328 413.533L514.097 482.067C516.429 483.305 518.36 485.18 519.666 487.475C520.971 489.769 521.597 492.388 521.47 495.024C521.343 497.661 520.468 500.207 518.948 502.366C517.428 504.524 515.325 506.205 512.885 507.213C510.445 508.22 507.769 508.513 505.169 508.057C502.569 507.6 500.152 506.413 498.202 504.635C496.251 502.856 494.847 500.559 494.152 498.012C493.458 495.465 493.503 492.773 494.282 490.251L467.83 417.513L462.788 354.886V322.071C462.788 321.18 463.036 320.308 463.504 319.55C463.972 318.793 464.642 318.181 465.438 317.783Z"
                      fill="#FEB8B8"/>
                <path class="Vector_35"
                      d="M464.327 299.166L436.76 288.855L383.327 288.166L360.327 299.166L366.786 336.292C366.786 336.292 353.744 358.376 368.104 380.464L371.327 459.165C371.327 459.165 422.843 489.039 466.327 453.165L456.499 379.992C456.499 379.992 472.937 346.042 460.196 333.429L464.327 299.166Z"
                      fill="var(--primary)"/>
                <path class="Vector_36"
                      d="M453.099 310.703L464.327 299.166C464.327 299.166 488.831 312.055 488.327 355.166L457.415 345.639L453.099 310.703Z"
                      fill="var(--primary)"/>
                <path class="Vector_37"
                      d="M372.801 310.702L360.871 298.773C360.871 298.773 339.822 315.055 340.327 358.165L368.484 345.639L372.801 310.702Z"
                      fill="var(--primary)"/>
                <path class="Vector_38"
                      d="M426.708 187.449C426.829 187.332 426.956 187.228 427.076 187.108C434.749 179.435 436.305 168.549 430.549 162.794C425.111 157.355 415.094 158.453 407.532 165.066C401.049 158.893 392.356 157.173 386.729 161.433C380.241 166.347 380.291 177.343 386.843 185.994C387.291 186.587 387.762 187.147 388.245 187.686C379.527 190.877 372 196.669 366.682 204.279C361.364 211.888 358.512 220.948 358.512 230.232V273.352C358.512 274.617 358.761 275.869 359.245 277.037C359.729 278.205 360.438 279.266 361.332 280.16C362.226 281.055 363.288 281.764 364.456 282.248C365.624 282.731 366.876 282.981 368.141 282.98H447.836C450.304 282.981 452.671 282 454.416 280.255C456.161 278.51 457.142 276.143 457.142 273.675V230.232C457.14 220.823 454.209 211.649 448.755 203.982C443.302 196.315 435.596 190.536 426.708 187.449Z"
                      fill="#2F2E41"/>
                <path class="Vector_39"
                      d="M435.827 267.602C450.596 252.833 450.596 228.888 435.827 214.119C421.058 199.351 397.113 199.351 382.344 214.119C367.576 228.888 367.575 252.833 382.344 267.602C397.113 282.371 421.058 282.371 435.827 267.602Z"
                      fill="#FFB6B6"/>
                <path class="Vector_40"
                      d="M369.348 217.073C377.99 223.03 388.247 226.2 398.743 226.158C394.999 228.754 390.737 230.507 386.25 231.297C400.205 234.291 414.627 234.372 428.614 231.535C431.724 231.037 434.724 230.006 437.483 228.489C438.858 227.721 440.056 226.673 440.999 225.411C441.942 224.15 442.609 222.704 442.957 221.168C443.887 215.858 439.749 211.034 435.449 207.783C428.866 202.881 421.276 199.504 413.227 197.896C405.178 196.287 396.873 196.488 388.911 198.483C383.713 199.827 378.505 202.098 375.128 206.272C371.752 210.447 370.751 216.884 373.968 221.183L369.348 217.073Z"
                      fill="#2F2E41"/>
            </g>
            <path class="Vector_41"
                  d="M167.07 141.607L168.261 140L200.01 163.55C198.285 158.291 192.178 141.621 182.167 134.97C170.206 127.024 161.792 129.495 157.207 136.395C152.623 143.296 153.604 152.011 165.565 159.957C175.703 166.693 193.764 165.757 199.033 165.315L167.07 141.607Z"
                  fill="#F2F2F2"/>
            <path class="Vector_42"
                  d="M644.767 12.6721L646.241 14.0245L619.517 43.152C624.926 41.9829 642.141 37.6428 649.798 28.3775C658.945 17.3087 657.362 8.68251 650.976 3.40525C644.59 -1.87201 635.82 -1.80211 626.673 9.26677C618.919 18.6494 617.972 36.7094 617.863 41.9959L644.767 12.6721Z"
                  fill="#F2F2F2"/>
            <path class="Vector_43"
                  d="M213.767 323.672L215.241 325.025L188.517 354.152C193.926 352.983 211.141 348.643 218.798 339.378C227.945 328.309 226.362 319.683 219.976 314.405C213.59 309.128 204.82 309.198 195.673 320.267C187.919 329.649 186.972 347.709 186.863 352.996L213.767 323.672Z"
                  fill="#F2F2F2"/>
            <path class="Vector_44"
                  d="M439.744 46.0501L441.475 47.052L421.678 81.2668C426.707 78.9562 442.577 70.9981 448.05 60.297C454.589 47.5129 451.179 39.4328 443.803 35.6604C436.428 31.888 427.88 33.8517 421.341 46.6359C415.798 57.4726 418.777 75.3105 419.813 80.4956L439.744 46.0501Z"
                  fill="#F2F2F2"/>
            <path class="Vector_45"
                  d="M624.744 260.05L626.475 261.052L606.678 295.267C611.707 292.956 627.577 284.998 633.05 274.297C639.589 261.513 636.179 253.433 628.803 249.66C621.428 245.888 612.88 247.852 606.341 260.636C600.798 271.473 603.777 289.31 604.813 294.496L624.744 260.05Z"
                  fill="#F2F2F2"/>
        </g>
        <defs>
            <clipPath class="clip0_714_1023">
                <rect width="818" height="618.165" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
