import { useStorage } from 'nvd-use-storage'

export const userMenuItems = [{
    title: "Guest",
    subMenuItems: [
        {
            id: "login",
            title: "Login",
            shortCutKey: "",
            disabled: false
        },
        {
            id: "signup",
            title: "register",
            shortCutKey: "",
            disabled: false
        }
    ]
}]

export const LoggedUserMenuItems = () => [
    {
        title: useStorage('user')?.name,
        subMenuItems: [
            {
                id: "dashboard",
                title: "Dashboard",
                shortCutKey: "",
                disabled: false
            },
            /*{
                id: "settings",
                title: "Settings",
                shortCutKey: "",
                disabled: false
            },*/
            {
                id: "logout",
                title: "Logout",
                shortCutKey: "",
                disabled: false
            }
        ]
    },
]
