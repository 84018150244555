import { randomInt } from "@/helpers/misc"

export const tabHelper = {
    tabId(tab) {
        return tab.schema?.id || Date.now() + randomInt(100000, 999999)
    },
    getSnakeCaseName(tab) {
        return tab.schema.title.replace(/ /g, '-').toLowerCase()
    },
    getClassName(tab) {
        return this.getSnakeCaseName(tab)
    },
    getClassForCanvasParent(tab) {
        let id = tab.schema.id || this.getClassName(tab)
        return `cp${id}`
    },
    getCanvasParentEl(tab) {
        return document.querySelector("." + this.getClassForCanvasParent(tab))
    },
}
