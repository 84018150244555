import { _deepClone, randomInt } from "@/helpers/misc"
import i18n from "@/locales/translations"
import { ApmCanvas } from "@/modules/canvas/ApmCanvas.js"

export const newComment = {
    user_id: null,
    commenter: null,
    content: '',
    own_comment: false
}

export const defaultHistory = { commands: [], currentCommandIdx: -1 }

export const newTab = function (data) {
    const tab = {
        id: Date.now() + randomInt(100000, 999999),
        dbml: '',
        history: _deepClone(defaultHistory),
        comments: [],
        newComment: { ...newComment },
        schema: {
            "id": data.id || null,
            "title": data.title,
            "schema_data": {
                "title": data.title,
                "notes": data.schema_data ? data.schema_data.notes : [],
                "tables": data.schema_data ? data.schema_data.tables : [],
                "db": data.db
            },
            "template": data.template,
            "db": data.db,
            "is_owner": true,
            "versions": [],
            "slug": data.slug || "new"
        },
    }

    tab.canvas = new ApmCanvas(tab)
    return tab
}

export const tabsSample = [newTab({
    "title": "Untitled",
    schema_data: {
        "db": "generic",
        "notes": [],
        "title": "Untitled",
        "tables": []
    }, "db": "generic"
})]
