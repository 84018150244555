<script setup>
import UButton from 'nvd-u/components/UButton.vue'
import CogOutlineIcon from 'nvd-u/icons/CogOutline.vue'
import { useSettingsStore } from "../../../stores/settings.store";

const settings = useSettingsStore()
</script>

<template>
    <UButton icon flat v-tooltip="'Settings'" @click="settings.showModal = true">
        <CogOutlineIcon/>
    </UButton>
</template>

<style scoped lang="scss">
</style>
