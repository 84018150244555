import en from "@/locales/en.json";
import es from "@/locales/es.json";
import bn from "@/locales/bn.json";
import ca from "@/locales/ca.json";
import cs from "@/locales/cs.json";
import da from "@/locales/da.json";
import de from "@/locales/de.json";
import eo from "@/locales/eo.json";
import fa from "@/locales/fa.json";
import fi from "@/locales/fi.json";
import fr from "@/locales/fr.json";
import gr from "@/locales/gr.json";
import id from "@/locales/id.json";
import it from "@/locales/it.json";
import ja from "@/locales/ja.json";
import kn from "@/locales/kn.json";
import nb_no from "@/locales/nb_no.json";
import nl from "@/locales/nl.json";
import pl from "@/locales/pl.json";
import pt_br from "@/locales/pt_br.json";
import ro from "@/locales/ro.json";
import ru from "@/locales/ru.json";
import tr from "@/locales/tr.json";
import ua from "@/locales/ua.json";
import uz from "@/locales/uz.json";
import vi from "@/locales/vi.json";
import zh from "@/locales/zh.json";

export const locales = {
    en,
    es,
    bn,
    ca,
    cs,
    da,
    de,
    eo,
    fa,
    fi,
    fr,
    gr,
    id,
    it,
    ja,
    kn,
    nb_no,
    nl,
    pl,
    pt_br,
    ro,
    ru,
    tr,
    ua,
    uz,
    vi,
    zh
};
