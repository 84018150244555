<script lang="ts" setup>
import { randomArrayItem } from 'nvd-js-helpers/misc'
import DrinkingAnimation from 'src/components/animated/DrinkingAnimation.vue'
import FeelingOfJoyAnimated from 'src/components/animated/FeelingOfJoyAnimated.vue'
import MeditationAnimated from 'src/components/animated/MeditationAnimated.vue'
import RocketAnimation from 'src/components/animated/RocketAnimation.vue'
import ThinkingAnimated from 'src/components/animated/ThinkingAnimated.vue'
import { useInterval } from 'src/composables/useInterval'
import { shallowRef } from 'vue'

const comps = [
    MeditationAnimated,
    ThinkingAnimated,
    FeelingOfJoyAnimated,
    DrinkingAnimation,
    RocketAnimation
]
const comp = shallowRef(randomArrayItem(comps))

useInterval(() => {
    comp.value = randomArrayItem(comps)
}, 4000)
</script>

<template>
    <div>
        <Transition name="fade" mode="out-in">
            <component :is="comp" class="waiting-comp"/>
        </Transition>
    </div>
</template>

<style scoped lang="scss">
.waiting-comp {
    max-height: 20rem;
}
</style>
