<script lang="ts" setup>

</script>

<style scoped lang="scss">
.item-f, .item-s {
    animation: bounce 1s ease-in-out infinite alternate;
}

.torso {
    animation: bounce 2.5s ease-out infinite alternate;
}

.item-f {
    animation-delay: 0.4s;
}
</style>

<template>
    <svg width="768" height="596" viewBox="0 0 768 596" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="undraw_design_thinking_re_644h 1" clip-path="url(#clip0_711_925)">
            <path class="Vector"
                  d="M466.439 371.03L465.188 385.737C465.04 387.454 464.217 389.042 462.9 390.153C461.582 391.263 459.878 391.806 458.161 391.662L335.59 381.235C333.873 381.087 332.285 380.264 331.174 378.946C330.063 377.629 329.52 375.924 329.664 374.207L330.915 359.5C331.063 357.784 331.886 356.195 333.204 355.085C334.521 353.974 336.226 353.431 337.943 353.575L460.514 364.002C462.231 364.15 463.819 364.973 464.93 366.291C466.041 367.608 466.584 369.313 466.439 371.03Z"
                  fill="var(--primary)"/>
            <path class="Vector_2"
                  d="M490.869 442.764L477.293 448.556C473.997 449.963 470.604 449.441 469.73 447.394L439.746 377.116C438.873 375.068 440.844 372.258 444.141 370.851L457.716 365.059C461.013 363.653 464.406 364.174 465.279 366.222L495.263 436.5C496.137 438.548 494.166 441.358 490.869 442.764Z"
                  fill="var(--primary)"/>
            <path class="Vector_3"
                  d="M436.439 382.03L435.188 396.737C435.04 398.453 434.217 400.042 432.9 401.152C431.582 402.263 429.878 402.806 428.161 402.662L305.59 392.235C303.873 392.087 302.285 391.264 301.174 389.946C300.063 388.629 299.52 386.924 299.664 385.207L300.915 370.5C301.063 368.784 301.886 367.195 303.204 366.085C304.521 364.974 306.226 364.431 307.943 364.575L430.514 375.002C432.231 375.15 433.819 375.973 434.93 377.291C436.041 378.608 436.584 380.313 436.439 382.03Z"
                  fill="var(--primary)"/>
            <path class="Vector_4"
                  d="M460.869 453.764L447.293 459.556C443.997 460.963 440.604 460.441 439.73 458.394L409.746 388.116C408.873 386.068 410.844 383.258 414.141 381.851L427.716 376.059C431.013 374.653 434.406 375.174 435.279 377.222L465.263 447.5C466.137 449.548 464.166 452.358 460.869 453.764Z"
                  fill="var(--primary)"/>
            <path class="item-f" d="M684.213 1.07922L658.806 20.5334L678.26 45.9408L703.668 26.4866L684.213 1.07922Z"
                  fill="var(--primary)"/>
            <path class="item-f"
                  d="M540.541 202.152C552.691 202.152 562.541 192.303 562.541 180.152C562.541 168.002 552.691 158.152 540.541 158.152C528.391 158.152 518.541 168.002 518.541 180.152C518.541 192.303 528.391 202.152 540.541 202.152Z"
                  fill="#FF6584"/>
            <path class="item-s"
                  d="M534.896 195.81C532.131 192.198 530.499 187.847 530.206 183.308C529.913 178.768 530.973 174.244 533.251 170.307C535.53 166.369 538.925 163.196 543.007 161.189C547.088 159.181 551.674 158.429 556.184 159.027C560.693 159.625 564.924 161.547 568.341 164.55C571.758 167.553 574.208 171.502 575.38 175.897C576.553 180.292 576.396 184.937 574.93 189.243C573.463 193.549 570.753 197.323 567.141 200.089C562.295 203.79 556.179 205.419 550.134 204.617C544.089 203.814 538.609 200.647 534.896 195.81ZM569.832 169.06C567.306 165.762 563.86 163.288 559.929 161.948C555.997 160.609 551.757 160.466 547.744 161.537C543.731 162.608 540.125 164.845 537.384 167.965C534.642 171.084 532.887 174.947 532.34 179.065C531.794 183.182 532.481 187.369 534.314 191.096C536.147 194.823 539.044 197.922 542.639 200.003C546.234 202.083 550.365 203.051 554.51 202.783C558.655 202.516 562.627 201.026 565.925 198.501C570.342 195.111 573.233 190.107 573.966 184.588C574.698 179.069 573.211 173.485 569.832 169.06Z"
                  fill="#3F3D56"/>
            <path class="item-s"
                  d="M685.878 47.6654L665.208 20.6701L692.203 0L712.873 26.9953L685.878 47.6654ZM668.011 21.0422L686.25 44.8616L710.069 26.6232L691.831 2.80389L668.011 21.0422Z"
                  fill="#3F3D56"/>
            <path class="item-f" d="M738.995 179.542L713.588 198.996L733.042 224.404L758.449 204.949L738.995 179.542Z"
                  fill="var(--primary)"/>
            <path class="item-s"
                  d="M740.659 226.128L719.989 199.133L746.985 178.463L767.655 205.458L740.659 226.128ZM722.793 199.505L741.031 223.324L764.851 205.086L746.612 181.267L722.793 199.505Z"
                  fill="#3F3D56"/>
            <path class="Vector_5" d="M625 572.996H1V574.996H625V572.996Z" fill="#3F3D56"/>
            <g class="sofa">
                <path class="Vector_6"
                      d="M419 419.996H189.111C191.758 400.832 188.493 381.318 179.75 364.06C171.008 346.803 157.205 332.626 140.188 323.425C123.171 314.224 103.75 310.437 84.5231 312.571C65.2957 314.705 47.1781 322.658 32.5926 335.366C18.0071 348.075 7.64893 364.933 2.90311 383.687C-1.84272 402.442 -0.750021 422.198 6.0351 440.314C12.8202 458.431 24.9743 474.044 40.8724 485.066C56.7704 496.089 75.6546 501.995 95 501.996C106.622 502.008 118.147 499.881 129 495.723V587.996C129 590.118 129.843 592.152 131.343 593.653C132.843 595.153 134.878 595.996 137 595.996H419C421.122 595.996 423.157 595.153 424.657 593.653C426.157 592.152 427 590.118 427 587.996V427.996C427 425.874 426.157 423.839 424.657 422.339C423.157 420.839 421.122 419.996 419 419.996Z"
                      fill="#E6E6E6"/>
                <path class="Vector_7"
                      d="M436 574.996H154C151.614 574.993 149.326 574.044 147.639 572.357C145.952 570.67 145.003 568.382 145 565.996V405.996C145.003 403.61 145.952 401.322 147.639 399.635C149.326 397.948 151.614 396.999 154 396.996H436C438.386 396.999 440.674 397.948 442.361 399.635C444.048 401.322 444.997 403.61 445 405.996V565.996C444.997 568.382 444.048 570.67 442.361 572.357C440.674 574.044 438.386 574.993 436 574.996ZM154 398.996C152.144 398.998 150.365 399.736 149.053 401.048C147.74 402.361 147.002 404.14 147 405.996V565.996C147.002 567.852 147.74 569.631 149.053 570.943C150.365 572.255 152.144 572.994 154 572.996H436C437.856 572.994 439.635 572.255 440.947 570.943C442.26 569.631 442.998 567.852 443 565.996V405.996C442.998 404.14 442.26 402.361 440.947 401.048C439.635 399.736 437.856 398.998 436 398.996H154Z"
                      fill="#3F3D56"/>
                <path class="sofa-top"
                      d="M108 476.996C89.0129 476.996 70.4523 471.365 54.6652 460.817C38.8781 450.268 26.5735 435.275 19.3075 417.733C12.0415 400.192 10.1404 380.889 13.8445 362.267C17.5487 343.645 26.6918 326.539 40.1177 313.114C53.5435 299.688 70.6491 290.545 89.2712 286.84C107.893 283.136 127.196 285.037 144.738 292.303C162.279 299.569 177.272 311.874 187.821 327.661C198.37 343.448 204 362.009 204 380.996C203.971 406.448 193.848 430.849 175.85 448.846C157.853 466.844 133.452 476.967 108 476.996ZM108 286.996C89.4085 286.996 71.2345 292.509 55.7763 302.838C40.3181 313.166 28.2699 327.847 21.1552 345.024C14.0406 362.2 12.1791 381.1 15.8061 399.334C19.4331 417.568 28.3858 434.318 41.5319 447.464C54.678 460.61 71.4272 469.563 89.6614 473.19C107.896 476.817 126.796 474.955 143.972 467.84C161.148 460.726 175.829 448.678 186.158 433.219C196.487 417.761 202 399.587 202 380.996C201.972 356.074 192.059 332.181 174.437 314.559C156.815 296.937 132.922 287.024 108 286.996Z"
                      fill="#3F3D56"/>
            </g>
            <g class="torso">
                <path class="Vector_8"
                      d="M316.421 238.526C345.979 238.526 369.941 214.564 369.941 185.006C369.941 155.449 345.979 131.487 316.421 131.487C286.864 131.487 262.902 155.449 262.902 185.006C262.902 214.564 286.864 238.526 316.421 238.526Z"
                      fill="var(--primary)"/>
                <path class="Vector_9"
                      d="M324.297 204.842L324.241 202.842C327.962 202.739 331.242 202.505 333.707 200.705C334.406 200.165 334.98 199.481 335.391 198.7C335.802 197.919 336.04 197.058 336.089 196.176C336.132 195.638 336.05 195.096 335.85 194.594C335.65 194.092 335.337 193.643 334.936 193.282C333.299 191.9 330.667 192.347 328.748 193.227L327.093 193.986L330.266 170.796L332.248 171.067L329.548 190.794C332.156 190.027 334.572 190.357 336.227 191.755C336.862 192.309 337.361 193.003 337.683 193.783C338.006 194.562 338.144 195.405 338.087 196.247C338.029 197.431 337.712 198.588 337.159 199.638C336.606 200.687 335.831 201.602 334.887 202.32C331.72 204.634 327.604 204.75 324.297 204.842Z"
                      fill="#2F2E41"/>
                <path class="Vector_10" d="M352.818 172.768H342.047V174.768H352.818V172.768Z" fill="#2F2E41"/>
                <path class="Vector_11" d="M318.818 172.768H308.047V174.768H318.818V172.768Z" fill="#2F2E41"/>
                <path class="Vector_12"
                      d="M393 399.496H267C265.807 399.495 264.663 399.02 263.819 398.176C262.976 397.333 262.501 396.189 262.5 394.996V293.775C262.514 281.505 267.394 269.742 276.07 261.066C284.746 252.39 296.51 247.51 308.779 247.496H350C351.18 247.497 352.313 247.961 353.154 248.789C353.995 249.616 354.478 250.741 354.499 251.921L397.5 394.923V394.996C397.499 396.189 397.024 397.333 396.181 398.176C395.337 399.02 394.193 399.495 393 399.496Z"
                      fill="#2F2E41"/>
                <g class="arm">
                    <path class="Vector_13"
                          d="M309.303 288.451L324.061 288.567C325.785 288.582 327.431 289.281 328.64 290.509C329.849 291.737 330.522 293.394 330.51 295.117L329.543 418.127C329.528 419.851 328.83 421.497 327.601 422.706C326.373 423.915 324.716 424.588 322.993 424.576L308.234 424.46C306.511 424.445 304.864 423.746 303.655 422.518C302.446 421.29 301.774 419.632 301.785 417.909L302.752 294.899C302.767 293.176 303.466 291.529 304.694 290.32C305.922 289.111 307.579 288.439 309.303 288.451Z"
                          fill="var(--primary)"/>
                    <path class="Vector_14"
                          d="M294.5 283.496C294.5 277.661 296.818 272.065 300.944 267.94C305.069 263.814 310.665 261.496 316.5 261.496C322.335 261.496 327.931 263.814 332.056 267.94C336.182 272.065 338.5 277.661 338.5 283.496V332.496H294.5V283.496Z"
                          fill="#2F2E41"/>
                </g>
                <path class="hair"
                      d="M300.612 159.799C305.681 154.584 313.045 153.687 319.974 154.255C327.395 154.862 334.426 157.327 341.705 158.699C348.885 160.051 356.669 160.657 363.532 157.661C366.7 156.238 369.446 154.018 371.501 151.218C373.555 148.417 374.849 145.132 375.255 141.682C376.138 134.438 374.262 127.127 369.998 121.204C365.298 114.562 358.621 109.698 351.68 105.615C336.718 96.8127 320.035 89.1108 302.53 87.687C286.725 86.4016 269.967 90.7693 258.849 102.573C248.427 113.637 243.991 129.078 243.068 143.949C241.891 162.928 246.32 181.671 247.923 200.49C249.17 215.133 248.703 232.712 236.811 243.228C226.292 252.53 210.275 252.419 197.873 247.338C183.132 241.298 172.788 227.74 164.965 214.415C162.974 211.022 161.101 207.562 159.286 204.073C158.394 202.359 155.804 203.875 156.695 205.587C164.017 219.657 172.533 233.711 185.34 243.461C196.537 251.986 211.478 256.092 225.319 252.489C232.029 250.814 238.023 247.027 242.417 241.685C247.16 235.775 249.65 228.502 250.688 221.062C251.8 213.099 251.412 205.075 250.607 197.103C249.638 187.506 248.092 177.978 247.034 168.392C245.231 152.064 244.983 135.119 251.434 119.693C254.216 112.813 258.537 106.663 264.066 101.713C270.117 96.6117 277.378 93.1497 285.151 91.6591C302.11 88.1398 319.35 92.9048 334.703 100.085C342.451 103.546 349.868 107.708 356.86 112.518C363.057 116.953 368.829 122.763 371.216 130.171C373.274 136.555 373.103 144.112 369.001 149.679C364.617 155.628 357.02 157.113 350.054 156.755C335.748 156.02 321.33 147.705 306.935 152.45C303.738 153.484 300.841 155.278 298.491 157.678C297.144 159.063 299.263 161.187 300.612 159.799Z"
                      fill="#2F2E41"/>
            </g>
        </g>
        <defs>
            <clipPath class="clip0_711_925">
                <rect width="767.655" height="595.996" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
