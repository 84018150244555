<script lang="ts" setup>
import { onMounted, ref } from 'vue'

const svg = ref()

onMounted(() => {
    svg.value.querySelectorAll('.star, .plus').forEach(el => {
        el.style.animationDelay = Math.random() * 3 + 's'
        el.style.animationDuration = (1 + Math.random()) + 's'
    })
})
</script>

<template>
    <svg ref="svg" width="903" height="827" viewBox="0 0 903 827" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="undraw_outer_space_re_u9vd 1" clip-path="url(#clip0_708_849)">
            <path class="smoke"
                  d="M578.159 659.811L586.83 679.255C579.782 678.133 573.226 684.153 573.079 691.884C572.932 699.615 579.219 707.658 586.268 708.757C584.921 714.759 579.107 717.405 574.061 719.831C569.015 722.257 563.576 726.281 563.951 732.677C564.252 737.812 568.499 742.346 573.009 744.169C577.518 745.992 582.25 745.711 586.817 745.406C579.896 752.645 579.222 766.253 585.3 776.016C591.378 785.779 603.516 790.585 612.605 786.828C606.183 794.816 607.579 809.445 615.548 817.69C623.583 826.001 635.226 826.805 645.406 825.929C671.327 823.738 696.027 813.95 716.412 797.789C724.194 791.606 731.463 784.334 735.902 774.848C740.341 765.362 741.649 753.352 737.514 742.558C738.629 756.08 752.164 766.76 764.34 767.358C776.516 767.956 787.147 761.098 796.181 753.277C808.195 742.877 819.318 728.298 817.669 710.31C816.853 701.405 812.963 692.841 809.148 684.545L801.753 668.462C798.767 661.97 795.714 655.367 791.22 649.919C786.726 644.47 780.489 640.252 774.247 640.292C775.674 633.772 777.108 627.009 775.912 620.081C774.716 613.153 770.136 605.981 763.907 604.358C755.98 602.294 749.263 609.609 741.292 608.919C733.621 608.255 727.282 600.557 719.833 597.507C716.766 596.18 713.379 595.777 710.086 596.347C706.794 596.916 703.739 598.434 701.296 600.713C696.574 605.323 694.605 613.111 696.3 620.48C697.784 626.936 701.734 632.629 704.132 638.855C706.53 645.081 706.902 653.086 702.297 656.486C696.74 660.589 688.283 655.006 683.941 648.252C679.6 641.497 677.108 633.318 671.618 627.638C665.138 620.933 654.996 619.362 648.325 624.029C641.654 628.696 639.103 639.148 642.466 648.033C645.216 655.297 651.515 662.646 649.33 669.487C647.021 676.717 636.918 675.802 630.823 670.692C624.729 665.582 620.682 657.886 614.662 652.662C608.548 647.356 600.564 645.031 593.555 646.516C589.999 647.341 586.709 649.048 583.987 651.48C581.265 653.912 579.2 656.99 577.982 660.431"
                  fill="#E6E6E6"/>
            <path class="cloud"
                  d="M160.64 243.762C177.555 197.465 203.952 155.211 238.141 119.705C267.148 89.5937 312.291 90.8449 312.291 90.8449L312.549 90.5249C323.414 77.1266 337.994 67.2352 354.459 62.0916C370.925 56.948 388.542 56.7813 405.102 61.6124C409.406 62.8476 412.717 63.395 414.292 62.8449C442.999 52.7978 441.65 30.1207 466.418 11.8521C509.785 -20.1351 653.629 16.5056 673.912 75.3658C690.351 123.071 763.732 146.193 791.148 182.683C795.435 188.389 799.641 194.161 803.766 199.999C805.7 202.711 807.612 205.444 809.503 208.197C828.927 236.325 846.352 265.783 861.65 296.352C871.682 316.324 880.401 336.93 887.751 358.036C916.342 441.619 906.565 503.891 827.73 488.507C818.398 486.681 809.29 485.047 800.406 483.605C787.542 481.506 775.127 479.781 763.16 478.43C747.045 476.597 731.714 475.396 717.166 474.828C712.275 474.631 707.473 474.499 702.761 474.432C633.091 473.466 581.412 486.578 542.042 506.934C535.368 510.375 529.044 514.017 523.069 517.857C513.943 523.691 505.229 530.146 496.989 537.176C485.586 546.961 475.071 557.736 465.566 569.373L465.044 570.013C418.996 626.339 403.841 688.694 371.81 699.673C234.883 746.614 148.283 576.454 139.402 409.999C138.954 401.612 138.706 393.248 138.659 384.908C138.631 381.18 138.65 377.463 138.714 373.755C138.855 364.081 139.281 354.47 139.995 344.975C140.262 341.345 140.571 337.733 140.923 334.142C141.311 330.268 141.733 326.418 142.221 322.592C145.704 294.545 151.798 267.861 160.64 243.762Z"
                  fill="var(--primary)"/>
            <path class="star"
                  d="M291.361 341.028C287.499 343.601 283.546 337.477 287.483 335.018C291.344 332.445 295.297 338.569 291.361 341.028Z"
                  fill="#E6E6E6"/>
            <path class="star"
                  d="M693.361 253.028C689.499 255.602 685.546 249.477 689.482 247.018C693.344 244.445 697.297 250.57 693.361 253.028Z"
                  fill="#E6E6E6"/>
            <path class="star"
                  d="M419.361 323.028C415.499 325.601 411.546 319.477 415.482 317.018C419.344 314.445 423.297 320.569 419.361 323.028Z"
                  fill="#E6E6E6"/>
            <path class="star"
                  d="M291.361 563.028C287.499 565.601 283.546 559.477 287.483 557.018C291.344 554.445 295.297 560.569 291.361 563.028Z"
                  fill="#E6E6E6"/>
            <path class="star"
                  d="M488.361 62.0284C484.499 64.6015 480.546 58.4771 484.482 56.0182C488.344 53.4451 492.297 59.5696 488.361 62.0284Z"
                  fill="#E6E6E6"/>
            <path class="star"
                  d="M298.361 180.028C294.499 182.602 290.546 176.477 294.483 174.018C298.344 171.445 302.297 177.57 298.361 180.028Z"
                  fill="#E6E6E6"/>
            <path class="plus"
                  d="M571.639 146.2L569.182 145.661L569.72 143.203L568.082 142.844L567.543 145.302L565.086 144.764L564.727 146.402L567.184 146.941L566.646 149.398L568.284 149.757L568.823 147.299L571.28 147.838L571.639 146.2Z"
                  fill="#E6E6E6"/>
            <path class="plus"
                  d="M171.542 350.978L169.085 350.439L169.623 347.982L167.985 347.623L167.446 350.08L164.988 349.542L164.629 351.18L167.087 351.719L166.549 354.177L168.187 354.535L168.726 352.078L171.183 352.616L171.542 350.978Z"
                  fill="#E6E6E6"/>
            <path class="sun"
                  d="M171.292 307.845C228.177 307.845 274.292 261.73 274.292 204.845C274.292 147.96 228.177 101.845 171.292 101.845C114.406 101.845 68.2915 147.96 68.2915 204.845C68.2915 261.73 114.406 307.845 171.292 307.845Z"
                  fill="#FF6584"/>
            <path class="Vector" opacity="0.3"
                  d="M593.606 312.124L656.199 129.315L587.468 309.936C587.128 310.364 586.895 310.868 586.788 311.405C586.682 311.941 586.705 312.496 586.856 313.022C587.006 313.548 587.28 314.03 587.655 314.429C588.029 314.828 588.493 315.132 589.009 315.316C589.524 315.499 590.076 315.558 590.618 315.485C591.16 315.413 591.678 315.213 592.127 314.901C592.577 314.589 592.945 314.174 593.202 313.691C593.459 313.208 593.598 312.671 593.606 312.124Z"
                  fill="#F0F0F0"/>
            <path class="planet-pink"
                  d="M783.425 385.839C789.336 385.839 794.128 381.047 794.128 375.136C794.128 369.225 789.336 364.434 783.425 364.434C777.514 364.434 772.723 369.225 772.723 375.136C772.723 381.047 777.514 385.839 783.425 385.839Z"
                  fill="#FF6584"/>
            <path class="Vector_2"
                  d="M851.425 675.839C857.336 675.839 862.128 671.047 862.128 665.136C862.128 659.225 857.336 654.434 851.425 654.434C845.514 654.434 840.723 659.225 840.723 665.136C840.723 671.047 845.514 675.839 851.425 675.839Z"
                  fill="#F2F2F2"/>
            <path class="Vector_3"
                  d="M486.425 741.002C497.949 741.002 507.292 731.66 507.292 720.136C507.292 708.612 497.949 699.27 486.425 699.27C474.901 699.27 465.559 708.612 465.559 720.136C465.559 731.66 474.901 741.002 486.425 741.002Z"
                  fill="#F2F2F2"/>
            <path class="planet-black"
                  d="M327.425 464.839C333.336 464.839 338.128 460.047 338.128 454.136C338.128 448.225 333.336 443.434 327.425 443.434C321.515 443.434 316.723 448.225 316.723 454.136C316.723 460.047 321.515 464.839 327.425 464.839Z"
                  fill="#3F3D56"/>
            <g class="rocket">
                <path class="Vector_4"
                      d="M718.556 605.17L700.563 613.529C697.859 614.865 694.791 615.279 691.829 614.707C689.217 614.089 687.452 612.492 686.988 610.324L682.802 590.769C682.011 587.081 685.066 582.716 690.065 580.393L700.002 575.777C705.001 573.454 710.307 573.934 712.616 576.917L724.861 592.728C726.219 594.481 726.301 596.86 725.088 599.254C723.615 601.887 721.321 603.964 718.556 605.17Z"
                      fill="#3F3D56"/>
                <path class="Vector_5"
                      d="M585.043 335.344C574.108 340.435 573.927 363.239 577.356 370.628L583.57 384.002L623.18 365.599L616.966 352.225C613.53 344.839 595.986 330.27 585.043 335.344Z"
                      fill="#3F3D56"/>
                <path class="Vector_6"
                      d="M478.602 527.36C477.905 527.36 477.218 527.191 476.6 526.869C475.982 526.547 475.451 526.081 475.051 525.51C474.652 524.939 474.396 524.28 474.306 523.589C474.215 522.898 474.293 522.195 474.532 521.54C474.726 521.005 475.024 520.514 475.409 520.095C475.794 519.676 476.259 519.338 476.775 519.1L681.329 424.062C681.845 423.822 682.403 423.686 682.971 423.662C683.539 423.638 684.106 423.726 684.64 423.921C685.174 424.116 685.665 424.415 686.084 424.799C686.502 425.184 686.841 425.648 687.081 426.163C687.563 427.205 687.613 428.396 687.219 429.474C686.825 430.552 686.019 431.43 684.978 431.915L480.424 526.953C479.854 527.22 479.232 527.359 478.602 527.36Z"
                      fill="#3F3D56"/>
                <path class="Vector_7"
                      d="M638.609 374.128L580.138 401.294L671.859 598.708L730.33 571.541L638.609 374.128Z"
                      fill="#E6E6E6"/>
                <path class="Vector_8"
                      d="M419.114 125.617C408.179 130.709 407.998 153.512 411.427 160.901L417.641 174.276L457.251 155.873L451.037 142.498C447.601 135.113 430.057 120.544 419.114 125.617Z"
                      fill="#3F3D56"/>
                <path class="Vector_9"
                      d="M664.129 627.864L646.136 636.224C643.431 637.559 640.364 637.973 637.402 637.401C634.79 636.783 633.025 635.186 632.561 633.018L628.375 613.464C627.584 609.775 630.639 605.411 635.638 603.088L645.575 598.471C650.574 596.148 655.88 596.628 658.189 599.611L670.433 615.422C671.791 617.175 671.874 619.554 670.661 621.949C669.188 624.581 666.894 626.658 664.129 627.864Z"
                      fill="#3F3D56"/>
                <path class="Vector_10"
                      d="M608.81 655.31L590.817 663.669C588.113 665.005 585.045 665.419 582.083 664.847C579.471 664.229 577.706 662.632 577.242 660.464L573.056 640.909C572.265 637.221 575.32 632.856 580.319 630.534L590.256 625.917C595.255 623.594 600.561 624.074 602.87 627.057L615.115 642.868C616.473 644.621 616.555 647 615.342 649.394C613.869 652.027 611.575 654.104 608.81 655.31Z"
                      fill="#3F3D56"/>
                <path class="Vector_11"
                      d="M488.282 197.982L429.811 225.149L615.214 624.197L673.685 597.031L488.282 197.982Z"
                      fill="#E6E6E6"/>
                <path class="Vector_12"
                      d="M548.951 403.691L542.39 389.254L546.167 387.512L552.708 401.903L647.423 611.909L643.65 613.662"
                      fill="#3F3D56"/>
                <path class="Vector_13"
                      d="M622.951 391.691L616.39 377.254L620.167 375.512L626.708 389.903L721.423 599.909L717.65 601.662"
                      fill="#3F3D56"/>
                <path class="Vector_14"
                      d="M515.88 229.917L436.654 266.727C431.61 258.879 416.405 218.666 419.819 206.735L417.257 195.358L471.96 169.942L479.282 178.949C490.151 182.284 511.311 220.055 515.88 229.917Z"
                      fill="#E6E6E6"/>
                <path class="Vector_15"
                      d="M474.246 170.428L417.795 196.656C415.711 197.624 413.492 197.268 412.839 195.861C412.745 195.658 412.687 195.441 412.667 195.218L409.745 164.05C409.62 162.716 410.762 161.203 412.506 160.393L449.943 142.999C451.687 142.189 453.58 142.292 454.519 143.248L476.455 165.582C477.577 166.724 476.967 168.679 475.094 169.949C474.825 170.131 474.541 170.291 474.246 170.428Z"
                      fill="#E6E6E6"/>
                <path class="Vector_16"
                      d="M518.522 414.617C517.911 414.619 517.302 414.547 516.708 414.404C515.871 414.23 515.085 413.867 514.409 413.343C513.734 412.818 513.188 412.146 512.812 411.378L478.299 337.095C476.871 334.02 478.789 330.088 482.574 328.329L533.11 304.849C536.895 303.089 541.137 304.161 542.566 307.236L577.079 381.519C577.424 382.302 577.585 383.153 577.55 384.007C577.516 384.861 577.286 385.696 576.878 386.448C575.992 388.15 574.556 389.503 572.804 390.286L522.269 413.765C521.097 414.318 519.818 414.609 518.522 414.617Z"
                      fill="#3F3D56"/>
                <path class="Vector_17"
                      d="M525.434 425.862L466.963 453.028L558.683 650.441L617.154 623.275L525.434 425.862Z"
                      fill="#E6E6E6"/>
                <path class="Vector_18"
                      d="M453.398 173.685L436.251 181.651C432.942 183.189 428.478 180.627 426.301 175.942C424.125 171.256 425.046 166.193 428.355 164.655L445.502 156.689C448.81 155.151 453.274 157.713 455.451 162.398C457.628 167.084 456.707 172.147 453.398 173.685Z"
                      fill="#3F3D56"/>
                <path class="Vector_19"
                      d="M473.008 386.547C462.073 391.638 461.893 414.442 465.322 421.831L471.536 435.205L511.145 416.802L504.931 403.428C501.496 396.042 483.952 381.473 473.008 386.547Z"
                      fill="#3F3D56"/>
                <path class="Vector_20"
                      d="M675.429 455.436C673.384 456.383 671.047 456.481 668.931 455.707C666.814 454.933 665.091 453.352 664.139 451.309L654.027 429.544C653.08 427.5 652.984 425.164 653.758 423.048C654.533 420.933 656.115 419.212 658.158 418.263C660.201 417.314 662.537 417.215 664.653 417.987C666.769 418.759 668.492 420.339 669.444 422.381L679.556 444.146C680.504 446.191 680.601 448.528 679.827 450.645C679.054 452.761 677.472 454.484 675.429 455.436Z"
                      fill="#3F3D56"/>
                <path class="Vector_21"
                      d="M503.429 535.436C501.384 536.383 499.047 536.481 496.931 535.707C494.814 534.933 493.091 533.352 492.139 531.309L482.027 509.544C481.08 507.5 480.983 505.164 481.758 503.049C482.533 500.933 484.115 499.212 486.158 498.263C488.201 497.314 490.537 497.215 492.653 497.987C494.769 498.759 496.492 500.339 497.444 502.381L507.556 524.146C508.504 526.191 508.601 528.528 507.827 530.645C507.054 532.761 505.472 534.484 503.429 535.436Z"
                      fill="#3F3D56"/>
                <path class="Vector_22"
                      d="M659.851 407.881L592.045 439.385C587.728 432.669 574.715 398.252 577.637 388.041L579.071 376.618L619.557 356.755L628.528 364.26C641.168 359.986 659.748 391.308 659.851 407.881Z"
                      fill="#E6E6E6"/>
                <path class="Vector_23"
                      d="M628.371 405.697L611.224 413.664C607.915 415.201 602.881 411.411 600.002 405.217C597.124 399.022 597.475 392.73 600.784 391.193L617.931 383.226C621.239 381.689 626.274 385.479 629.152 391.673C632.03 397.868 631.68 404.16 628.371 405.697Z"
                      fill="#3F3D56"/>
                <path class="Vector_24"
                      d="M482.951 446.691L476.39 432.254L480.167 430.512L486.708 444.903L581.423 654.909L577.65 656.662"
                      fill="#3F3D56"/>
                <path class="Vector_25"
                      d="M547.817 459.085L480.011 490.588C470.008 485.423 454.72 451.627 465.602 439.244L467.037 427.821L507.523 407.958L516.494 415.463C525.797 418.317 543.906 450.644 547.817 459.085Z"
                      fill="#E6E6E6"/>
                <path class="Vector_26"
                      d="M514.941 453.897L497.794 461.864C494.485 463.401 489.764 460.285 487.27 454.918C484.777 449.551 485.441 443.933 488.749 442.396L505.896 434.429C509.205 432.892 513.927 436.008 516.42 441.375C518.914 446.741 518.25 452.36 514.941 453.897Z"
                      fill="#3F3D56"/>
                <path class="Vector_27"
                      d="M687.226 478.768L733.402 490.721L761.723 546.349L713.843 548.227L687.226 478.768Z"
                      fill="#E6E6E6"/>
                <path class="Vector_28"
                      d="M580.645 398.574L606.059 424.002L670.606 563.557L658.722 573.824L580.645 398.574Z"
                      fill="#CCCCCC"/>
                <path class="Vector_29"
                      d="M525.386 421.624L527.38 457.52L588.304 598.694L603.88 596.688L525.386 421.624Z"
                      fill="#CCCCCC"/>
                <path class="Vector_30"
                      d="M523.723 566.637L498.854 607.339L517.187 667.008L552.9 635.061L523.723 566.637Z"
                      fill="#E6E6E6"/>
            </g>
            <path class="elipse-t"
                  d="M753.159 203.158C740.906 207.23 727.322 211.23 712.408 215.159C635.263 235.47 531.858 251.313 421.242 259.772C310.627 268.23 206.015 268.291 126.679 259.946C88.0317 255.88 57.4639 250.033 35.8252 242.565C12.9157 234.659 0.877946 225.133 0.0456665 214.251C-1.57507 193.051 40.2582 174.393 75.6376 162.473L76.5958 165.316C28.0106 181.685 1.88717 198.982 3.03737 214.023C4.50167 233.178 49.6817 248.829 126.993 256.962C206.154 265.289 310.573 265.225 421.014 256.781C531.454 248.335 634.669 232.523 711.644 212.257C786.818 192.467 829.095 170.128 827.63 150.972C826.414 135.067 795.063 121.462 739.352 112.66L739.821 109.697C780.301 116.092 828.913 128.405 830.622 150.743C831.453 161.625 821.004 172.871 799.563 184.167C787.34 190.607 771.811 196.959 753.159 203.158Z"
                  fill="#3F3D56"/>
            <path class="elipse-b"
                  d="M743.405 590.647C730.493 590.647 716.34 590.161 700.948 589.189C621.334 584.153 518.21 566.646 410.572 539.893C302.934 513.14 203.642 480.336 130.987 447.526C95.5941 431.543 68.4306 416.414 50.2514 402.558C31.0043 387.889 22.5854 375.103 25.2274 364.556C30.3754 344.009 75.9585 339.514 113.291 339.361L113.304 342.349C62.0361 342.559 31.7905 350.703 28.1386 365.28C23.4867 383.846 61.4252 412.831 132.226 444.805C204.721 477.543 303.831 510.283 411.299 536.994C518.766 563.705 621.7 581.182 701.139 586.207C778.717 591.115 825.882 583.281 830.533 564.714C834.395 549.299 808.935 526.591 758.844 500.77L760.223 498.117C796.62 516.878 838.868 543.787 833.444 565.438C830.802 575.985 817.339 583.332 793.429 587.274C779.8 589.521 763.059 590.647 743.405 590.647Z"
                  fill="#3F3D56"/>
        </g>
        <defs>
            <clipPath class="clip0_708_849">
                <rect width="902.419" height="826.207" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<style scoped lang="scss">
@keyframes upDown {
    to {
        transform: translateY(2%);
    }
}


@keyframes smoke {
    0% {
        transform: translate3d(0px, 0px, 0);
    }
    50% {
        transform: translate3d(0px, -4px, 0) scale(0.7);
    }
    100% {
        transform: translate3d(0px, 0px, 0);
    }
}

@keyframes fade {
    to {
        opacity: 0;
    }
}

.cloud {
    animation: upDown 2s ease infinite alternate;
}

.rocket {
    animation: bounce 0.5s infinite;
}

.smoke {
    transform-origin: 70% 75%;
    animation: smoke 2s infinite;
}

.star, .plus {
    animation: fade ease-out 1s infinite;
}
</style>
