import { LINE_TYPES } from "@/data/constants"
import { cssVar } from "nvd-js-helpers/misc"
import { canvasHelper } from "@/modules/canvas/canvas-helper.js"

export class LogicalConnection {
    canvas
    toItemId
    fromItem
    toItem

    constructor(canvas, fromTable, toTableId) {
        this.canvas = canvas
        this.toItemId = toTableId
        this.fromItem = fromTable
    }

    draw() {
        // if (this.isOutOfScreen()) return
        let direction = ['right', 'left']
        if (this.fromItem.right <= this.toItem.left) direction = ['right', 'left']
        else if (this.fromItem.left >= this.toItem.right) direction = ['left', 'right']
        else if (this.fromItem.right <= this.toItem.right) direction = ['right', 'right']
        else if (this.fromItem.left >= this.toItem.left) direction = ['left', 'left']

        this.drawConnection(...direction)
    }

    drawConnection(from, to) {
        let fromX = this.fromItem[from]
        let toX = this.toItem[to]
        const fromY = Math.round(this.fromItem.cy)
        const toY = Math.round(this.toItem.cy)

        const ctx = this.canvas.ctx
        const directions = { left: -1, right: 1 }

        const lineType = this.canvas?.settings?.lineType || LINE_TYPES.BEZIER
        const offset = lineType === LINE_TYPES.FLOWCHART ? 30 : (lineType === LINE_TYPES.STRAIGHT ? 20 : 100)
        const endPointSize = 3
        const arrowHeight = 5
        const arrowLength = 8
        let color = this.toItem?.table?.color || this.canvas?.settings?.lineColor || '#bbb'
        let lineWidth = 1

        // let isFromHovered = this.fromRow.table.isHoveredObject
        // let isToHovered = this.toRow.table.isHoveredObject
        // if (isFromHovered || isToHovered) {
        //     color = this.toRow?.table?.color || cssVar('--primary')
        //     lineWidth = 2
        //
        //     this.toRow.highlighted = true
        //     if (isToHovered) this.fromRow.highlighted = true
        //
        //     ctx.globalCompositeOperation = 'source-over'
        //     this.toRow.draw()
        //     if (isToHovered) this.fromRow.draw()
        //     ctx.globalCompositeOperation = 'destination-over'
        // }

        ctx.lineWidth = lineWidth
        ctx.fillStyle = color
        ctx.strokeStyle = color

        let cp1x = fromX + offset * directions[from]
        let cp1y = fromY
        let cp2x = toX + offset * directions[to]
        let cp2y = toY


        // the dot
        canvasHelper.circle(ctx, fromX, fromY, endPointSize)
        ctx.fill()

        // the line
        ctx.beginPath()
        ctx.moveTo(fromX, fromY)
        ctx.setLineDash([6]);
        if (lineType === LINE_TYPES.FLOWCHART) {
            const cpx = Math.max(cp1x, cp2x)
            ctx.lineTo(cpx, cp1y)
            ctx.lineTo(cpx, cp2y)
            ctx.lineTo(toX, toY)
        } else if (lineType === LINE_TYPES.STRAIGHT) {
            ctx.lineTo(cp1x, cp1y)
            ctx.lineTo(cp2x, cp2y)
            ctx.lineTo(toX, toY)
        } else {
            ctx.bezierCurveTo(cp1x, cp1y, cp2x, cp2y, toX, toY)
        }
        ctx.stroke()
        ctx.closePath()

        // the arrow
        ctx.beginPath()
        ctx.moveTo(toX, toY)
        ctx.lineTo(toX + arrowLength * directions[to], toY + arrowHeight)
        ctx.lineTo(toX + arrowLength * directions[to], toY - arrowHeight)
        ctx.lineTo(toX, toY)
        ctx.fill()
        ctx.closePath()
    }
}
