<template>
    <div class="d-flex align-items-center justify-content-center">
        <select v-model="_value" class="dbd-editable mr-1" v-if="type === 'select'">
            <option v-for="option in ddOptions" :value="option[ddValueField] || option">
                {{ option[ddLabelField] || option }}
            </option>
        </select>

        <input v-select-all-on-focus :type="type" v-else v-model="_value" class="dbd-editable mr-1">

        <template v-if="modelValue != _value">
            <button class="btn-icon text-success" @click="ok">
                <check-bold-icon/>
            </button>
            <button class="btn-icon text-muted" @click="cancel">
                <close-thick-icon/>
            </button>
        </template>
    </div>
</template>

<script>
import CheckBoldIcon from "nvd-u/icons/CheckBold.vue";
import CloseThickIcon from "nvd-u/icons/CloseThick.vue";

export default {
    name: "Editable",
    components: { CloseThickIcon, CheckBoldIcon },
    props: {
        modelValue: {},
        type: { default: 'text' },
        ddOptions: {
            type: Array,
            default: [],
        },
        ddLabelField: {},
        ddValueField: {},
        defaultValue: {},
        needsConfirmation: {
            type: Boolean,
            default: false
        }
    },
    data: () => ({
        _value: '',
    }),
    methods: {
        ok() {
            this.$emit('update:modelValue', this._value)
        },
        cancel() {
            this._value = this.modelValue
            this.$el.querySelector('.dbd-editable').blur()
        },
        updateWidth(w) {
            this.$el.querySelector('.dbd-editable').style.width = `${w + 2}ch`
        },
    },
    computed: {
        // needsConfirmation() {
        //     return false
        // return !['select', 'checkbox', 'radio'].includes(this.type)
        // disabled confirmation for all types upon customer request
        // }
    },
    mounted() {
        if (this.type === 'checkbox') {
            this.$emit('update:modelValue', !!this.modelValue)
        }

        if (this.modelValue) {
            this._value = this.modelValue
        } else {
            this._value = this.defaultValue
        }
    },
    watch: {
        _value(v) {
            this.updateWidth(v?.length || 3)
            if (!this.needsConfirmation) {
                this.ok()
            }
        },
        modelValue(v) {
            this._value = this.type === 'checkbox' ? !!v : v
        },
    },
}
</script>

<style scoped lang="scss">
.dbd-editable {
    padding: 0.25em 0.5em;
    max-width: 20em;
    min-width: 1ch;
    width: 5ch;
    height: fit-content;
    min-height: 2em;
    border: 1px solid #ddd;
    background-color: var(--body-bg);
    flex-grow: 1;
    border-radius: 0.5em;
    color: var(--main-text-color);

    &[type=checkbox], &[type=radio] {
        width: 1em;
        max-width: 1em;
        border: none;
    }

    &[type=color] {
        --color-input-size: 2em;
        padding: 0;
        width: var(--color-input-size);
        height: var(--color-input-size);
        min-height: var(--color-input-size);
        max-width: var(--color-input-size);
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}
</style>
