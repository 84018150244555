<script lang="ts" setup>
import {useAuthStore} from '@/stores/auth.store'
import {useSchemaStore} from '@/stores/schema.store'
import UButton from 'nvd-u/components/UButton.vue'
import PlusIcon from 'nvd-u/icons/Plus.vue'
import LogoutIcon from 'nvd-u/icons/Logout.vue'
import SidebarLink from 'src/components/layout/sidebar/SidebarLink.vue'
import MenuIcon from "@/components/layout/sidebar/MenuIcon.vue";
import {useBillingStore} from "@/stores/billing.store";
import {onMounted} from "vue";

const auth = useAuthStore()
const schema = useSchemaStore()
const billing = useBillingStore()


onMounted(() => {
    if (!billing.stripeProducts.loaded) billing.stripeProducts.send()
})
</script>

<template>
    <div class="main-sidebar p-4 my-4 d-flex flex-column gap-2">
        <UButton outline class="mb-4" @click="schema.openNewProject" style="box-shadow: var(--shadow-1)">
            <PlusIcon/>
            {{ $t('dashboard.new_project') }}
        </UButton>
        <SidebarLink path="/dashboard">
            <MenuIcon name="dashboard"/>
            {{ $t('dashboard.dashboard') }}
        </SidebarLink>
        <SidebarLink path="/team">
            <MenuIcon name="team"/>
            {{ $t('dashboard.team') }}
        </SidebarLink>
        <SidebarLink path="/downloads">
            <MenuIcon name="downloads"/>
            {{ $t('dashboard.downloads') }}
        </SidebarLink>
        <SidebarLink @click="auth.logout" path='/'>
            <LogoutIcon/>
            {{ $t('menu.logout') }}
        </SidebarLink>
    </div>
</template>

<style lang="scss">
.main-sidebar {
}
</style>
