<script lang="ts" setup>

</script>

<template>
    <img src="@/assets/images/logo.png" alt="Logo.png" style="width: 70%"/>
</template>

<style scoped lang="scss">
</style>
