import { defineStore } from "pinia";
import { FetchRequest } from "../helpers/fetch-request";
import { newTab, tabsSample } from "@/data/tab-template";
import i18n from "@/locales/translations";
import { useMenuStore } from "@/stores/menu.store";
import { useTabsStore } from "@/stores/tabs.store";
import { SortItemsCommand } from "@/commands/SortItemsCommand";
import { env } from "@/env";
import router from "@/router/routes";
import { useNotify } from "nvd-u/composables/Notifiy";
import { useConfirm } from "nvd-u/composables/Confirm";
import { tempEmailProviders } from "../helpers/temporary-email-providers";
import { access_modes } from "../data/constants";
import { useAuthStore } from "./auth.store";
import { useVersionsStore } from "./versions.store";
import { newField } from "../helpers/table-helper";
import { SchemaToDbml } from "../components/code-editor/schemaToDbml";
import { onEditorEvents } from "@/events/realtime-collaboration-editor-events";

const notify = useNotify()
const aiForm = { prompt: '', error: '', loading: false }


export const useSchemaStore = defineStore('schema', {
    state: () => ({
        loadSchemasReq: new FetchRequest('schemas', 'GET').withProps({
            pagination: true
        }),
        deleteSchemaReq: new FetchRequest('', 'DELETE'),
        documentationReq: new FetchRequest('', 'POST'),
        singleSchemaReq: new FetchRequest(''),
        saveSchemaReq: new FetchRequest('', 'GET', { delay: 500 }),
        schemaTemplatesReq: new FetchRequest('schemas/templates', 'GET'),
        feedbackReq: new FetchRequest('feedbacks', 'POST'),
        importSqlWithAiReq: new FetchRequest('schemas/import_by_ai', 'POST'),
        importSqlReq: new FetchRequest('schemas/import_sql', 'POST'),
        importLoading: false,
        createTablesReq: new FetchRequest('', 'POST'),
        createResourceReq: new FetchRequest('', 'POST'),
        deleteResourceReq: new FetchRequest('', 'DELETE'),
        editProjectModal: false,
        editingProject: {},
        showAdvancedEditingFields: false,
        cloneSchemaReq: new FetchRequest('', 'POST'),
        checkLimitReq: new FetchRequest('', 'POST'),
        savingNewTable: false,
        savingNewNote: false,
        aiForm: { ...aiForm },
        saveEntireSchemaReq: new FetchRequest('', 'POST'),
        createUsingAiReq: new FetchRequest('template_with_ai', 'POST'),
        bulkResourceReq: new FetchRequest('', 'POST'),
    }),
    getters: {
        ownSchemas: (state) => state.loadSchemasReq?.data?.own_schemas || [],
        sharedSchemas: (state) => state.loadSchemasReq?.data?.shared_schemas || [],
        archiveSchemas: (state) => state.loadSchemasReq?.data?.archive_schemas || [],
        default_types: (state) => {
            const tab = useTabsStore()
            return tab.aiDbTypes['default']?.filter(t => !['generic'].includes(t))?.sort()?.map(t => {
                return {
                    label: t,
                    value: t
                }
            }) || []
        },
        ai_types: (state) => {
            const tab = useTabsStore()
            return tab.aiDbTypes['ai']?.sort()?.map(t => {
                return {
                    label: `${t}*`,
                    value: t,
                    ai: true
                }
            }) || []
        },
        exported_types: (state) => {
            const tab = useTabsStore()
            return tab.aiDbTypes['exported']?.sort()?.map(t => {
                return {
                    label: `${t}`,
                    value: t,
                    ai: true
                }
            }) || []
        },
        db_types: (state) => {
            let types = state.default_types.concat(state.ai_types)
            if (types.length) return types
            return state.exported_types
        },
    },
    actions: {
        // mutations
        resetDocumentationReq() {
            this.documentationReq = new FetchRequest('', 'POST')
        },
        toggleEditProjectModal(payload) {
            this.editProjectModal = payload
        },
        setEditingProject(payload) {
            this.editingProject = payload
        },
        setShowAdvancedEditingFields(payload) {
            this.showAdvancedEditingFields = payload
        },
        // actions
        async loadSchema(schemaId) {
            if (!schemaId) { // try to read the url in not given
                schemaId = window.location.href.match(/designer\/schema\/(.+)/)
                schemaId = schemaId?.length ? schemaId[1] : ''
            }

            const tabsStore = useTabsStore()
            const menu = useMenuStore()

            // if nothing in url either, just select the first one
            if (!schemaId || schemaId === 'new') {
                // check if there's any tab in the storage
                // if yes, open that tab
                // otherwise, redirect to the dashboard
                if (tabsStore.tabs.length)
                    tabsStore.selectTab(tabsStore.tabs[0])
                else
                    router.replace('/dashboard')
                return
            }

            // ok, we found the id, let's see if its loaded already
            let tab = tabsStore.tabs.find(tab => tab.schema.slug === schemaId)
            if (tab) {
                tabsStore.selectTab(tab, true)
                return
            }

            // we have the id, but it's not loaded. Let's ask the server
            this.singleSchemaReq.url = `schemas/${schemaId}`
            this.singleSchemaReq.send().then(async data => {
                let tabs = tabsStore.tabs
                let tab = { schema: data }
                // check if this schema is not already opened in another tab
                let existingTab = tabs.find(t => t.schema.id === tab.schema.id)
                if (existingTab) {
                    tabsStore.selectTab(existingTab, true)
                } else {
                    let openNewTab = true
                    if (tabsStore.showNewTabConfirmationDialogue) {
                        openNewTab = !(await useConfirm(i18n.global.t('open_new_tab_confirmation_title'), i18n.global.t('open_new_tab_confirmation_description'), {
                            okTitle: i18n.global.t('open_new_tab_yes'),
                            cancelTitle: i18n.global.t('open_new_tab_no')
                        }))
                    }
                    // check if user asked for opening in new or existing tab
                    if (openNewTab) {
                        this.openNewTab(tab)
                    } else {
                        this.replaceExistingTab(tab)
                    }
                }
                menu.toggleLoadSchemaModal(false)
                tabsStore.saveTabsInStorage(null)
                menu.determineMetaData(tab)
            }).catch(e => {
                if (!tabsStore.tabs[0]) {
                    window.location.href = '/'
                    return
                }
                tabsStore.selectTab(tabsStore.tabs[0])
            })
        },
        toggleSchemaTemplate(payload) {
            const tabs = useTabsStore()
            let schema = tabs.selectedTab.schema
            schema.title = payload.title
            schema.template = payload.template
        },
        deleteSchema(payload) {
            const tabs = useTabsStore()
            const menu = useMenuStore()
            let index = tabs.tabs.indexOf(tabs.tabs.selectedTab)
            this.deleteSchemaReq.url = `schemas/${payload.slug}`
            this.deleteSchemaReq.send().then((data) => {
                menu.toggleSchemaPropertiesModal(false)

                tabs.tabs.splice(index, 1)

                // remove from loaded schemas
                let schemas = this.loadSchemasReq.data?.own_schemas || []
                if (schemas.length) {
                    this.loadSchemasReq.data.own_schemas = schemas.filter(schema => schema.id !== payload.slug)
                }

                notify.success(i18n.global.t('alerts.schema_deleted'), i18n.global.t('alerts.schema_deleted_description'))

                if (payload.source === 'app') {
                    tabs.saveTabsInStorage(null)
                    if (!tabs.tabs.length) {
                        window.location.href = '/'
                        return
                    }

                    tabs.selectFirstTab(null)
                } else if (payload.source === 'dashboard') {
                    this.loadSchemasReq.send()
                }
            })
        },
        sortItems(save = true) {
            const tabs = useTabsStore()
            const sorted = (new SortItemsCommand(tabs.selectedTab)).execute()
            if (save) this.saveSortedItems()
        },
        async saveTable(payload, existing = null) {
            const tabs = useTabsStore()
            const menu = useMenuStore()
            this.updateFieldsOrder(existing)
            if (tabs.isSchemaReadonly || tabs.isTemplateReadonly) return
            if (menu.embedded) return
            let $creating = !payload.data.p_id || payload.data.p_id === 'new'
            if ($creating) {
                this.savingNewTable = true
            }
            this.createTablesReq.url = `schemas/${tabs.selectedTab.schema.slug}/create_tables`
            this.createTablesReq.config.body = JSON.stringify({
                "tables": [
                    payload.data
                ]
            })

            this.createTablesReq.send().then(res => {
                let updated_table = res.tables.find(t => t.name === payload.data.name || t.p_id === payload.data.p_id)
                if (updated_table && existing) {
                    for (const [key, value] of Object.entries(updated_table)) {
                        existing[key] = value
                    }
                }
            }).catch(() => {
                tabs.selectedTab.unsaved = true
            })
                .finally(() => {
                    if ($creating) {
                        this.savingNewTable = false
                    }
                })
        },
        async saveNote(payload) {
            let $creating = !payload.data.p_id || payload.data.p_id === 'new'
            if ($creating) {
                this.savingNewNote = true
            }
            return this.saveSchema(payload)
                .then(res => {
                    if (!res.updated_data) throw new Error('Created a new note but could not fetch it from server.')
                    return res
                }).catch(error => {
                })
                .finally(() => {
                    this.savingNewNote = false
                })
        },
        async saveNewSchema(payload = {
            title: 'Untitled',
            db: 'generic'
        }) {
            const menu = useMenuStore()
            const tabs = useTabsStore()
            let data = {
                schema: {}
            }
            for (const [key, value] of Object.entries(payload)) {
                data.schema[key] = value
            }

            if (this.ownSchemas.length) {
                let title, newTitle
                title = newTitle = payload.title
                let postfix = 1

                while (this.ownSchemas.find(schema => schema.title === newTitle)) {
                    newTitle = `${title}(${postfix})`
                    postfix++
                }
                data.schema.title = newTitle
            }

            this.saveSchemaReq.url = `schemas`
            this.saveSchemaReq.config.method = 'POST'
            this.saveSchemaReq.config.body = JSON.stringify(data)
            return this.saveSchemaReq.send()
                .then((data) => {
                    let schemas = this.loadSchemasReq.data.own_schemas || []
                    schemas.unshift(data)
                    // tabs.selectedTab.unsaved = false
                    menu.toggleCreateNewSchemaModalOpen(false)
                    // selecting this schema as the new tab±
                    let tab = newTab(data)
                    tabs.tabs.push(tab)
                    tabs.selectTab(tab)
                    // menu.determineMetaData(tab)
                    return notify.success(i18n.global.t('alerts.schema_created'))
                })
        },
        async saveEntireSchemaData() {
            const tabs = useTabsStore()
            this.saveEntireSchemaReq.url = `schemas/${tabs.selectedTab.schema.slug}/save_all_data`
            this.saveEntireSchemaReq.config.body = JSON.stringify(tabs.selectedTab.schema)
            this.saveEntireSchemaReq.send().then(schema => {
                tabs.selectedTab.schema = schema
                tabs.selectedTab.unsaved = false
                notify.success(i18n.global.t('alerts.schema_saved'))
            })
        },
        async saveSchema(payload = null) {
            const menu = useMenuStore()
            const tabs = useTabsStore()
            const versions = useVersionsStore()
            if (versions.isPreviewing) return
            if (menu.embedded) return
            if (tabs.isSchemaReadonly || tabs.isTemplateReadonly) return
            if (!tabs.selectedTab?.schema) return
            const creating = !tabs.selectedTab?.schema?.id
            if (creating) return await this.saveNewSchema()

            this.saveSchemaReq.url = `schemas/${tabs.selectedTab.schema.slug}`
            this.saveSchemaReq.config.method = 'PUT'

            // updating/creating a new resource i.e., note,table,field etc
            if (payload) {
                this.saveSchemaReq.config.body = JSON.stringify(payload.data)
            } else {
                let schema = {
                    template: tabs.selectedTab?.schema?.template,
                    db: tabs.selectedTab?.schema?.db,
                    title: tabs.selectedTab?.schema?.title,
                }
                this.saveSchemaReq.config.body = JSON.stringify(schema)
            }


            return this.saveSchemaReq.send().then(data => {
                // if we saved a schema i.e., we modified its title/db etc, then update locally
                if (!payload) {
                    let schemas = this.loadSchemasReq.data.own_schemas || []
                    let existingSchema = schemas.find(s => s.id === data.updated_data.id)
                    if (existingSchema) {
                        existingSchema.title = data.updated_data.title
                        existingSchema.db = data.updated_data.db
                    }

                    // updating browser tab title
                    document.title = data.updated_data.title + " | " + env.appName
                    // updating browser url
                    window.history.replaceState({}, data.updated_data.db, `/designer/schema/${tabs.selectedTab.schema.slug || tabs.selectedTab.schema.id}`)
                }

                if (payload && payload.saveSilently) return data
                return notify.success(i18n.global.t('alerts.schema_saved'))
            })
                .catch(error => {
                    return tabs.selectedTab.unsaved = true
                })
        },
        viewProject(slug) {
            window.open(`${env.designerUrl}/${slug}`, '_self')
        },
        async cloneProject(slug, title = null, callback = null) {
            if (this.cloneSchemaReq.loading) return
            this.cloneSchemaReq.url = `schemas/${slug}/clone`
            if (title)
                this.cloneSchemaReq.config.body = JSON.stringify({
                    title
                })
            this.cloneSchemaReq.send()
                .then(res => {
                    notify.success(i18n.global.t('alerts.project_cloned'))
                    this.loadSchemasReq.send()
                    if (callback) {
                        callback(res)
                    }
                })
                .catch(error => {
                    notify.error(error)
                })
        },
        async getSchemaData(slug) {
            if (this.singleSchemaReq.loading || this.saveSchemaReq.loading) return
            this.singleSchemaReq.url = `schemas/${slug}`
            let schema = this.singleSchemaReq.send()
                .then(async data => {
                    return schema = data
                })
                .catch(error => {
                    notify.error(error)
                    return null
                })
            if (!schema) {
                notify.error(i18n.global.t('alerts.no_schema'))
                return false
            }
            return schema
        },
        async saveEditedSchema(payload) {
            const tabs = useTabsStore()
            let schema = { ...this.editingProject, ...payload }
            delete schema['updated_at']
            delete schema['slug']
            delete schema['id']

            this.saveSchemaReq.url = `schemas/${this.editingProject.slug}`
            this.saveSchemaReq.config.method = 'PUT'
            this.saveSchemaReq.config.body = JSON.stringify(schema)

            this.saveSchemaReq.send()
                .then(() => {
                    this.loadSchemasReq.send()
                    notify.success(i18n.global.t('alerts.schema_updated'))
                }).catch(() => tabs.selectedTab.unsaved = true)

        },
        async deleteProject(schema) {
            const res = await useConfirm(i18n.global.t('dialog.properties_delete_schema'), i18n.global.t('alerts.delete_schema'), {
                okTitle: i18n.global.t('dialog.ok'),
                cancelTitle: i18n.global.t('dialog.cancel')
            })
            if (!res) return 'cancel'
            return this.deleteSchema({
                slug: schema.slug,
                source: 'dashboard'
            })
        },
        undoArchive(schema) {
            return
        },
        manageTeam(schemaId) {
            router.push({
                path: `/project/team/${schemaId}`,
                params: {
                    id: schemaId
                }
            })
        },
        manageRevisions(slug) {
            router.push({
                path: `/dashboard/project-revisions/${slug}`,
                params: {
                    slug: slug
                }
            })
        },
        editProject(payload) {
            this.setEditingProject({ ...payload.schema })
            this.setShowAdvancedEditingFields(payload.showAdvancedFields)
            this.toggleEditProjectModal(true)
        },
        async addCollaborator(payload) {
            const menu = useMenuStore()
            const tab = useTabsStore()
            const auth = useAuthStore()
            if (payload.data.hasOwnProperty('email') && tempEmailProviders.some(v => payload.data.email.includes(v))) {
                notify.error(i18n.global.t('alerts.temporary_email'))
                return
            }
            if (!auth.user.verified) {
                const confirm = await useConfirm(`Your email ${auth.user.email} is not verified.`, 'Please verify your email to be able to access this feature', {
                    okTitle: 'Verify Email',
                    cancelTitle: 'Cancel'
                })
                if (confirm) auth.verifyEmail()
                return
            }
            const confirm = await useConfirm(i18n.global.t('dialog.confirm'),
                payload.data.email ?
                    i18n.global.t(`alerts.share_schema_${access_modes.find(m => m.value === payload.data.access_mode).title}_email`, { email: payload.data.email })
                    :
                    i18n.global.t(`alerts.share_schema_${access_modes.find(m => m.value === payload.data.access_mode).title}_this user`))
            if (!confirm) return
            let inviteReq = menu.inviteUserReq
            if (payload.data.id) {
                inviteReq.url = `schemas/${payload.schemaId}/collaborators/${payload.data.id}`
                inviteReq.config.method = 'PUT'
            } else {
                inviteReq.url = `schemas/${payload.schemaId}/collaborators`
                inviteReq.config.method = 'POST'
            }
            inviteReq.config.body = JSON.stringify(payload.data)
            return inviteReq.send()
                .then((data) => {
                    notify.success(i18n.global.t('alerts.collaborator_added'))
                    auth.reFetchUser()
                    return data
                })
                .catch(error => false)
        },
        async removeCollaborator(payload) {
            const auth = useAuthStore()
            const menu = useMenuStore()
            const tab = useTabsStore()
            const res = await useConfirm(i18n.global.t('dialog.confirm'), i18n.global.t('dialog.remove_collaborator'))
            if (!res) return false
            let selectedTab = tab.tabs.find(t => t.schema.id === payload.schemaId)
            if (selectedTab) {
                let index = selectedTab.schema.members.findIndex(m => m.id === payload.memberId)
                if (index !== -1)
                    selectedTab.schema.members.splice(index, 1)
            }
            let req = menu.deleteInvitedUserReq
            req.url = `schemas/${payload.schemaId}/collaborators/${payload.memberId}`
            req.send().then(() => {
                notify.success(i18n.global.t('alerts.collaborator_removed'))
                auth.reFetchUser()
            })
                .catch((error) => {
                    notify.error(error)
                })
            return true
        },
        loadSchemaCollaborators(schemaId) {
            const menu = useMenuStore()
            let req = menu.getSchemaCollaboratorsReq
            req.url = `schemas/${schemaId}/collaborators`
            req.send()
        },
        replaceExistingTab(tab) {
            const tabsStore = useTabsStore()
            const tabs = tabsStore.tabs

            if (!tabs.length) {
                tabs.push(tab)
                tabsStore.selectTab(tab, true)
                this.openAppWalkThrough(tab)
                return
            }

            if (tabsStore.selectedTab) {
                let index = tabs.findIndex(t => t.schema.id === tabsStore.selectedTab.schema.id)
                if (index !== -1) {
                    tabs[index] = tab
                    tabsStore.selectTab(tab, true)
                }
            } else {
                tabs.push(tab)
                tabsStore.selectTab(tab, true)
            }

            this.openAppWalkThrough(tab)
        },
        openNewTab(tab) {
            const tabsStore = useTabsStore()
            const menu = useMenuStore()
            let tabs = tabsStore.tabs
            tabs.push(tab)
            tabsStore.selectTab(tab, true)
            this.openAppWalkThrough(tab)
            // menu.determineMetaData(tab)
        },
        openAppWalkThrough(tab) {
            // enable walk-through for guest template on first visit only
            if (tab.schema.slug === 'guest_template') {
                const menu = useMenuStore()
                menu.handleMenuItemClick({
                    id: 'show_tutorial'
                })
            }
        },
        openNewProject() {
            const menu = useMenuStore()
            // router.push('/designer/schema/new')
            menu.toggleCreateNewSchemaModalOpen(true)
        },
        async checkLimit() {
            const auth = useAuthStore()
            const tab = useTabsStore()
            if (!auth.user['is_pro?']) {
                let schemaId = tab.selectedTab?.schema?.id
                this.checkLimitReq.url = `schemas/${schemaId}/check_limits`
                let result
                let error
                await this.checkLimitReq.send()
                    .then(res => {
                        result = res.status === 'success'
                        error = res.message
                    })
                    .catch(e => {
                        // check limits manually
                        result = tab.selectedTab.schema.schema_data.tables.length <= auth.user.tables_limit
                    })
                if (!result) {
                    auth.showPlanLimitReachedModal(error ? error : `Sorry, you have reached your plan limit. Your current plan allows Max ${auth.user.tables_limit} Database Models or Projects. Please upgrade your plan by clicking on the upgrade link or visit your account dashboard in a separate tab/window and click on upgrade plan under billing settings.`)
                    return false
                } else return true
            } else
                return true
        },
        addNewField(table = null, index = null) {
            const tab = useTabsStore()
            let canvas = tab.selectedTab?.canvas
            if (!canvas) return
            let editedTable = table || canvas.editedTable
            if (!editedTable) return
            let field = { ...newField }
            let postfix = 1
            let name = field.name
            while (editedTable.fields?.find(f => f.name === field.name)) {
                field.name = `${name}_${postfix}`
                postfix++
            }
            if (index) {
                field.order = index + 1
                editedTable.fields.splice(index, 0, field)
            } else {
                field.order = editedTable.fields.length + 1
                editedTable.fields.push(field)
            }
        },
        updateFieldsOrder(table = null) {
            const tab = useTabsStore()
            let canvas = tab.selectedTab?.canvas
            if (!canvas) return
            let editedTable = table || canvas.editedTable
            if (!editedTable || !editedTable.fields || !editedTable.fields.length) return

            // Update the order property of all items
            editedTable.fields.forEach((item, index) => {
                item.order = index + 1;
            });

            // update dbml
            new SchemaToDbml(tab.selectedTab).convert()
        },
        saveSortedItems() {
            const tabs = useTabsStore()
            const menu = useMenuStore()
            if (menu.embedded) return
            // save schema
            if (!tabs.selectedTab?.schema?.slug || tabs.isSchemaReadonly || tabs.isTemplateReadonly) return
            let sortItemsReq = new FetchRequest(`schemas/${tabs.selectedTab.schema.slug}/sort_items`, 'POST')
            let items = tabs.selectedTab.schema.schema_data.tables.concat(tabs.selectedTab.schema.schema_data.notes).map(item => {
                return {
                    p_id: item.p_id,
                    resource: item.resource,
                    top: item.top,
                    left: item.left
                }
            })
            sortItemsReq.config.body = JSON.stringify({
                data: items
            })
            sortItemsReq.send().catch(() => tabs.selectedTab.unsaved = true)
        },
        savePastedItems(payload) {
            const tabs = useTabsStore()
            if (!tabs.selectedTab?.schema?.slug || tabs.isSchemaReadonly || tabs.isTemplateReadonly) return
            this.bulkResourceReq.url = `schemas/${tabs.selectedTab.schema.slug}/bulk_resource`
            this.bulkResourceReq.config.body = JSON.stringify(payload)
            return this.bulkResourceReq.send().catch(() => tabs.selectedTab.unsaved = true)
        },
        createSchemaUsingAi() {
            this.aiForm.error = ''
            this.aiForm.loading = true
            this.createUsingAiReq.config.body = JSON.stringify({ content: this.aiForm.prompt })
            this.createUsingAiReq.send()
                .then((data) => {
                    onEditorEvents({ id: data.schema_id })
                    notify.success('', 'Generating your schema in real time. It will take a few moments.', {
                        permanent: true
                    })
                    return data
                })
                .catch((error) => {
                    this.aiForm.error = error
                    this.aiForm.loading = false
                })
        }
    }
})
