import { isMacOs } from "@/helpers/osHelper.js";
import WebIcon from "nvd-u/icons/Web.vue";
import ShareVariantIcon from "nvd-u/icons/ShareVariant.vue";
import CodeBracesIcon from "nvd-u/icons/CodeBraces.vue";
import AnimationPlayIcon from "nvd-u/icons/AnimationPlay.vue";
import KeyboardIcon from "nvd-u/icons/Keyboard.vue";
import CommentIcon from "nvd-u/icons/Comment.vue";
import ChatQuestionIcon from "nvd-u/icons/ChatQuestion.vue";
import DatabaseIcon from "nvd-u/icons/Database.vue";
import FilePdfIcon from "nvd-u/icons/FilePdf.vue";
import FileImageIcon from "nvd-u/icons/FileImage.vue";
import RobotIcon from "nvd-u/icons/Robot.vue";
import PencilIcon from "nvd-u/icons/Pencil.vue";
import ViewListIcon from "nvd-u/icons/ViewList.vue";
import ContentSaveIcon from "nvd-u/icons/ContentSave.vue";
import ContentDuplicateIcon from "nvd-u/icons/ContentDuplicate.vue";
import HistoryIcon from "nvd-u/icons/History.vue";
import CogOutlineIcon from "nvd-u/icons/CogOutline.vue";
import PlayBoxIcon from "nvd-u/icons/PlayBox.vue";
import { KeyboardShortcut } from "@/classes/KeyboardShortcut";
import i18n from "@/locales/translations";

// we use separate variables for certain items because they are also used in context menu:
export const tableMenuItem = {
    id: "table",
    title: "new_table",
    shortCutKey: new KeyboardShortcut(i18n.global.t('dialog.insert_new_table'), 'T', true, { alt: true }).formattedKeys,
    disabled: false
}

export const copyMultiple = {
    id: 'copy_multiple',
    title: "copy_multiple_items",
    disabled: false
}

export const deleteMultiple = {
    id: 'delete_multiple',
    title: "delete_multiple_items",
    disabled: false
}

export const noteMenuItem = {
    id: "note",
    title: "new_note",
    shortCutKey: new KeyboardShortcut(i18n.global.t('dialog.insert_new_note'), 'N', true, { alt: true }).formattedKeys,
    disabled: false
}

export const sortMenuItem = {
    id: "sortItems",
    title: "sort_items",
    shortCutKey: (isMacOs() ? "⌘" : "Ctrl") + "+1",
    disabled: false
}

// export menu
export const exportMenu = [
    {
        id: 'sql',
        title: 'export_sql',
        description: 'Export as sql script',
        loginNeeded: true,
        icon: DatabaseIcon
    },
    {
        id: 'pdf',
        title: 'export_pdf',
        description: 'Export as pdf file',
        loginNeeded: true,
        icon: FilePdfIcon
    },
    {
        id: 'image',
        title: 'export_image',
        description: 'Export as image file',
        loginNeeded: true,
        icon: FileImageIcon
    },
    // {
    //     id: 'ai',
    //     title: 'export_ai',
    //     description: 'Export sql script via AI',
    //     loginNeeded: true,
    //     icon: RobotIcon
    // },
]

export const helpMenu = [
    {
        id: 'show_tutorial',
        title: "show_tutorial",
        description: 'View step by step tutorial',
        shortCutKey: new KeyboardShortcut(i18n.global.t('menu.show_tutorial'), 'U', false, { alt: true }).formattedKeys,
        disabled: false,
        icon: AnimationPlayIcon
    },
    {
        id: "keyboardShortcuts",
        title: "shortcuts",
        description: 'View quick keyboard shortcuts',
        shortCutKey: new KeyboardShortcut(i18n.global.t('dialog.show_this_dialog'), 'K', true).formattedKeys,
        disabled: false,
        icon: KeyboardIcon
    }, {
        id: "feedBack",
        title: "Feedback",
        description: 'Provide valuable feedback',
        shortCutKey: "",
        disabled: false,
        icon: CommentIcon
    },
    {
        id: 'about',
        title: "About",
        description: 'Learn more about us',
        shortCutKey: "",
        disabled: false,
        icon: ChatQuestionIcon
    },
]

export const shareMenu = [
    {
        id: 'shortLink',
        title: "share_shortlink_button",
        description: "Get sharable short link",
        shortCutKey: "",
        disabled: false,
        loginNeeded: true,
        icon: WebIcon
    },
    {
        id: 'share',
        title: "Share",
        description: "Share with other members",
        shortCutKey: "",
        disabled: false,
        loginNeeded: true,
        icon: ShareVariantIcon
    },
    {
        id: 'embed',
        title: "Embed",
        description: "Get embed link",
        shortCutKey: "",
        disabled: false,
        loginNeeded: true,
        icon: CodeBracesIcon
    }
]

// file menu
export const fileMenu = [
    {
        id: "new",
        title: "New",
        description: "Create a new project",
        shortCutKey: new KeyboardShortcut(i18n.global.t('dialog.new_schema'), 'N', false, { alt: true }).formattedKeys,
        disabled: false,
        loginNeeded: false,
        icon: PencilIcon
    },
    {
        id: "loadSchema",
        title: "Load",
        description: "Open existing project or template",
        shortCutKey: new KeyboardShortcut(i18n.global.t('dialog.load_schema'), 'L', true).formattedKeys,
        loginNeeded: true,
        disabled: false,
        icon: ViewListIcon
    },
    {
        id: "save",
        title: "Save",
        description: "Save your current work",
        shortCutKey: new KeyboardShortcut(i18n.global.t('dialog.save_schema'), 'S', true).formattedKeys,
        loginNeeded: true,
        disabled: true,
        icon: ContentSaveIcon
    },
    {
        id: "saveAs",
        title: "Save As",
        description: "Save as another project",
        loginNeeded: true,
        shortCutKey: "",
        disabled: false,
        icon: ContentDuplicateIcon
    },
    {
        id: "history",
        title: "History",
        description: "Check history with timestamps",
        shortCutKey: new KeyboardShortcut(i18n.global.t('menu.history'), 'H', false, { alt: true }).formattedKeys,
        disabled: false,
        icon: HistoryIcon
    },
    {
        id: "schemaProperties",
        loginNeeded: true,
        title: "Properties",
        description: "Change different schema properties",
        shortCutKey: new KeyboardShortcut(i18n.global.t('menu.properties'), 'P', false, { alt: true }).formattedKeys,
        disabled: false,
        icon: CogOutlineIcon
    },
]

// view menu
export const viewMenu = [
    {
        id: 'simple_view',
        title: "simple_view",
        description: "Show only table headers",
        disabled: false
    },
    {
        id: 'detailed_view',
        title: "detailed_view",
        description: "Show everything",
        disabled: false
    },
    {
        id: 'presentation_view',
        title: "presentation_view",
        description: "Toggle presentation view",
        shortCutKey: '',
        disabled: false,
        hidden: true,
        icon: PlayBoxIcon
    },
    {
        id: 'physical_relationships_view',
        title: "physical_relationships_view",
        description: "Show only physical relationships",
        disabled: false,
        beta: true,
    },
    {
        id: 'logical_relationships_view',
        title: "logical_relationships_view",
        description: "Create/show only logical relationships",
        disabled: false,
        beta: true,
    },
]

// main menu
export const menuItems = [
    {
        title: 'View',
        subMenuItems: viewMenu,
    },
    {
        title: "Schema",
        subMenuItems: fileMenu
    },
    {
        title: "Export",
        subMenuItems: [
            {
                id: "sql",
                title: "Sql",
                shortCutKey: (isMacOs() ? "⌘" : "Ctrl") + "+E",
                loginNeeded: true,
                disabled: false
            },
            {
                id: 'image',
                title: "Image",
                shortCutKey: "",
                loginNeeded: true,
                disabled: false
            },
            {
                id: 'pdf',
                title: "PDF",
                shortCutKey: "",
                loginNeeded: true,
                disabled: false
            },
        ]
    },
    {
        title: "Help",
        subMenuItems: [
            {
                id: "index",
                title: "Index",
                shortCutKey: "",
                disabled: false
            },
            {
                id: "changes",
                title: "Changes",
                shortCutKey: "",
                disabled: false
            },
            {
                id: 'show_tutorial',
                title: "show_tutorial",
                shortCutKey: "",
                disabled: false,
            },
            {
                id: "keyboardShortcuts",
                title: "shortcuts",
                shortCutKey: (isMacOs() ? "⌘" : "Ctrl") + "+K",
                disabled: false
            }, {
                id: "feedBack",
                title: "Feedback",
                shortCutKey: "",
                disabled: false
            },
            {
                id: 'about',
                title: "About",
                shortCutKey: "",
                disabled: false
            },
        ]
    },
    {
        title: "Edit",
        subMenuItems: [
            {
                id: 'undo',
                title: 'Undo',
                shortCutKey: (isMacOs() ? "⌘" : "Ctrl") + "+Z",
                disabled: false
            },
            {
                id: 'redo',
                title: 'Redo',
                shortCutKey: (isMacOs() ? "⌘" : "Ctrl") + "+Y",
                disabled: false
            },
            {
                id: 'select-all',
                title: 'select_all',
                shortCutKey: (isMacOs() ? "⌘" : "Ctrl") + "+A",
                disabled: false
            },
            {
                id: 'select-none',
                title: 'select_none',
                shortCutKey: "",
                disabled: false
            },
        ],
    },
    {
        title: "Insert",
        subMenuItems: [
            tableMenuItem,
            noteMenuItem,
        ]
    },
    {
        title: "Togglers", subMenuItems: [
            {
                id: "toggle_sidebar",
                title: "show_sidebar",
                shortCutKey: (isMacOs() ? "⌥" : "Alt") + "+B",
                disabled: false
            },
            {
                id: "toggle_minimap",
                title: "show_minimap",
                shortCutKey: (isMacOs() ? "⌥" : "Alt") + "+M",
                disabled: false
            },
            {
                id: "toggle_grid",
                title: "show_grid",
                shortCutKey: (isMacOs() ? "⌥" : "Alt") + "+G",
                disabled: false
            },
        ]
    }

    /*{
        title: "Documentation",
        subMenuItems: [
            {
                id: "documentation",
                title: "Documentation",
                shortCutKey: "",
                disabled: false
            },
        ]
    },
    */
]
