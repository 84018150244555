import { Command } from "@/commands/Command.js"
import { FetchRequest } from "../helpers/fetch-request";

export class CreateNoteCommand extends Command {
    _description = "Create Note"

    async run() {
        const { note } = this.payload
        this.tab.schema.schema_data.notes.push(note)

        // call schema save request here
        const schema = ((await import('../stores/schema.store')).useSchemaStore)()
        const res = await schema.saveNote({
            data: {
                ...note,
                resource: "note"
            },
            saveSilently: true
        })
        if (res && res.updated_data) {
            for (const [key, value] of Object.entries(res.updated_data)) {
                note[key] = value
            }
        }

        super.run()
    }

    undo() {
        const { note } = this.payload
        let request = new FetchRequest(`schemas/${this.tab.schema.slug}/remove_resource`, 'DELETE')
        request.config.body = JSON.stringify({ data: [note] })
        request.send().catch(() => this.tab.unsaved = true)
        this.tab.schema.schema_data.notes.splice(this.tab.schema.schema_data.notes.indexOf(note), 1)
        super.undo()
    }

    redo() {
        const { note } = this.payload
        note.p_id = 'new'
        super.redo()
    }
}
