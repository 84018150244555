import { Command } from "@/commands/Command.js"
import { _deepClone } from "nvd-js-helpers/misc";

export class UpdateTableCommand extends Command {
    _description = "Edit Table"
    oldData = {}

    async saveUpdatedTable(data, table) {
        this.updateDbml()
        const schema = ((await import('../stores/schema.store')).useSchemaStore)()
        return await schema.saveTable({
            data,
            saveSilently: true
        }, table)
    }

    async run() {
        const { table, data } = this.payload
        this.oldData = _deepClone(table)

        for (const [key, value] of Object.entries(data)) {
            table[key] = value
            if (key === 'checked') {
                table.fields.forEach(field => {
                    field[key] = value
                })
            }
        }
        await this.saveUpdatedTable(data, table)
        super.run()
    }

    async undo() {
        const { table } = this.payload
        for (const [key, value] of Object.entries(this.oldData)) {
            table[key] = value
            if (key === 'checked') {
                table.fields.forEach(field => {
                    field[key] = value
                })
            }
        }
        await this.saveUpdatedTable(this.oldData, table)
        super.undo()
    }
}
