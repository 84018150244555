import { useTabsStore } from "../../stores/tabs.store";

export class Mouse {
    x = 0
    y = 0
    lastX
    lastY
    clicked = false
    pressed = false
    moving = false
    mouseUp = false
    canvas

    get dragging() {
        return this.pressed && this.moving
    }

    get dx() {
        return this.x - this.lastX
    }

    get dy() {
        return this.y - this.lastY
    }

    async movingCanvasOrShapeStuff(e) {
        const tab = useTabsStore()
        const versions = ((await import('../../stores/versions.store')).useVersionsStore)()
        this.moving = true

        if (this.pressed) {
            if (this.canvas.hoveredObject && !versions.isPreviewing && !tab.selectedTab?.isImporting) { // dragging an object
                if (this.canvas.selectedLogicalTable) return
                if (!this.canvas.draggedObject) { // dont select a new object if already dragging
                    this.canvas.setDraggedObject(this.canvas.hoveredObject)
                }
                // fix: dragging over the toolbar makes things go crazy
                this.canvas.setSelectedObject(null)
            } else { // dragging the canvas
                this.canvas.updateOrigin(this.dx + this.canvas.lastOrigin.x, this.dy + this.canvas.lastOrigin.y)
            }
        }
        e.preventDefault()
        e.stopPropagation()
    }

    canvasOrShapeClicked() {
        this.pressed = true

        this.canvas.lastOrigin.x = this.canvas.origin.x
        this.canvas.lastOrigin.y = this.canvas.origin.y
    }

    canvasOrShapeClickLifted() {
        this.pressed = false
        this.mouseUp = true

        this.canvas.onDragEnd()
    }

    async initialize(canvas) {
        this.canvas = canvas
        canvas.element.addEventListener('dblclick', e => {
            if (canvas.hoveredObject) {
                canvas.hoveredObject.fields ? canvas.setEditedTable(canvas.hoveredObject) : canvas.setEditedNote(canvas.hoveredObject)
            }
            e.stopPropagation()
            e.preventDefault()
        })

        canvas.element.addEventListener('click', e => {
            if (e.shiftKey) {
                canvas.selectedItems.toggle(canvas.hoveredObject)
                return
            }

            this.x = e.offsetX
            this.y = e.offsetY
            this.clicked = true
            this.pressed = false

            canvas.onSelect(canvas.hoveredObject)
        })

        canvas.element.addEventListener('touchstart', e => {

            // activate the toolbar for the item if any
            this.x = e.touches[0].pageX
            this.y = e.touches[0].pageY
            this.clicked = true
            this.pressed = false
            canvas.onSelect(canvas.hoveredObject)

            // update origin

            this.lastX = e.touches[0].pageX
            this.lastY = e.touches[0].pageY
            this.canvasOrShapeClicked()

        })

        canvas.element.addEventListener('mousedown', e => {
            if (e.button !== 0) return // only left click plz

            this.lastX = e.offsetX
            this.lastY = e.offsetY

            this.canvasOrShapeClicked()
        })

        canvas.element.addEventListener('touchend', e => {
            this.canvasOrShapeClickLifted()
        })

        canvas.element.addEventListener('mouseup', e => {
            this.canvasOrShapeClickLifted()
        })

        canvas.element.addEventListener('touchmove', e => {
            this.x = e.touches[0].pageX
            this.y = e.touches[0].pageY

            this.movingCanvasOrShapeStuff(e)
        })

        canvas.element.addEventListener('mousemove', e => {
            this.x = e.offsetX
            this.y = e.offsetY

            this.movingCanvasOrShapeStuff(e)
        })

        canvas.element.addEventListener('mouseleave', e => {
            this.pressed = false
            this.mouseUp = true
            canvas.onDragEnd()
            e.preventDefault()
            e.stopPropagation()
        })

        canvas.element.addEventListener('wheel', e => {
            if (!e.metaKey && !e.ctrlKey) {
                let x, y
                if (e.shiftKey) {
                    x = canvas.origin.x - Math.round(e.deltaY * 0.25)
                    y = canvas.origin.y
                } else {
                    x = canvas.origin.x
                    y = canvas.origin.y - Math.round(e.deltaY * 0.35)
                }
                canvas.updateOrigin(x, y)
                e.preventDefault()
                e.stopPropagation()
                return false
            }

            if (e.deltaY < 0) canvas.zoomIn()
            else canvas.zoomOut()

            e.preventDefault()
            e.stopPropagation()
            return false
        })
    }
}
