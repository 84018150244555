<script setup>
import { useSettingsStore } from "../../stores/settings.store";
import { LANGUAGES } from "../../data/constants";
import { useI18n } from "vue-i18n";
import Editable from "./Editable.vue";
import { useKbShortcutsStore } from "@/stores/keyboard-shortcuts.store";

const settings = useSettingsStore()
const t = useI18n()
const shortcuts = useKbShortcutsStore()

async function updateLang(evt) {
    let code = evt.target.value
    t.locale.value = code
    settings.changeLang(code)
    shortcuts.initialize()
}

</script>

<template>
    <editable v-model="settings.settings.language"
              type="select"
              class="flex-grow-1"
              :dd-options="LANGUAGES"
              @change="updateLang"
              dd-value-field="value"
              dd-label-field="title"/>
</template>
