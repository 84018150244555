import { defineStore } from "pinia";
import { FetchRequest } from "../helpers/fetch-request";
import { FileHelper } from "nvd-js-helpers/FileHelper";

export const useDownloadsStore = defineStore('downloads', {
    state: () => ({
        downloadsReq: new FetchRequest('downloads', 'GET')
    }),
    getters: {
        hasDownloads(state) {
            return state.downloadsReq.hasLoadedData
        },
        downloads(state) {
            return state.downloadsReq.data
        },
    },
    actions: {
        getDownloads() {
            this.downloadsReq.send()
        },
        downloadExportedSql(download) {
            FileHelper.downloadDataUrl(download.file_url, `${download.title}.sql`)
        },
    }
})