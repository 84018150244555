import {useAuthStore} from 'src/stores/auth.store'
import {env} from "@/env"

const socketIo = {
    io: null, // instance to listen editor events
    authIo: null, // instance to listen to events provided on user channel
    channels: []
}

export const useSocketIo = function (selectedTab) {
    const auth = useAuthStore()

    function subscribeToSchemaChannel() {
        if (socketIo.channels.includes(selectedTab)) return
        socketIo.channels.push(selectedTab)
        socketIo.io.send(JSON.stringify({
            "command": "subscribe",
            "identifier": `{"channel":"SchemaChannel", "id":"${selectedTab}"}`
        }))
    }

    if (!socketIo.io) {
        socketIo.io = new WebSocket(`wss://${env.socketIoServer}/cable?token=${auth.authToken}`)
        socketIo.io.onopen = () => {
            subscribeToSchemaChannel()
        }
    } else {
        subscribeToSchemaChannel()
    }
    return socketIo
}

export const useSocketIoAuth = function () {
    const auth = useAuthStore()

    function subscribeToUserChannel() {
        socketIo.authIo.send(JSON.stringify({
            "command": "subscribe",
            "identifier": `{"channel":"UserChannel", "id":"${auth.user.id}"}`
        }))
    }

    if (!socketIo.authIo) {
        socketIo.authIo = new WebSocket(`wss://${env.socketIoServer}/cable?token=${auth.authToken}`)
        socketIo.authIo.onopen = () => {
            subscribeToUserChannel()
        }
    } else {
        subscribeToUserChannel()
    }
    return socketIo.authIo
}