<script lang="ts" setup>
import MainLoader from '@/components/common/MainLoader.vue'
import {useAuthStore} from '@/stores/auth.store'
import UButton from 'nvd-u/components/UButton.vue'
import UDropdown from 'nvd-u/components/UDropdown.vue'
import UMenuItem from 'nvd-u/components/UMenuItem.vue'
import AccountOutlineIcon from 'nvd-u/icons/AccountOutline.vue'
import AccountIcon from 'nvd-u/icons/Account.vue'
import LogoutIcon from 'nvd-u/icons/Logout.vue'
import LoginIcon from 'nvd-u/icons/Login.vue'
import ViewDashboardIcon from 'nvd-u/icons/ViewDashboard.vue'
import CreditCardIcon from 'nvd-u/icons/CreditCard.vue'
import AccountBoxIcon from 'nvd-u/icons/AccountBox.vue'
import UserAvatar from 'src/components/common/UserAvatar.vue'
import {useRouter} from 'vue-router'
import {defineProps} from "vue";

const auth = useAuthStore()
const router = useRouter()

function logout() {
    auth.logout()
}

defineProps({
    compact: {
        type: Boolean,
        default: false
    },
    flat: {
        type: Boolean,
        default: false
    }
})
</script>

<template>
    <UDropdown v-if="auth.isLoggedIn" left>
        <UButton id="profile-btn" style="border: 1px solid var(--border-color)" icon secondary :compact="compact"
                 :flat="flat"
                 v-tooltip="'Profile'">
            <UserAvatar :user="auth.user"/>
        </UButton>
        <template #content>
            <div class="user-dd py-2" style="min-width: 12em;">
                <div class="text-muted text-small px-4 py-2">
                    <div class="font-weight-bold mb-2">{{ auth.user.name }}</div>
                    <div class="text-nowrap">{{ auth.user.email }}</div>
                </div>
                <hr class="my-2">
                <UMenuItem @click="router.push('/dashboard')">
                    <ViewDashboardIcon/>
                    {{ $t('dashboard.dashboard') }}
                </UMenuItem>
                <UMenuItem @click="router.push({name:'profile'})">
                    <AccountBoxIcon/>
                    {{ $t('dashboard.account') }}
                </UMenuItem>
                <hr class="my-2">
                <UMenuItem @click="logout">
                    <LogoutIcon/>
                    {{ $t('menu.logout') }}
                </UMenuItem>
            </div>
        </template>
    </UDropdown>
    <UDropdown v-else left>
        <UButton id="profile-btn" flat icon v-tooltip="'Profile'" secondary>
            <AccountOutlineIcon/>
        </UButton>
        <template #content>
            <div class="user-dd py-2" style="min-width: 12em;">
                <UMenuItem @click="router.push('/login')">
                    <LoginIcon/>
                    Login
                </UMenuItem>
                <UMenuItem @click="router.push('/register')">
                    <AccountIcon/>
                    Register
                </UMenuItem>
            </div>
        </template>
    </UDropdown>
    <MainLoader v-if="auth.logoutReq.loading"/>
</template>

<style scoped lang="scss">
</style>
