<template>
    <div class="schema-card shadow-sm p-4 d-flex align-items-center"
         @click="$emit('selected', schema)">
        <div class="icon">
            <img :alt="schema.db" :src="'/images/dbs/'+`${schema.db}`+'.svg'" class="imageSize"/>
        </div>
        <div>
            <h4 class="m-0">{{ $t(`${schema.title}`) }}</h4>
            <p class="my-2 text-muted" v-if="schema.description">{{ schema.description }}</p>
            <p class="my-2 text-muted" v-if="schema.updated_at">
                {{ dayjs.unix(schema.updated_at).format('DD MMM YYYY hh:mm A') }}
            </p>
            <p class="my-2 text-muted" v-if="schema.num_tables">{{schema.num_tables}} {{$t('menu.table')}}</p>
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
    name: "SchemaCard",
    props: {
        schema: {}
    },
    data: () => ({
        dayjs,
    }),
}
</script>

<style scoped lang="scss">
.schema-card {
    cursor: pointer;
    border-radius: 1em;
    border: 1px solid var(--light);
    background-color: var(--bg);

    &:hover {
        background-color: var(--primary);
        color: var(--bg);
    }

    .icon {
        margin-right: 1em;

        img {
            width: 2.5em;
        }
    }
}
</style>
