<script setup lang="ts">
import USwitch from 'nvd-u/components/USwitch.vue'
import MoonIcon from 'nvd-u/icons/WeatherNight.vue'
import SettingsItem from './SettingsItem.vue'
import {useTheme} from 'src/composables/useTheme'
import {useSettingsStore} from 'src/stores/settings.store'
import {computed} from 'vue'
import {markupThemes} from "@/data/constants";

const theme = useTheme()
const settings = useSettingsStore()
const darkMode = computed({
    get: () => theme.value === 'dark',
    set: v => {
        theme.setTheme(v ? 'dark' : 'light')
        settings.settings.markupTheme = theme.value === 'dark' ? markupThemes[1] : markupThemes[0]
    }
})
</script>

<template>
    <SettingsItem>
        <MoonIcon/>
        <div class="flex-grow-1">{{ $t('dialog.dark_mode') }}</div>
        <USwitch v-model="darkMode"/>
    </SettingsItem>
</template>

<style scoped lang="scss">

</style>
