import { colorHelper } from "@/helpers/color-helper"
import { canvasFont, colorDark, colorLight, fontSize } from "@/modules/canvas/styles"
import { TableChild } from "@/modules/canvas/table/TableChild.js"

export class TableHeader extends TableChild {
    paddingY = 0.75 * fontSize

    draw() {
        // rect
        let color = this.table.color || '#adb5bd'
        this.ctx.fillStyle = color
        this.ctx.fillRect(this.table.left, this.table.top, this.table.width, this.height())

        // text
        this.ctx.font = `bold ${canvasFont}`
        this.ctx.fillStyle = colorHelper.isLight(color) ? colorDark : colorLight
        this.ctx.fillText(this.table.name, this.table.left + this.paddingX, this.table.top + this.paddingY)
    }
}
