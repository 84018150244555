<script lang="ts" setup>
import {computed} from 'vue'

let props = defineProps<{
    user: {},
}>()

const alt = computed(() => {
    let name = props.user.name || props.user.email
    let cleanName = name.replace(/^[^a-zA-Z]*/, '') || name.replace(/^[^a-zA-Z\d]*/, '')
    return cleanName[0]?.toUpperCase()
})
</script>

<template>
    <img class="avatar sm"
         :src="user.avatar"
         :alt="alt"
    >
</template>

<style scoped lang="scss">
.avatar {
    max-width: var(--avatar-size);
    max-height: var(--avatar-size);
    min-width: var(--avatar-size);
    min-height: var(--avatar-size);
    font-size: var(--avatar-size);
    position: relative;
    overflow: hidden;

    &::after {
        content: attr(alt);
        position: absolute;
        font-size: 0.75em;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: grid;
        place-items: center;
        color: var(--light);
        background-color: var(--primary);
        z-index: 2;
        font-weight: 900;
    }
}
</style>
