import { defineStore } from "pinia";
import { useMenuStore } from "@/stores/menu.store";
import { useAuthStore } from "@/stores/auth.store";
import { useBillingStore } from "@/stores/billing.store";
import { useSchemaStore } from "@/stores/schema.store";
import { useTabsStore } from "@/stores/tabs.store";

export const useAppStore = defineStore('app', {
    state: () => ({
        generatingDbmlModal: false
    }),
    actions: {
        closeAllModals() {
            const menu = useMenuStore()
            const auth = useAuthStore()
            const billing = useBillingStore()
            const schema = useSchemaStore()
            const tab = useTabsStore()

            menu.appIntroModalOpen = false
            menu.criticalTableLimitReachedModalOpen = false
            menu.CreateNewSchemaModalOpen = false
            menu.loginModal = false
            menu.loadSchemaModal = false
            menu.schemaPropertiesModal = false
            menu.indexModal = false
            menu.keyBoardShortcuts = false
            menu.exportSql = false
            menu.exportAi = false
            menu.loadTemplatesModal = false
            menu.feedbackModal = false
            menu.importSqlModal = false
            menu.schemaSaveAsModal = false
            menu.documentationModal = false
            menu.shareModal = false
            menu.optionModal = false
            menu.embedModal = false
            menu.sharableLinkModal = false
            menu.lineTypeModal = false
            menu.exportAiLoading = false

            auth.planLimitReached = false

            billing.cancelSubscriptionModal = false
            billing.updateInvoiceModal = false
            billing.viewInvoiceModal = false

            schema.importLoading = false
            schema.editProjectModal = false

            tab.showNewTabConfirmationDialogue = false

            this.generatingDbmlModal = false
        }
    }
})