<script setup>

import AppLogo from "../../common/AppLogo.vue";
import ProfileBtn from "./ProfileBtn.vue";
import UpgradeNowBtn from "./UpgradeNowBtn.vue";
import { useAuthStore } from "../../../stores/auth.store";
import SchemaSettingsBtn from "../../../views/SchemaView/schema-settings/SchemaSettingsBtn.vue";
import UButton from "nvd-u/components/UButton.vue";
import MenuIcon from "nvd-u/icons/Menu.vue";
import UIconBtn from "nvd-u/components/UIconBtn.vue";
import Sidebar from "@/components/layout/sidebar/Sidebar.vue";
import { ref } from "vue";
import CloseIcon from "nvd-u/icons/Close.vue";
import { useOutsideClick } from "nvd-u/composables/useOutsideClick";

const auth = useAuthStore()

const sidebarEl = ref()
const sidebarVisible = ref(false)
const toggleSidebar = () => sidebarVisible.value = !sidebarVisible.value

useOutsideClick(sidebarEl, () => {
    if (sidebarVisible.value)
        toggleSidebar()
})
</script>

<template>
    <header class="main-header d-flex gap-2 align-items-center p-2">
        <UIconBtn class="menu-btn" transparent @click="toggleSidebar">
            <MenuIcon class="menu-icon"/>
        </UIconBtn>

        <transition name="slide-down">
            <div
                ref="sidebarEl"
                v-if="sidebarVisible"
                style="position: absolute;top: 0;left: 0;background: var(--disabled-bg);height: 100vh;display: flex;flex-direction: column;width: 330px;z-index: 99">
                <UIconBtn class="menu-btn" transparent @click="toggleSidebar"
                          style="position:absolute;right: 0.5em;top: 0.5em">
                    <CloseIcon style="color: var(--main-text-color);font-size: 1.2em;"/>
                </UIconBtn>
                <div style="margin-top: 2em">
                    <Sidebar/>
                </div>
            </div>
        </transition>


        <RouterLink to="/" class="home-link">
            <AppLogo/>
        </RouterLink>
        <div class="flex-grow-1"></div>
        <SchemaSettingsBtn/>
        <UpgradeNowBtn v-if="auth.user && !auth.user['is_pro?']" class="mr-3"/>
        <ProfileBtn/>
    </header>
</template>

<style scoped lang="scss">
.main-header {
    background-color: var(--primary);
    border-bottom: 1px solid var(--border-color);
    position: relative;
}

.home-link {
    border-bottom: none;
}

.separator {
    height: 1.5em;
}

</style>

<style lang="scss">

.main-heading {
    input {
        color: var(--muted);
        font-weight: bold;
        padding: 0.25em 0.5em;
    }
}


.menu-btn {
    display: none;

    .menu-icon {
        color: var(--bg);
    }
}

@media screen and (max-width: 768px) {
    .menu-btn {
        display: block;
    }
}


</style>
