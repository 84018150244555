// @ts-ignore
import U from 'nvd-u/index'
import 'nvd-u/u-core.scss'
import {createPinia} from 'pinia'
import 'src/styles/app.scss'
import {createApp} from 'vue'
import Main from './Main.vue'
// @ts-ignore
import router from './router/routes'
import i18n from "./locales/translations";
import { env } from "@/env"


// clear local storage
let appVersion = localStorage.getItem('appVersion')
if (!appVersion || appVersion !== env.appVersion) {
    console.log("in here once")
    localStorage.clear()
    window.location.reload()
}
localStorage.setItem('appVersion', env.appVersion)

const app = createApp(Main)
const pinia = createPinia()

app.use(pinia)
app.use(router)
app.use(U)
app.use(i18n)

app.mount('#app')
