import { Command } from "@/commands/Command.js"
import { DeleteResourceCommand } from "./DeleteResourceCommand";

export class DeleteTableCommand extends Command {
    _description = "Delete Table"
    tableIndex = null

    run() {
        const { table } = this.payload

        // deleting from local
        this.tableIndex = this.tab.schema.schema_data.tables.indexOf(table)
        this.tab.schema.schema_data.tables.splice(this.tableIndex, 1)

        // deleting from remote
        let command = new DeleteResourceCommand(this.tab.schema.slug, {
            data: [{
                p_id: table.p_id,
                resource: "table"
            }]
        })
        command.run()

        // updating markup
        this.updateDbml()

        super.run()
    }

    async undo() {
        const { table } = this.payload
        if (table.fields.length) {
            table.fields.forEach(field => delete field['p_id'])
        }
        this.tab.schema.schema_data.tables.splice(this.tableIndex, 0, table)
        this.updateDbml()

        // re-create the table
        const schema = ((await import('../stores/schema.store')).useSchemaStore)()
        const res = await schema.saveTable({
            data: {
                ...table,
                p_id: "new"
            },
            saveSilently: true
        }, table)

        super.undo()
    }
}
