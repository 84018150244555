import { fontSize } from "@/modules/canvas/styles"

export class TableChild {
    table
    left
    top
    paddingY = 0.75 * fontSize
    paddingX = fontSize

    constructor(table) {
        this.table = table
    }

    get ctx() {
        return this.table.canvas.ctx
    }

    height() {
        return fontSize + this.paddingY * 2
    }

    get right() {
        return this.left + this.table.width
    }

    get bottom() {
        return this.top + this.height()
    }
}
