<script setup>
import { computed, markRaw, onMounted, reactive, ref } from "vue";
import UModal from 'nvd-u/components/UModal.vue';
import UInput from 'nvd-u/components/UInput.vue';
import UButton from 'nvd-u/components/UButton.vue';
import UDropdown from 'nvd-u/components/UDropdown.vue'
import UMenuItem from 'nvd-u/components/UMenuItem.vue';
import SchemaList from "../../components/layout/schema/SchemaList.vue";
import i18n from "../../locales/translations";
import { useSchemaStore } from "../../stores/schema.store";
import { useMenuStore } from "../../stores/menu.store";
import Loader from "../../components/common/Loader.vue";
import { useTabsStore } from "../../stores/tabs.store";
import ChevronDownIcon from "nvd-u/icons/ChevronDown.vue";
import { useAuthStore } from "../../stores/auth.store";
import { useValidator } from "../../Vee/useValidator";
import { requiredRule } from '@/Vee/rules/required.rule'
import GenerateWithAi from "../../components/schema/GenerateWithAi.vue";
import PlusCircleMultipleOutline from 'nvd-u/icons/PlusCircleMultiple.vue';
import MagicIcon from 'nvd-u/icons/Creation.vue'
import ShareVariantIcon from "nvd-u/icons/ShareVariant.vue";
import AccountIcon from "nvd-u/icons/Account.vue";
import PencilIcon from "nvd-u/icons/Pencil.vue";
import { useRouter } from "vue-router";

const choices = reactive([
    {
        id: 'scratch',
        title: i18n.global.t('dialog.schema_scratch'),
        icon: markRaw(PencilIcon),
        loginNeeded: true
    },
    {
        id: 'templates',
        title: i18n.global.t('dialog.templates'),
        icon: markRaw(PlusCircleMultipleOutline),
    },
    {
        id: 'own_schemas',
        title: i18n.global.t('dialog.load_schema_your_schemas'),
        icon: markRaw(AccountIcon),
        loginNeeded: true
    },
    {
        id: 'shared_schemas',
        title: i18n.global.t('dialog.load_schema_shared_with_you'),
        icon: markRaw(ShareVariantIcon),
        loginNeeded: true
    },
    {
        id: 'ai',
        title: i18n.global.t('dialog.generate_with_ai'),
        icon: markRaw(MagicIcon),
        loginNeeded: true
    }
])

const choice = ref(choices[0])
const schema = useSchemaStore()
const menu = useMenuStore()
const tab = useTabsStore()
const auth = useAuthStore()
const router = useRouter()

const form = reactive({
    title: '',
    db: tab.dbTypes[0] || 'generic'
})

const getSchemas = computed(() => {
    let id = choice.value.id
    switch (id) {
        case 'templates':
            return schema.schemaTemplatesReq.data.filter(t => !!t.featured);
            break;
        case 'own_schemas':
            return schema.loadSchemasReq.data.own_schemas;
            break;
        case 'shared_schemas':
            return schema.loadSchemasReq.data.shared_schemas
            break;
    }
})

function load(s) {
    menu.toggleCreateNewSchemaModalOpen(false)
    menu.toggleLoadSchemaModal(false)
    schema.loadSchema(s.slug)
}

const v = reactive(useValidator(form, (v) => {
    v.addRule(requiredRule('title'))
    v.addRule(requiredRule('db'))
}))

function submit() {
    v.validate()
    if (v.hasErrors) return
    v.reset()
    menu.createNewSchema({ ...form })
    form.title = ''
    form.db = tab.dbTypes[0] || 'generic'
}

function selectChoice(ch) {
    choice.value = ch
}

onMounted(() => {
    if (!schema.schemaTemplatesReq.loaded) schema.schemaTemplatesReq.send()
    if (!schema.loadSchemasReq.loaded) {
        if (auth.user) schema.loadSchemasReq.send()
    }
})

</script>
<template>
    <UModal
        class="text-small"
        @cancel="menu.toggleCreateNewSchemaModalOpen"
        :title="$t('dialog.new_schema')"
        :model-value="menu.CreateNewSchemaModalOpen"
        no-footer
        size="lg">
        <div class="load-schema d-flex">
            <div class="choices-list flex-shrink-0">
                <div class="choice has-hover d-flex align-items-center gap-2"
                     :class="{'bg-primary active': choice?.id === ch.id, [ch.id]: true}"
                     @click="selectChoice(ch)"
                     v-for="ch in choices" :key="ch.id">
                    <component :is="ch.icon" v-if="ch.icon"/>
                    <span>{{ ch.title }}</span>
                </div>
            </div>

            <div class="flex-grow-1 ml-4" style="min-height: 28em">
                <div v-if="!choice.loginNeeded || (choice.loginNeeded &&  auth.isLoggedIn)">
                    <div v-if="choice.id === 'scratch'" class="load-schema flex-column">
                        <div class="d-flex flex-wrap gap-6">
                            <UInput class="flex-grow-1 flex-shrink-0 mr-6" label="Title" v-model="form.title"
                                    :errors="v.errors.title"/>
                            <UDropdown left>
                                <UButton flat secondary>
                        <span class="text-small">{{
                                form.db ? form.db : 'Select Database Type'
                            }}</span>
                                    <ChevronDownIcon/>
                                </UButton>
                                <template #content>
                                    <UMenuItem v-for="type in tab.dbTypes?.sort()" :key="type" :value="type"
                                               @click="form.db = type">
                                        {{ type }}
                                    </UMenuItem>
                                </template>
                            </UDropdown>
                            <div class="flex-grow-1"></div>
                        </div>
                        <p class="text-muted my-3">
                            {{
                                $t('Selecting the "generic" database type will allow you to generate SQL for all the databases; but you will only have access to the generic data types.')
                            }}
                        </p>
                        <UButton class="my-2" @click="submit" :loading="schema.saveSchemaReq.loading"
                                 :disabled="schema.saveSchemaReq.loading || !(form.title && form.db)"><span
                            class="text-small">{{ $t('dialog.create_a_new_schema') }}</span></UButton>
                    </div>
                    <div v-else-if="choice.id === 'ai'">
                        <GenerateWithAi/>
                    </div>
                    <div v-else>
                        <div class="text-center"
                             v-if="schema.loadSchemasReq.loading || schema.schemaTemplatesReq.loading">
                            <Loader/>
                        </div>
                        <SchemaList
                            v-else
                            :schemas="getSchemas"
                            @selected="load"
                        />
                    </div>
                </div>
                <div v-else class="text-center">
                    You need to be logged in to perform this action.
                    <div class="mt-4 d-flex align-items-baseline justify-content-center">
                        <UButton flat compact @click="router.push('/login')">Login</UButton>
                        <div class="mx-5">
                            OR
                        </div>
                        <UButton flat compact secondary outline
                                 @click="selectChoice(choices.find(c => c.id === 'templates'))">Checkout Templates
                        </UButton>
                    </div>
                </div>
            </div>
        </div>
    </UModal>
</template>

<style lang="scss">

.apm-modal-body {
    container-type: inline-size;
    container-name: modal-body;
}

.load-schema {
    container-name: test;
    container-type: inline-size;

    .choices-list {
        flex-direction: column;
        align-items: flex-start !important;
        gap: 1em;

        .u-chip {
            width: 100%;
            border-radius: var(--border-radius);
            justify-content: flex-start;
            padding: 1.5em 2.5em !important;
        }

        .choice {
            padding: 1em !important;
            border-bottom: 1px solid var(--border-color);
            cursor: pointer;

            &.active {
                &:hover {
                    background-color: var(--primary-dark);
                    cursor: initial;
                }
            }

            .material-design-icon {
                font-size: 1.25em;
            }
        }
    }
}

@container modal-body (max-width: 400px) {
    .load-schema {
        flex-direction: column;

        .choices-list {
            margin-bottom: 1rem;
        }
    }
}
</style>
