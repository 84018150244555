<script lang="ts" setup>

</script>

<style scoped lang="scss">
.leaf, .leaf_2 {
    animation: rotate 2s ease-in infinite alternate;
    transform-origin: 8% 98%;
}

.playcard, .head, .arm {
    animation: rotate2 3s ease-in infinite alternate;
    transform-origin: 66% 55%;
}

.head {
    animation-duration: 0.75s;
    transform-origin: 50% 33%;
}

.arm {
    animation-duration: 1s;
    transform-origin: 50% 33%;
}

.diamond, .circle {
    animation: bounce 1s ease-out infinite;
}

.circle {
    animation-delay: 0.13s;
}
</style>

<template>
    <svg width="719" height="558" viewBox="0 0 719 558" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g class="undraw_feeling_of_joy_re_cqko 1" clip-path="url(#clip0_710_898)">
            <path class="Vector"
                  d="M333 557.817C516.911 557.817 666 552.892 666 546.817C666 540.742 516.911 535.817 333 535.817C149.089 535.817 0 540.742 0 546.817C0 552.892 149.089 557.817 333 557.817Z"
                  fill="#E6E6E6"/>
            <path class="arm"
                  d="M317.155 232.616L298.603 218.418L232.183 338.719C230.097 339.099 228.169 340.083 226.639 341.551C225.108 343.018 224.044 344.903 223.578 346.972C223.112 349.04 223.265 351.2 224.017 353.182C224.77 355.164 226.09 356.88 227.811 358.117C229.533 359.355 231.581 360.058 233.7 360.139C235.818 360.221 237.914 359.677 239.725 358.575C241.537 357.474 242.984 355.864 243.887 353.945C244.79 352.027 245.108 349.885 244.802 347.787L317.155 232.616Z"
                  fill="#FFB8B8"/>
            <path class="Vector_2"
                  d="M486.5 300.897C485.741 298.702 484.296 296.81 482.378 295.5C480.46 294.191 478.171 293.534 475.851 293.628L368.835 209.682L352.783 226.656L465.52 305.234C465.634 306.766 466.075 308.255 466.811 309.603C467.547 310.951 468.562 312.127 469.788 313.051C471.014 313.976 472.424 314.629 473.922 314.965C475.421 315.302 476.974 315.315 478.478 315.005C479.982 314.694 481.403 314.065 482.645 313.162C483.887 312.259 484.922 311.101 485.681 309.766C486.44 308.431 486.906 306.949 487.047 305.42C487.188 303.89 487.002 302.348 486.5 300.897Z"
                  fill="#FFB8B8"/>
            <path class="Vector_3" d="M362.3 535.033H374.908L380.908 486.404H362.3V535.033Z" fill="#FFB8B8"/>
            <path class="Vector_4"
                  d="M399.222 546.74H359.599V531.43H383.913C387.973 531.43 391.867 533.043 394.738 535.914C397.609 538.785 399.222 542.679 399.222 546.74Z"
                  fill="#2F2E41"/>
            <path class="Vector_5" d="M262.401 523.777L273.984 528.755L298.698 486.446L281.602 479.099L262.401 523.777Z"
                  fill="#FFB8B8"/>
            <path class="Vector_6"
                  d="M291.701 549.111L255.297 533.466L261.341 519.401L283.68 529.001C287.411 530.604 290.351 533.624 291.855 537.395C293.359 541.166 293.304 545.381 291.701 549.111Z"
                  fill="#2F2E41"/>
            <path class="Vector_7"
                  d="M301.698 301.866L241.724 490.801L320.44 499.235L328 388.817L338.245 499.235L401.968 491.738C401.968 491.738 390.457 316.86 369.505 305.615C348.553 294.369 301.698 301.866 301.698 301.866Z"
                  fill="#2F2E41"/>
            <path class="Vector_8"
                  d="M333.376 310.336C315.157 310.336 297.623 309.528 296.72 307.962C296.643 307.83 296.614 307.676 296.637 307.526C296.661 307.375 296.735 307.238 296.848 307.136C298.101 305.29 295.159 275.289 288.962 226.899C288.26 221.232 289.513 215.496 292.515 210.638C295.517 205.781 300.088 202.094 305.47 200.188L305.586 200.162L360.5 194.739L361.115 194.912C366.547 196.422 371.304 199.736 374.604 204.307C377.903 208.879 379.55 214.437 379.273 220.068C377.857 250.936 375.481 303.219 375.481 306.552C375.481 307.459 374.219 307.913 373.119 308.208C367.772 309.64 350.279 310.336 333.376 310.336Z"
                  fill="#2F2E41"/>
            <path class="diamond" d="M111.651 92.2178L50.4209 168.735L126.938 229.965L188.168 153.448L111.651 92.2178Z"
                  fill="#F2F2F2"/>
            <path class="circle"
                  d="M126.407 247.145C122.105 246.489 118.095 244.573 114.882 241.638C111.67 238.703 109.4 234.881 108.36 230.656C107.32 226.431 107.556 221.993 109.038 217.902C110.52 213.811 113.182 210.251 116.688 207.673C120.193 205.095 124.384 203.615 128.731 203.419C133.077 203.223 137.385 204.32 141.108 206.572C144.831 208.824 147.803 212.129 149.647 216.07C151.492 220.011 152.126 224.41 151.471 228.712C151.035 231.568 150.042 234.31 148.546 236.782C147.051 239.254 145.084 241.408 142.756 243.119C140.429 244.831 137.787 246.067 134.982 246.758C132.176 247.449 129.263 247.58 126.407 247.145ZM132.736 205.625C128.825 205.029 124.826 205.605 121.243 207.282C117.661 208.959 114.656 211.661 112.609 215.045C110.562 218.43 109.564 222.346 109.742 226.297C109.92 230.249 111.266 234.059 113.61 237.246C115.954 240.432 119.19 242.852 122.909 244.2C126.628 245.548 130.663 245.762 134.504 244.816C138.345 243.87 141.819 241.807 144.487 238.887C147.155 235.967 148.897 232.321 149.493 228.41C150.287 223.167 148.968 217.824 145.826 213.552C142.685 209.281 137.977 206.43 132.736 205.625Z"
                  fill="#3F3D56"/>
            <path class="leaf"
                  d="M79.5776 495.203C72.0677 520.29 83.723 545.938 83.723 545.938C83.723 545.938 107.554 530.913 115.064 505.827C122.574 480.74 110.919 455.092 110.919 455.092C110.919 455.092 87.0875 470.117 79.5776 495.203Z"
                  fill="#E6E6E6"/>
            <path class="leaf_2"
                  d="M90.9801 495.882C97.3007 521.294 84.451 546.365 84.451 546.365C84.451 546.365 61.3534 530.235 55.0328 504.823C48.7121 479.411 61.5619 454.34 61.5619 454.34C61.5619 454.34 84.6595 470.47 90.9801 495.882Z"
                  fill="#E6E6E6"/>
            <g class="head">
                <path class="Vector_11"
                      d="M328 178.817C350.644 178.817 369 160.461 369 137.817C369 115.173 350.644 96.8171 328 96.8171C305.356 96.8171 287 115.173 287 137.817C287 160.461 305.356 178.817 328 178.817Z"
                      fill="var(--primary)"/>
                <path class="Vector_12"
                      d="M334.217 188.701C348.708 188.701 360.456 176.953 360.456 162.462C360.456 147.97 348.708 136.223 334.217 136.223C319.726 136.223 307.978 147.97 307.978 162.462C307.978 176.953 319.726 188.701 334.217 188.701Z"
                      fill="#FFB8B8"/>
                <path class="Vector_13"
                      d="M297.318 145.083C311.271 152.68 326.977 156.475 342.86 156.087C348.943 155.929 355.646 154.822 359.499 150.112C363.846 144.796 362.758 136.492 358.565 131.054C354.371 125.617 347.811 122.551 341.267 120.473C335.075 118.506 328.44 117.239 322.09 118.614C318.739 119.34 315.583 120.778 312.838 122.832C310.093 124.886 307.822 127.507 306.18 130.517C304.538 133.527 303.564 136.856 303.323 140.276C303.083 143.696 303.581 147.128 304.786 150.338"
                      fill="var(--primary)"/>
                <path class="Vector_14"
                      d="M299 131.817C311.703 131.817 322 121.52 322 108.817C322 96.1146 311.703 85.8171 299 85.8171C286.297 85.8171 276 96.1146 276 108.817C276 121.52 286.297 131.817 299 131.817Z"
                      fill="var(--primary)"/>
            </g>
            <path class="Vector_15" opacity="0.1"
                  d="M589.393 184.223C592.174 179.452 579.729 167.016 561.598 156.448C543.466 145.881 526.512 141.182 523.731 145.953C520.95 150.725 533.395 163.16 551.527 173.728C569.658 184.296 586.612 188.995 589.393 184.223Z"
                  fill="black"/>
            <g class="playcard">
                <path class="Vector_16"
                      d="M714.613 111.367L624.329 58.7463L635.911 38.8752C636.241 38.3079 636.457 37.681 636.545 37.0304C636.634 36.3797 636.593 35.7181 636.426 35.0831C636.258 34.4482 635.968 33.8525 635.57 33.3299C635.173 32.8073 634.676 32.3682 634.109 32.0375C633.541 31.7069 632.915 31.4912 632.264 31.4028C631.613 31.3144 630.952 31.3551 630.317 31.5224C629.682 31.6897 629.086 31.9805 628.563 32.3781C628.041 32.7756 627.602 33.2722 627.271 33.8395L615.689 53.7106L525.405 1.08951C523.572 0.021114 521.389 -0.275324 519.338 0.2654C517.286 0.806123 515.533 2.13972 514.465 3.97281L443.968 124.928C442.899 126.761 442.603 128.944 443.144 130.995C443.684 133.047 445.018 134.8 446.851 135.868L537.135 188.489L458.078 324.132C457.747 324.699 457.531 325.326 457.443 325.977C457.354 326.628 457.395 327.29 457.562 327.925C457.729 328.56 458.02 329.156 458.418 329.678C458.815 330.201 459.312 330.64 459.879 330.971C460.447 331.302 461.074 331.517 461.725 331.606C462.375 331.694 463.037 331.653 463.672 331.486C464.307 331.318 464.903 331.027 465.426 330.629C465.948 330.232 466.387 329.735 466.718 329.167L545.775 193.525L636.06 246.146C636.967 246.675 637.97 247.02 639.011 247.161C640.052 247.303 641.111 247.238 642.127 246.97C643.143 246.702 644.096 246.237 644.932 245.601C645.768 244.965 646.471 244.17 647 243.262L717.497 122.307C718.026 121.4 718.371 120.397 718.512 119.356C718.654 118.315 718.589 117.256 718.321 116.24C718.053 115.224 717.588 114.271 716.952 113.435C716.316 112.599 715.521 111.896 714.613 111.367Z"
                      fill="#3F3D56"/>
                <path class="Vector_17"
                      d="M580.732 171.618C607.242 171.618 628.732 150.127 628.732 123.618C628.732 97.108 607.242 75.6177 580.732 75.6177C554.223 75.6177 532.732 97.108 532.732 123.618C532.732 150.127 554.223 171.618 580.732 171.618Z"
                      fill="var(--primary)"/>
                <path class="Vector_18"
                      d="M601.616 147.364C598.835 152.136 579.22 155.145 564.905 146.802C550.59 138.459 546.997 121.922 549.778 117.151C552.559 112.379 560.661 121.179 574.976 129.522C589.291 137.866 604.397 142.592 601.616 147.364Z"
                      fill="#F2F2F2"/>
                <path class="Vector_19"
                      d="M570.285 107.852C575.808 107.852 580.285 103.375 580.285 97.8523C580.285 92.3294 575.808 87.8523 570.285 87.8523C564.763 87.8523 560.285 92.3294 560.285 97.8523C560.285 103.375 564.763 107.852 570.285 107.852Z"
                      fill="#F2F2F2"/>
                <path class="Vector_20"
                      d="M606.572 129.001C612.095 129.001 616.572 124.524 616.572 119.001C616.572 113.479 612.095 109.001 606.572 109.001C601.049 109.001 596.572 113.479 596.572 119.001C596.572 124.524 601.049 129.001 606.572 129.001Z"
                      fill="#F2F2F2"/>
            </g>
        </g>
        <defs>
            <clipPath class="clip0_710_898">
                <rect width="718.586" height="557.817" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>
