import { useSchemaStore } from "../stores/schema.store";

export class DeleteResourceCommand {
    payload
    schemaSlug

    constructor(schemaSlug, payload) {
        this.schemaSlug = schemaSlug
        this.payload = payload
    }

    async run() {
        const tabs = ((await import('../stores/tabs.store')).useTabsStore)()
        const schema = useSchemaStore()
        if (tabs.isSchemaReadonly || tabs.isTemplateReadonly) return
        schema.deleteResourceReq.url = `schemas/${this.schemaSlug}/remove_resource`
        schema.deleteResourceReq.config.body = JSON.stringify(this.payload)
        schema.deleteResourceReq.send().catch(() => tabs.selectedTab.unsaved = true)
        // update dbml
    }
}
