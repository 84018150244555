import { Command } from "@/commands/Command.js"
import { useSchemaStore } from "../stores/schema.store";

export class MoveMultipleObjectsCommand extends Command {
    _description = "Move Objects"

    constructor(tab, payload) {
        super(tab, payload);
    }

    label() {
        return `Move ${this.payload.objs.length} Objects`
    }

    async saveData() {
        const tabs = ((await import('../stores/tabs.store')).useTabsStore)()
        const schema = useSchemaStore()
        if (tabs.isSchemaReadonly || tabs.isTemplateReadonly) return

        const { objs } = this.payload
        let tables = []
        let notes = []

        for (const obj of objs) {
            if (obj.resource === 'table') {
                tables.push(obj)
            } else if (obj.resource === 'note') {
                notes.push(obj)
            }
        }

        // save remotely
        schema.createResourceReq.url = `schemas/${this.tab.schema.slug}/create_resource`
        schema.createResourceReq.config.body = JSON.stringify({
            "notes": notes.map(n => {
                return {
                    p_id: n.p_id,
                    top: n.top,
                    left: n.left
                }
            }),
            "tables": tables.map(t => {
                return {
                    p_id: t.p_id,
                    top: t.top,
                    left: t.left
                }
            })
        })

        schema.createResourceReq.send().catch(() => this.tab.unsaved = true)
    }

    run() {
        const { objs, left, top } = this.payload

        for (const obj of objs) {
            obj.left += left
            obj.top += top
        }

        this.saveData()

        super.run()
    }

    undo() {
        const { objs, left, top } = this.payload
        for (const obj of objs) {
            obj.left -= left
            obj.top -= top
        }
        this.saveData()
        super.undo()
    }
}
