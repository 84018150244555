<script setup>
import USelect from 'nvd-u/components/USelect.vue'
import { useSettingsStore } from "../../stores/settings.store";
import { markupThemes } from "../../data/constants";
import { computed } from "vue";

const settings = useSettingsStore()

</script>

<template>
    <USelect
        :label="$t('panel.properties_markup_theme')"
        :options="markupThemes"
        label-field="value"
        v-model="settings.settings.markupTheme"
    />
</template>
