import {colorHelper} from "@/helpers/color-helper.js"
import {cssVar} from "@/helpers/misc"
import {canvasHelper} from "@/modules/canvas/canvas-helper.js"
import {canvasFont, colorDark, colorLight, fontSize} from "@/modules/canvas/styles.js"
import {CanvasItem} from "@/modules/canvas/table/CanvasItem.js"

export class Note extends CanvasItem {
    paddingY = fontSize * 1.25
    paddingX = fontSize * 1.25
    maxTextWidth = fontSize * 22

    constructor(canvas, data) {
        super(canvas, data)

        this.height = this.paddingY * 2 + fontSize
        this.color = this.color || '#ffff00'
    }

    draw() {
        this.ctx.font = canvasFont
        let lines = canvasHelper.getLines(this.ctx, this.data.content, this.maxTextWidth)
        this.width = Math.min(this.maxTextWidth, this.ctx.measureText(this.data.content).width) + this.paddingX * 2
        this.height = this.paddingY * 2 + fontSize * lines.length

        if (this.isOutOfScreen()) return

        this.update()

        this.ctx.beginPath()
        // rect
        this.ctx.fillStyle = this.color
        this.ctx.fillRect(this.left, this.top, this.width, this.height, this.borderRadius)
        this.ctx.fill()

        // text
        let color = this.color
        this.ctx.fillStyle = colorHelper.isLight(color) ? colorDark : colorLight

        lines.forEach((line, idx) => {
            this.ctx.fillText(line, this.left + this.paddingX, this.top + fontSize * idx + this.paddingY)
        })

        let r = 4
        this.ctx.fillStyle = cssVar('--bg')
        canvasHelper.circle(this.ctx, this.left + r * 2, this.top + r * 2, r)
        this.ctx.fill()

        this.drawSelectedState()
        this.ctx.closePath()
    }
}
