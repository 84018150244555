import {createRouter, createWebHistory} from 'vue-router'
import {useAuthStore} from "../stores/auth.store"
import AlreadyLoggedIn from "@/utils/AlreadyLoggedIn";
import HomeView from '../views/HomeView.vue'
import {useSchemaStore} from '../stores/schema.store';

const router = createRouter({
    // @ts-ignore

    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        {
            path: '/designer/new',
            redirect: '/designer/schema/new'
        },
        {
            path: '/designer/:id?',
            redirect: to => ({
                name: 'app',
            })
        },
        {
            path: '/designer/schema/:id?',
            name: 'app',
            component: () => import('../App.vue'),
        },
        {
            path: '/login',
            name: 'login',
            beforeEnter: AlreadyLoggedIn,
            component: () => import('../views/auth/Login.vue')
        },
        {
            path: '/register',
            name: 'register',
            beforeEnter: AlreadyLoggedIn,
            component: () => import('../views/auth/Register.vue')
        },
        {
            path: '/forgot-password',
            name: 'forgot-password',
            beforeEnter: AlreadyLoggedIn,
            component: () => import('../views/auth/ForgotPassword.vue')
        },
        {
            path: '/passwords/edit',
            name: 'edit-password',
            beforeEnter: AlreadyLoggedIn,
            component: () => import('../views/auth/EditPassword.vue')
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            meta: {auth: true},
            component: () => import('../views/dashboard/schemas/index.vue'),
        },
        {
            name: 'account',
            path: '/account',
            meta: {auth: true},
            component: () => import('../views/dashboard/account/index.vue'),
            children: [
                {
                    name: 'profile',
                    path: 'profile',
                    component: () => import('../views/dashboard/account/Profile.vue')
                },
                {
                    name: 'billing',
                    path: 'billing',
                    meta: {auth: true},
                    component: () => import('../views/dashboard/billing/index.vue'),
                },
                {
                    name: 'invoices-history',
                    path: 'invoices-history',
                    meta: {auth: true},
                    component: () => import('../views/dashboard/billing/InvoicesHistory.vue'),
                },
            ]
        },
        {
            name: 'team-members',
            path: '/team',
            meta: {auth: true},
            component: () => import('../views/dashboard/team-crud/Team.page.vue'),
        },
        {
            name: 'subscription',
            path: '/subscriptions/new',
            redirect: '/account/billing',
            meta: {auth: true},
            component: () => import('../views/dashboard/subscriptions/index.vue'),
        },
        {
            name: 'team',
            path: '/project/team/:id',
            meta: {auth: true},
            component: () => import('../views/dashboard/team/index.vue')
        },
        {
            path: '/github-auth-success',
            redirect: to => {
                const {path, query} = to
                return {
                    path: '/login', query
                }
            }
        },
        {
            name: 'invite',
            path: '/registrations/invite',
            beforeEnter: AlreadyLoggedIn,
            component: () => import('../views/auth/Invitation.vue')
        },
        {
            name: 'downloads',
            path: '/downloads',
            meta: {auth: true},
            component: () => import('../views/dashboard/downloads/index.vue')
        }
    ]
})

router.beforeEach(async (to, from, next) => {
    const auth = useAuthStore()
    const needsAuth = to.meta.auth

    if (!auth.isLoggedIn && needsAuth) {
        return next('/login')
    } else
        next()
})

export default router
